module.exports = function() {
	$('.reactivate-request-dialog #submit').on('click', function(e) {
		var url = $('.reactivate-request-dialog #continueURL').val().toString();
		var email = $('.reactivate-request-dialog #email').val().toString();
		var addToMailList = $('.reactivate-request-dialog #dwfrm_profile_customer_addtoemaillist').is(':checked');
		var optinMobile = false, optinEmail = false, optinDirectMail = false;

		if (addToMailList) {
			optinMobile = $('.reactivate-request-dialog #dwfrm_profile_login_optinMobile').is(':checked');
			optinEmail = $('.reactivate-request-dialog #dwfrm_profile_login_optinEmail').is(':checked');
			optinDirectMail = $('.reactivate-request-dialog #dwfrm_profile_login_optinDirectMail').is(':checked');	
		}

		$.ajax({
			type: 'POST',
			url: url,
			data: {
				email: email,
				addToMailList: addToMailList,
				optinMobile: optinMobile,
				optinEmail: optinEmail,
				optinDirectMail: optinDirectMail
			},
			async : false,
			crossDomain  : true,
			xhrFields: {
				withCredentials: true
			},
			success: function(res) {
				var resStr = JSON.stringify(res);
				var result = $('#result');
				try {
					var data = JSON.parse(resStr);
					if(data.status == '0'){
						result.html(Resources.SLEEPING_ACCOUNT_REACTIVATE_REQUEST_SUCCESSFUL);
					} else if (data.status == '1') {
						result.html(Resources.SLEEPING_ACCOUNT_REACTIVATE_REQUEST_ERROR_EXISTS);
					} else {
						result.html('Error!');
					}
				} catch (e) {
					//Do Nothing
				}
			}
		});
	});
}