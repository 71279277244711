'use strict';
var $countrySelectorBtn = $('.country-selector .current-country');

exports.init = function init() {

    $('.country').on('focus', function(){
        $('.country').removeClass('focus active-descendent');
        $(this).addClass('focus active-descendent');
    });

    $('.change-country .label').on('keydown',function (e) {
        var key = e.which;
        if(key == 13)  {// the enter key code
            $(this).trigger('click');//Trigger click for keyboard accessibility
            return false;  
        }
    }); 
    $('.country-selector .selector').on('keydown',function (e) {
        var key = e.which;
        if(key == 27)  {// the esc key code
            closeCountrySelector();
            $countrySelectorBtn.find('button').trigger('focus');
            return false;  
        }
    }); 

    /*redirect last tab to first input*/
    $('.label.explore').on('keydown', function (e) {
        e.preventDefault();
        if ((e.which === 9 && !e.shiftKey)) {
          
            if (!$(this).hasClass('selector-active')) {
                $('.label.shop').trigger('focus');
            }        
        }
        if (e.which === 9) {
            if ($(this).hasClass('selector-active')) {
                $('.explore.lists li').first().trigger('focus');
            } else {
                $('.label.shop').trigger('focus');
            }
        }
    });

    /*redirect first shift+tab to last input*/
    $('.label.shop').on('keydown', function (e) {
        e.preventDefault();
        if ((e.which === 9 && e.shiftKey)) {
           
            if (!$(this).hasClass('selector-active')) {
                $('.label.explore').trigger('focus');
            }             
        }
        if (e.which === 9) {
            if ($(this).hasClass('selector-active')) {
                $('.shop.lists li').first().trigger('focus');
            } else {
                $('.label.explore').trigger('focus');
            }
        }
    });

    $('.change-country .label').on('click', function () {
        var $curObj = $(this);
        $curObj.siblings('span.label').removeClass('selector-active');
        $curObj.toggleClass('selector-active');
        if ($('.shop').hasClass('selector-active')) {
            $('.explore.lists').hide();
            $('.explore.lists').removeClass('active');
            $('.shop.lists').show();
            $('.shop.lists').addClass('active');
            $('.shop').attr('aria-expanded', true);
            $('.label.explore').attr('aria-expanded', false);
            $('.shop.lists ul li ').first().trigger('focus');
            $('.shop.lists ul li ').first().addClass('active-descendent');
            return;
        } else {
            $('.shop.lists').hide();
            $('.shop.lists').removeClass('active');
            $('.label.shop').attr('aria-expanded', false);
        }
        if ($('.explore').hasClass('selector-active')) {
            $('.explore.lists').show();
            $('.explore.lists').addClass('active');
            $('.shop.lists').hide();
            $('.shop.lists').removeClass('active');
            $('.label.shop').attr('aria-expanded', false);
            $('.label.explore').attr('aria-expanded', true);
            $('.explore.lists li').first().trigger('focus');
            $('.explore.lists li').first().addClass('active-descendent');
            return;
        } else {
            $('.explore.lists').hide();
            $('.explore.lists').removeClass('active');
            $('.label.explore').attr('aria-expanded', false);
        }
    });
    // set currency first before reload
    $('.country-selector .selector .locale').on('click', function (e) {
        e.preventDefault();
        var url = this.href;
        var currency = this.getAttribute('data-currency');
        $.ajax({
            dataType: 'json',
            url: Urls.setSessionCurrency,
            data: {
                format: 'ajax',
                currencyMnemonic: currency
            }
        })
        .done(function (response) {
            if (!response.success) {
                throw new Error('Unable to set currency');
            }
            window.location.href = url;
        });
    });



    //accessibility tree view

    if ($('.tree').length) {
        $('.tree').each(function() {

                var $tree = $(this);
                //add role 
                $tree.attr({'role': 'tree', 'id': 'tree' + $('.treeview').index(this) });
                $tree.find('.tree-item').not('.child-tree').each(function() {
                    $(this).data('index', $('.tree-item').index(this) );
                });
                var $allNodes = $tree.find('.tree-item');
                var $lastNodeIdx = $allNodes.length - 1;//last list item node's index

                function nextNode($currentNode, direction) {
   

                    if (direction == 'up'){
                        if ($currentNode.data('index') == 0 ) {
                            return;
                        } else {
                            $currentNode.removeClass('active-descendent');
                        }

                        $currentNode.prev('.tree-item').trigger('focus').addClass('active-descendent');


                    }
                    if (direction == 'down'){
                        if ($currentNode.data('index') == $lastNodeIdx ) {
                            return;
                        } else {
                            $currentNode.removeClass('active-descendent');
                        }

                        $currentNode.next('.tree-item').trigger('focus').addClass('active-descendent');

                    }
                    if (direction == 'left'){
                        $currentNode.removeClass('active-descendent');
                        $currentNode.prev('li').trigger('focus').addClass('active-descendent');
                        
                    }
                    if (direction == 'right'){
                        $currentNode.removeClass('active-descendent');
                        $currentNode.children('ul').children('li:first').trigger('focus').addClass('active-descendent');
                    }
                    if (direction == 'parent') {
                        $currentNode.removeClass('active-descendent');
                        $currentNode.parent().parent('.tree-item').trigger('focus').addClass('active-descendent');
                    }
                    if (direction == 'nextparent') {
                        $currentNode.removeClass('active-descendent');
                        $currentNode.parent().parent('.tree-item').next('.tree-item').trigger('focus').addClass('active-descendent');
                    }
                    
                }

                $tree.on('keydown', function(e){
                    if (!(e.shiftKey || e.ctrlKey || e.altKey || e.metaKey)) {
                        var $currentNode = $tree.find('.active-descendent');
                        switch(e.which) {
                            case 9: //down - TAB
                                e.preventDefault();
                                e.stopPropagation();
                                if ($currentNode.data('index') == $lastNodeIdx ) {
                                    return;
                                } else if ($currentNode.hasClass('has-children')) {
                                    nextNode($currentNode, 'right');
                                } else if ($currentNode.is('.child-tree.last')) {
                                    nextNode($currentNode, 'nextparent');
                                } else {
                                    nextNode($currentNode, 'down');
                                }
                                break;
                            case 27: //esc to close
                                closeCountrySelector();
                                break;
                            case 38: //up
                                e.preventDefault();
                                e.stopPropagation();
                                nextNode($currentNode, 'up');
                                break;
                            case 40: //down
                                e.preventDefault();
                                e.stopPropagation();  
                                nextNode($currentNode, 'down');
                                break;
                            case 37: //left
                                e.preventDefault();
                                e.stopPropagation();
                                if ($currentNode.is('.child-tree.first')) {
                                    nextNode($currentNode, 'parent');
                                } else if ($currentNode.hasClass('child-tree')) {
                                    nextNode($currentNode, 'up');
                                } else {
                                    nextNode($currentNode, 'left');
                                }
                                break;
                            case 39: //right
                                e.preventDefault();
                                e.stopPropagation();
                                if ($currentNode.is('.child-tree.last')) {
                                    return;
                                } else if ($currentNode.hasClass('has-children')) {
                                    nextNode($currentNode, 'right');
                                } else {
                                    nextNode($currentNode, 'down');
                                }
                                break;
                            case 13: //enter
                            case 32: //space
                                if ($('> a', $currentNode).length) {
                                    location.href = $('> a', $currentNode).attr('href');
                                    e.stopPropagation();
                                }
                                break;
                            }
                        }
                    }
                );

            }
        );
    }

};
