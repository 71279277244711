'use strict';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

var viewtype = require('./viewtype');

function getInitialOffset($el) {
    var offsetVal = 0;
    if ($el.length) {
        $el.css({
            position: 'static'
        });
        var offsetVal = $el.offset().top;
        $el.css({
            position: ''
        });
    }

    return offsetVal;
}

function onScroll() {
    var $header = $('header');
    var lastScrollTop = 0;
    var delta = 5;
    var $criticalMsgBanner = $('.global-critical-err-msg');
    var $tickerBanner = $('.js-header-banner');
    var headerHeight = $header.height();
    var stickyBlockOffset = getInitialOffset($('.js-sticky-block'));
    var isPLP = $('.pt_product-search-result').length;
    var isPDP = $('.pdp-main').length;
    var $disappearingNavEl = $('.disappearingNavEnabled');
    var disappearingNavEnabled = $disappearingNavEl.length;

    $(window).on('scroll', function() {
        var $stickyFilter = $('.js-sticky-block');
        var windowScrollTop = $(window).scrollTop();
        var navHeight = $('.js-top-banner').outerHeight();
        var st = windowScrollTop;
        var isUpscroll = st < lastScrollTop;
        var filterShouldBeSticky = windowScrollTop >= stickyBlockOffset - (isUpscroll ? navHeight : 0);

        if (Math.abs(lastScrollTop - st) <= delta || $('body').hasClass('qs-prevent-scroll')) {
            return;
        }
        // updating the top position if the nav is hidden
        if(disappearingNavEnabled && $('#qrcode-rd').length > 0 &&  windowScrollTop > 43){
            if (isUpscroll) {
                $('.dpid-headining-section').addClass('show-nav').removeClass('hide-nav');
            } else {
                // downscroll
                $('.dpid-headining-section').removeClass('show-nav').addClass('hide-nav');
            }
        }
        //Global Disappearing Nav
        if (disappearingNavEnabled && windowScrollTop > headerHeight) {
            $('#wrapper').css('padding-top', headerHeight);
            $criticalMsgBanner.hide();
            $tickerBanner.hide();

            if (isUpscroll) {
                // upscroll
                $disappearingNavEl.addClass('add-transition');
                $disappearingNavEl.addClass('show-nav').removeClass('hide-nav');
            } else {
                // downscroll
                $disappearingNavEl.removeClass('show-nav').addClass('hide-nav');
            }
        }

        //PLP & Search
        if (isPLP) {
            //PLP filter bar transitions
            if (filterShouldBeSticky) {
                $stickyFilter.addClass('sticky add-transition');

                if (!sessionStorage.getItem('sameDayBopisFilterEnabled') && $('.js-filter-bar').siblings('.sd-bopis-display-msg').length) {
                    $stickyFilter.addClass('add-border');
                }

                if (disappearingNavEnabled) {
                    // disappearing nav is enabled, and this element should disappear along with it
                    if (isUpscroll) {
                        // upscroll
                        $header.addClass('add-border-bottom');
                        $stickyFilter.css({
                            top: `${navHeight - 2 + 'px'}`
                        });
                    } else {
                        // downscroll
                        $header.removeClass('add-border-bottom');
                        $stickyFilter.css({
                            top: 0
                        });
                    }
                } else {
                    // disappearing nav is disabled; just show the sticky header without the up/down scrolling
                    $header.addClass('add-border-bottom');
                    $stickyFilter.css({
                        top: `${navHeight - 2 + 'px'}`
                    });
                }
            } else {
                $header.removeClass('add-border-bottom');
                if($header.hasClass('show-nav')) {
                    $stickyFilter.removeClass('add-transition');
                }
                $stickyFilter.removeClass('sticky add-border');
                $stickyFilter.css({
                    top: ''
                });
                if ($('.js-gridbreak-title-first').css('opacity') == 0) {
                    $('.js-gridbreak-title-first').css('opacity', '1');
                }
            }
        }

        lastScrollTop = st;
    });
}

function scrollToElement(link) {
    var target = link;
    gsap.to(window, { duration: 0.5 , scrollTo: { y: target, offsetY: 150 } });
}

function headerObserver() {
    var header = document.querySelector('header');
    if (header != null) {
        var observeOptions = {
            attributes: true
        }
    
        function headerObserverCB(mutationList, observer) {
            var mutation = mutationList.pop();
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                ScrollTrigger.refresh(true);
            }
        }
    
        var headerObserver = new MutationObserver(headerObserverCB)
        headerObserver.observe(header, observeOptions)
    }
}

function initializeEvents() {
    //// Example link for Shop Similar, Notify Me, Add to Bag, Style It With CTA 
    $('.smooth-scroll-anchor').on('click', function (e) {
        e.preventDefault();
        var link = $(this).data('link'); //should be button tag for screen readers
        scrollToElement(link);
    });
}

exports.init = function() {
    viewtype.init();
    initializeEvents();
    onScroll();
    headerObserver();
};
