'use strict';

var $body = $('body');
var $countrySelectorFlyout = $('.js-country-selector-flyout');
var $topBanner = $('.js-top-banner');

// configure if we want to show shipping banner while active region selector
var keepShippingBanner = false;
var regionSelectedSequence = 0;

function showCountrySelectorFlyout() {
	$body.addClass('country-selector-active');

	// for interstitial page and country-selector on tablet/mobile device,
	// we keep top 0, as defined in css
	if (keepShippingBanner && $countrySelectorFlyout.css('top') !== 0) {
		// otherwise calculate accurate position to sit just below the shipping banner
		var positionTop = $topBanner.offset().top - $(window).scrollTop();
		$countrySelectorFlyout.css('top', positionTop)
			.find('.region-countries').css('top', positionTop);
	}
}

function hideCountrySelectorFlyout() {
	$body.removeClass('country-selector-active');
}

function clearRegionsSelection() {
	var $regions = $countrySelectorFlyout.find('.js-region');

	$regions.removeClass('active');
	$countrySelectorFlyout.removeClass('region-selected-further');
	regionSelectedSequence = 0;
}

module.exports = function() {
	$(document)
		.on('countryselector.show', showCountrySelectorFlyout)
		.on('countryselector.hide', hideCountrySelectorFlyout);

	$('.js-country-selector-button').on('click', function(e) {
		e.preventDefault();

		if ($body.hasClass('country-selector-active')) {
			hideCountrySelectorFlyout();
			clearRegionsSelection();
		} else {
			showCountrySelectorFlyout();
		}
	});

	$('.js-header-banner').on('click', function(e) {
		var $target = $(e.target);
		var $countrySelector = $target.closest('.js-country-selector');

		if (!$countrySelector.length) {
			hideCountrySelectorFlyout();
			clearRegionsSelection();
		}
	});

	$('.js-region-name').on('click', function(e) {
		e.preventDefault();

		var $target = $(e.currentTarget);
		var $currentRegion = $target.closest('.js-region');

		if (!$currentRegion.hasClass('active')) {
			$currentRegion
				.removeClass('inactive').addClass('active')
				.siblings()
				.removeClass('active').addClass('inactive');
			regionSelectedSequence++;
		}
		if (regionSelectedSequence > 1) {
			// special class for second and further selected region
			$countrySelectorFlyout.addClass('region-selected-further');
		}
	});

	$('.js-back-to-region').on('click', function(e) {
		e.preventDefault();
		clearRegionsSelection();
	});
}
