'use strict';
var navigation;
/**
 * this creates and load the navigation r24 code
 */

function initNavCode() {
    return new Promise(function (resolve, reject) {
        require.ensure([], function (require) {
            navigation = require('@foundation/js/navigation_24');
            if (navigation.init) {
                navigation.init();
            }
            resolve();
        }, function (error) {
            reject(error);
        }, 'nav.rd24');
    });
}

exports.init = function () {
    if (!navigation) {
        initNavCode();
    }
};
