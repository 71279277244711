'use strict';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

var util = require('../util');
let headerElement = document.querySelector('header');
let stickyScrollTrigger;

function copyNavToHeaderSignedInUser() {
    const isDisappearingNavEnabled = $('.disappearingNavEnabled').length;
    if (!isDisappearingNavEnabled) {
        headerElement = headerElement.querySelector('.top-banner-sticky');
    }
    let stickyScrollContent = document.querySelector('.myRLtabs.account-SignedIn');
    if (stickyScrollContent) {
        var newNode = stickyScrollContent.cloneNode(true);
        newNode.classList.add('account-SignedIn-gs');
        headerElement.appendChild(newNode);
        stickyScrollContent.classList.add('account-SignedIn-Orig');
    }

    const anim = gsap.timeline({
        duration: 0.10,
        paused: true
    });

    anim.fromTo('.account-SignedIn-gs', {
        opacity: 0,
        top: '-78px',
        display: 'none',
        duration: 1
    }, {
        opacity: 1,
        top: '0', 
        display: 'block',
        position: 'fixed',
        backgroundColor: 'white',
        duration: .5,
        ease: 'none',
        marginTop: 0,
        onComplete: function() {
           document.querySelector('.account-SignedIn-gs').style.position = 'static';
        }
    }, 0);

    stickyScrollTrigger = ScrollTrigger.create({
        trigger: stickyScrollContent,
        start: 'center top',
        endTrigger: '#footer', 
        end: 'bottom center',
        animation: anim,
        toggleActions: 'play reverse play reverse',
        markers: false
    });
}

function copyNavToHeaderSignedInUserMOB() {
    const isDisappearingNavEnabled = $('.disappearingNavEnabled').length;
    if (!isDisappearingNavEnabled) {
        headerElement = headerElement.querySelector('.top-banner-sticky');
    }
    let stickyScrollContent = document.querySelector('.myRLtabs.account-SignedIn');
    if (stickyScrollContent) {
        var newNode = stickyScrollContent.cloneNode(true);
        newNode.classList.add('account-SignedIn-gs');
        headerElement.appendChild(newNode);
        stickyScrollContent.classList.add('account-SignedIn-Orig');
    }

    const anim = gsap.timeline({
        duration: 0.10,
        paused: true
    });

    anim.fromTo('.account-SignedIn-gs', {
        opacity: 0,
        top: '-78px',
        display: 'none',
        duration: 10
    }, {
        opacity: 1,
        top: '0', 
        display: 'block',
        position: 'fixed',
        backgroundColor: 'white',
        duration: .5,
        ease: 'none',
        marginTop: 0,
        onComplete: function() {
           document.querySelector('.account-SignedIn-gs').style.position = 'static';
        }
    }, 0);

    stickyScrollTrigger = ScrollTrigger.create({
        trigger: stickyScrollContent,
        start: 'center top',
        endTrigger: '#footer', 
        end: 'bottom center',
        animation: anim,
        toggleActions: 'play reverse play reverse',
        markers: false
    });
}

function copyNavToHeader() {
    if (util.isDesktopSize() && $('.myRLtabs.account-SignedIn').length > 0) {
        copyNavToHeaderSignedInUser();
    } else if (util.isMobile() && $('.myRLtabs.account-SignedIn').length > 0){
		copyNavToHeaderSignedInUserMOB();
	} else {
		$('header').removeClass('disappearingNavEnabled');
		let scrollContent = document.querySelector('.slot-grid-header');
        if (scrollContent){
            scrollContent.classList.add('slot-grid-header-Orig');
        }
	}
}

function accountMenuToggle() {
	var accountMenu = $('.registered-nav-list').prop('aria-hidden', true);
	var accountBtn = $('.account-nav-registered h3').attr('role', 'button').prop('aria-expanded', false);
	accountBtn.on('click',function() {
		if($(window).width() < 960) {
			accountMenu.slideToggle();
			$('.account-nav-registered').find('h3').toggleClass('rotate');
		}
    });
	var desk = $(window).width() > 960;
	$(window).on('resize',function() {
		if($(window).width() > 960) {
			desk = true;
			$('.registered-nav-list').css('display', 'block');			
		} else {
			if(desk){				
				$('.registered-nav-list').css('display', 'none');
			    $('.account-nav-registered h3').removeClass('rotate');
			}
			desk = false;
		}
	});
}

exports.accountMenuToggle = accountMenuToggle;
exports.copyNavToHeader = copyNavToHeader;