'use strict';

module.exports = function () {
    // Set maxlength for input type number: https://stackoverflow.com/questions/9555143/html-maxlength-attribute-not-working-on-chrome-and-safari
    // Ticket: https://ralphlauren.atlassian.net/browse/DIGITAL-30483
    $('body').on('change keypress', 'input[id*="dwfrm_paymentinstruments_creditcards_newcreditcard_number"]', function (e) {
        var maxlength = $(this).prop('maxlength');
        if (maxlength !== -1) {  // Prevent execute statement for non-set maxlength prop inputs
            var length = $(this).val().trim().length;
            if (length + 1 > maxlength) e.preventDefault();
        }
    });

    var validatePhone = function (value, el) {
        var rgx = /((?=^(09)|(?=^(886))|(?=^(9)))[0-9]{9,15})$/;

        var form = $(el).closest("form");

        if (form.hasClass("eform-form") || ((form.find('select[name$=_addressFields_country]').val() && form.find('select[name$=_addressFields_country]').val() !== 'TW') || (form.find('select[name$=_address_country]').val() && form.find('select[name$=_address_country]').val() !== 'TW'))) {
            rgx = /^\d{8,20}$/;
        }

        var isOptional = this.optional(el);
        var isValid = rgx.test(value);
        return isOptional || isValid;
    };

    /**
     * Card exp date validation for payment page since we have new MM/YY format (Not Month dropdown)
     */
    var cardexpdateValidationForPayment = function (value, el) {
        if ($(el).closest('.anim-scroll-up') && !$(el).closest('.anim-scroll-up').hasClass('anim-scroll-down')) return true;

        var isValid = false;
        var expDate = $(el).closest('form').find('[name$="_expirationdate_date"]').val();
        var expMonth = expDate.split("/")[0];
        var expYear =  expDate.split("/")[1];
        var currentYear = parseInt(new Date().getFullYear().toString().substr(2,2));
        var currentMonth = parseInt(new Date().getMonth()+1);

        if ((expYear == currentYear && expMonth < currentMonth) || (expYear < currentYear) || (expMonth > 12) || (expYear > (currentYear+20)) || (expYear == (currentYear+20) && expMonth > currentMonth)) {
            isValid = false;
        } else if ((value.length == 5) && ((expYear > currentYear) || (expYear == currentYear && expMonth >= currentMonth))) {
            isValid = true;
        }
        return isValid;
    };

    /**
     * @function
     * @description Validates a given credit card if it has the correct format
     * @param {String} value of credit card which will be validated
     */
    var luhnCheck = (function (arr) {
        return function (ccNum) {
            var len = ccNum.length;
            var bit = 1;
            var sum = 0;
            var val;

            while (len) {
                val = parseInt(ccNum.charAt(--len), 10);
                sum += (bit ^= 1) ? arr[val] : val;
            }

            return sum && sum % 10 === 0;
        };
    }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

    var regexValidate = (function (value, rgx, notCheckEmpty) {
        if (notCheckEmpty && !value) {
            return true;
        }
        if (!rgx){
            rgx = /^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\s]+$/;
        }
        var isValid = rgx.test(value);
        return isValid;
    });

    $.validator.addMethod('mobilecode', function (value, el) {
        var regex = /^\/[A-Z0-9\+\-\.]{7}$/;
        var isValid = regex.test($.trim(value));
        return isValid;
    }, Resources.INVALID_MOBILECODE);

    $.validator.addMethod('digitalcode', function (value, el) {
        var regex = /^[A-Z]{2}\d{14}$/;
        var isValid = regex.test($.trim(value));
        return isValid;
    }, Resources.INVALID_DIGITALCODE);

    $.validator.addMethod('donationunitcode', function (value, el) {
        var regex = /^\d{3,7}$/;
        var isValid = regex.test($.trim(value));
        return isValid;
    }, Resources.INVALID_DONATIONUNITCODE);

    $.validator.addMethod('phone', validatePhone, Resources.INVALID_PHONE);

    $.validator.addMethod('mobile', validatePhone, Resources.INVALID_PHONE);

    /**
     * Add CC number validation method to jQuery validation plugin.
     * Text fields must have 'number' css class to be validated
     */
    $.validator.addMethod('credit-card-number', function (value) {	// number == Credit card number
        var hasError = $('.credit-card-number').hasClass('cc-error');
        var getCardSave = $('.saved-payment input[type=radio]:checked').length;
        var isValid = true;
        if (!hasError && getCardSave == 0) {
            isValid = luhnCheck($.trim(value));
        }
        return isValid;
    }, Resources.VALIDATE_NUMBER);

    $.validator.addMethod('owner', function (value, el) {
        var isValid = regexValidate(value);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('firstName', function (value, el) {
        var isValid = regexValidate(value);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('lastName', function (value, el) {
        var isValid = regexValidate(value);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('firstname', function (value, el) {
        var isValid = regexValidate(value, null, true);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('lastname', function (value, el) {
        var isValid = regexValidate(value, null, true);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('address1', function (value, el) {
        var rgx = /^[0-9A-Za-z!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\u3000\u3400-\u4DBF\u4E00-\u9FFF\s]{0,35}$/;
        var isValid = regexValidate(value, rgx);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('address2', function (value, el) {
        var rgx = /^[0-9A-Za-z!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\u3000\u3400-\u4DBF\u4E00-\u9FFF\s]{0,35}$/;
        var isValid = regexValidate(value, rgx);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('check_length_chinese_chars', function (value, el) {
        return (new TextEncoder().encode(value).length) > 35 ? false : true;
    }, Resources.VALIDATE_EXCEEDINGWORDLIMIT);


    $.validator.addMethod('city', function (value, el) {
        var isValid = regexValidate(value);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('latincheck', function () {
        return true;
    });

    $.validator.addMethod('specialcharcheck', function () {
        return true;
    });

    var validatePostcodeEform = function (value, el) {
        var isValid = false;

        var countryValue = $(el).closest('form').find('select[name$="_eform_country"]').val();
            if (countryValue && countryValue === 'TW') {
                var rgx = /^([0-9]){3,5}$/;
                isValid = rgx.test(value);
                return isValid;
            }

        return !isValid;
    };

    $.validator.addMethod('eform-postcode',validatePostcodeEform, Resources.INVALID_POSTAL);

    $.validator.addMethod('exp_date', cardexpdateValidationForPayment, Resources.CREDIT_EXPDT_VALIDATION);
};
