"use strict";

module.exports = function () {
    var validateDayInput = function (value, el) {
        var isValid = false;
        var dayInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_day"]');
        if (dayInputElement.length > 0) {
            var dayInput = dayInputElement.val();
            if (isNaN(dayInput) || dayInput.includes('.') || dayInput.includes('+') || dayInput.includes('-')) {
                return false;
            }
        }

        return !isValid;
    };

    var validateMore31 = function (value, el) {
        var isValid = false;
        var dayInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_day"]');
        if (dayInputElement.length > 0) {
            var dayInput = dayInputElement.val();
            if (Number(dayInput) > 31) {
                return false;
            }
        }
        return !isValid;
    };

    var validateLess1 = function (value, el) {
        var isValid = false;
        var dayInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_day"]');
        if (dayInputElement.length > 0) {
            var dayInput = dayInputElement.val();
            if (Number(dayInput) < 1) {
                return false;
            }
        }
        return !isValid;
    };

    function leapYear(year) {
        return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    }

    var validateDayOfMonth = function (value, el) {
        var isValid = false;

        var yearInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_year"]');
        var monthInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_month"]');
        var dayInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_day"]');
        if (
            yearInputElement.length > 0 &&
            monthInputElement.length > 0 &&
            dayInputElement.length > 0
        ) {
            var currentYear = new Date().getFullYear();

            var yearInput = yearInputElement.val();
            var monthInput = monthInputElement.val();
            var dayInput = dayInputElement.val();
            if (
                isNaN(dayInput) ||
                Number(dayInput) == 0 ||
                Number(dayInput) > 31
            ) {
                return false;
            }
            if (
                dayInput.toString().length > 0 &&
                monthInput.toString().length > 0 &&
                yearInput.toString().length > 0
            ) {
                if (
                    yearInput &&
                    !isNaN(yearInput) &&
                    Number(yearInput) > 1800 &&
                    Number(yearInput) < Number(currentYear)
                ) {
                    var isLeadYeap = leapYear(Number(yearInput));
                    var listMonth30days = [4, 6, 9, 11];

                    if (
                        (!isLeadYeap &&
                            Number(monthInput) == 2 &&
                            Number(dayInput) > 28) ||
                        (listMonth30days.includes(Number(monthInput)) &&
                            Number(dayInput) == 31)
                    ) {
                        return isValid;
                    }
                }
            }
        }

        return !isValid;
    };

    var validateMonthInput = function (value, el) {
        var isValid = false;

        var monthInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_month"]');

        if (monthInputElement.length > 0) {
            var monthInput = monthInputElement.val();
            if (monthInput.toString().length > 0) {
                if (isNaN(monthInput) || monthInput.includes('.') || monthInput.includes('+') || monthInput.includes('-')) {
                    return isValid;
                }
            }
        }
        return !isValid;
    };

    var validateMonthLess1 = function (value, el) {
        var isValid = false;

        var monthInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_month"]');

        if (monthInputElement.length > 0) {
            var monthInput = monthInputElement.val();
            if (monthInput.toString().length > 0) {
                if (isNaN(monthInput) || Number(monthInput) < 1) {
                    return isValid;
                }
            }
        }

        return !isValid;
    };

    var validateMonthMore12 = function (value, el) {
        var isValid = false;

        var monthInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_month"]');

        if (monthInputElement.length > 0) {
            var monthInput = monthInputElement.val();
            if (monthInput.toString().length > 0) {
                if (isNaN(monthInput) || Number(monthInput) > 12) {
                    return isValid;
                }
            }
        }

        return !isValid;
    };

    var validateYearInput = function (value, el) {
        var isValid = false;

        var yearInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_year"]');

        if (yearInputElement.length > 0) {
            var yearInput = yearInputElement.val();
            if (yearInput.toString().length > 0) {
                if (isNaN(yearInput) || yearInput.includes('.') || yearInput.includes('+') || yearInput.includes('-')) {
                    return isValid;
                }
            }
        }

        return !isValid;
    };

    var validateYearLess1930 = function (value, el) {
        var isValid = false;

        var yearInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_year"]');

        if (yearInputElement.length > 0) {
            var yearInput = yearInputElement.val();
            if (yearInput.toString().length > 0) {
                if (isNaN(yearInput) || Number(yearInput) < 1930) {
                    return isValid;
                }
            }
        }

        return !isValid;
    };

    var validateWithCurrentYear = function (value, el) {
        var isValid = false;
        var currentYear = new Date().getFullYear();

        var yearInputElement = $(el)
            .closest("form")
            .find('input[name$="_eform_year"]');

        if (yearInputElement.length > 0) {
            var yearInput = yearInputElement.val();
            if (yearInput.toString().length > 0) {
                if (Number(yearInput) >= Number(currentYear)) {
                    return isValid;
                }
            }
        }

        return !isValid;
    };

    var validatePhone = function (value, el) {
        var rgx = /^$|^(0(\d{9}))$|^(61(\d{9}))$/;
        var form = $(el).closest("form");
        var countryEl = form.find('[name$="_country"]');

        if (form.hasClass("eform-form")) {
            rgx = /^\d{8,20}$/;
        }
        
        var isValid = true;
        if((countryEl && countryEl.length && countryEl.val() === "AU") || form.hasClass("eform-form") || ($(el).closest("form[id='RegistrationForm']") && $(el).closest("form[id='RegistrationForm']").length)) {
            isValid = rgx.test(value);
        }

        return isValid;
    };
    
    if( $('.eform-valid-month') ) {
        $.validator.addMethod('month', validateMonthInput, Resources.VALIDATE_DATE);
    }
    if( $('.eform-valid-year') ) {
        $.validator.addMethod('year', validateYearInput, Resources.VALIDATE_DATE);
    }
    
    $.validator.addMethod("eform-valid-day", validateDayInput, Resources.VALIDATE_DATE);
    $.validator.addMethod("eform-day-greater", validateMore31, Resources.INVALID_DAY_LESS);
    $.validator.addMethod("eform-day-less", validateLess1, Resources.INVALID_DAY_GREATER);
    $.validator.addMethod("eform-day-of-month", validateDayOfMonth, Resources.VALIDATE_DATE);
    $.validator.addMethod("eform-valid-month", validateMonthInput, Resources.VALIDATE_DATE);
    $.validator.addMethod("eform-month-less", validateMonthLess1, Resources.INVALID_MONTH_GREATER);
    $.validator.addMethod("eform-month-greater", validateMonthMore12, Resources.INVALID_MONTH_LESS);
    $.validator.addMethod("eform-valid-year", validateYearInput, Resources.VALIDATE_DATE);
    $.validator.addMethod("eform-year-less", validateYearLess1930, Resources.INVALID_YEAR_GREATER);
    $.validator.addMethod("eform-year-greater", validateWithCurrentYear, Resources.INVALID_YEAR_LESS);
    $.validator.addMethod("phone_au", validatePhone, Resources.VALIDATE_PHONE_AU);
    $.validator.addMethod("phone-field", validatePhone, Resources.VALIDATE_PHONE);
    
    
};
