const {gsap}  = require('gsap');
function reveal($set) {
    var count = $set.length;
    var nn = 0;
    var fadeIn = function () {
        if (nn < count) {
            gsap.to($set[nn], {
                autoAlpha: 1,
                duration: 0.5
            });
            nn++;
            window.requestAnimationFrame(fadeIn);
        }
    };
    fadeIn();
}

exports.reveal = reveal;