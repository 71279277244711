'use strict';

function SearchButton($searchButton) {
    this.hide = function() {
        $searchButton.addClass('hide');
    };

    this.show = function() {
        $searchButton.removeClass('hide');
    };

    this.openSearch = function() {
        $searchButton.find('.search-icon').attr('aria-expanded', true);
    };

    this.closeSearch = function() {
        $searchButton.find('.search-icon').attr('aria-expanded', false);
    };

    this.click = function(cb) {
        $searchButton.on('click', function(e) {
            e.preventDefault();
            cb();
        });
    };
}

module.exports = SearchButton;
