'use strict';

function couponCode() {
    var couponCodeButton = $('.promo-code-accordion .apply');
    var couponCodeInput = $('.promo-code-accordion .input-text');

    couponCodeButton.prop('disabled', true);

    couponCodeInput.on('keyup focusout paste',function (event) {
    	var coupon = this;
    	setTimeout(function(e) {
	        if(!$(coupon).val() == '') {
	            couponCodeButton.prop('disabled', false);
	        } else {
	            couponCodeButton.prop('disabled', true);
	            if($('.coupon.error').length > 0 && $('.coupon.error').text().length>0){
	        		$('.coupon.error').remove();
	        	}
	        }
    	}, 0);
        if (event.keyCode === 13) {
        	couponCodeButton.click(); 
        }
    });

}

exports.init = function () {
    couponCode();
};

exports.couponCode = couponCode;