'use strict';

var ajax = require('./ajax'),
    progress = require('./../../../app_rleu_core/cartridge/js/progress'),
    util = require('./util'),
    tooltip = require('./../../../app_rleu_core/cartridge/js/tooltip'),
    validator = require('./validator'),
    login = require('./login'),
    dialog = require('./dialog');

var $cache = {},
    isSPCLogin = false,
    isSPCShipping = false,
    isSPCMultiShipping = false,
    isSPCBilling = false;

function initializeCache() {
    $cache = {
        main: $('#main'),
        primary: $('#primary'),
        secondary: $('#secondary')
    };
}

function initializeDom() {
    isSPCLogin = $('.spc-login').length > 0;
    isSPCShipping = $('.spc-shipping').length > 0;
    isSPCMultiShipping = $('.spc-multi-shipping').length > 0;
    isSPCBilling = $('.spc-billing').length > 0;
}

function initializeEvents() {

    if (isSPCLogin) {
        loadSPCLogin();
        login.init();
    } else if (isSPCShipping) {
        loadSPCShipping();

    } else if (isSPCMultiShipping) {
        loadSPCMultiShipping();

    } else if (isSPCBilling) {
        loadSPCBilling();

    }

    if (isGoogleAnalyticsEnabled) {
        require('./../../../app_rleu_core/cartridge/js/ga').spc();
    }

    $('.pt_checkout').ajaxError(function () {
        window.location.href = Urls.cartShow;
    });

    $('a.tooltip').on('click',function (e) {
        e.preventDefault();
    });

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

    $cache.primary.on('click', '.checkout-tab-head[data-href]', function (e) {
        e.preventDefault();
        progress.show($cache.primary);
        ajax.load({
            url: $(this).data('href'),
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                initSPC();
                validator.init();
                if ($cache.primary.find('.spc-login').length === 0) {
                    tooltip.init();
                    util.limitCharacters();
                    require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                }
                progress.hide();
                updateAnchor();
            }
        });
    });

    $cache.primary.on('click', '.checkout-tab-head.open[data-refreshurl]', function (e) {
        e.preventDefault();
        progress.show($cache.primary);
        ajax.load({
            url: $(this).data('refreshurl'),
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                initSPC();
                validator.init();
                if ($cache.primary.find('.spc-login').length === 0) {
                    tooltip.init();
                    util.limitCharacters();
                    require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                }
                progress.hide();
                updateSummary();
            }
        });
    });

    $cache.secondary.on('click', '.order-component-block a, .order-totals-table .order-shipping a', function (e) {
        e.preventDefault();
        progress.show($cache.primary);
        ajax.load({
            url: $(this).attr('href'),
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                progress.hide();
                updateAnchor();
            }
        });
    });

    $cache.primary.on('submit', 'form.address', function (e) {
        if ($(this).find('button[class$=-btn]').length > 0) {
            e.preventDefault();
            $(this).find('button[class$=-btn]').trigger('click');
        }
    });
}

function loadSPCLogin() {
    updateSummary();
    $cache.primary.find('.spc-login').trigger('ready');
    $cache.primary.on('click', '.spc-login-btn:not(:disabled)', function (e) {
        e.preventDefault();
        var form = $(this).closest('form');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo(form);
        form.validate();
        if (!form.valid()) {
            return false;
        }
        progress.show($cache.primary);
        ajax.load({
            url: form.attr('action'),
            data: form.serialize(),
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                initSPC();
                validator.init();
                if ($cache.primary.find('.spc-login').length === 0) {
                    tooltip.init();
                    util.limitCharacters();
                    require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                    updateCustomerInfo();
                }
                progress.hide();
                updateAnchor();
            }
        });
    });

    $cache.primary.on('click', '.spc-guest-btn:not(:disabled)', function (e) {
        e.preventDefault();
        var form = $(this).closest('form');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo(form);
        form.validate();
        if (!form.valid()) {
            return false;
        }
        progress.show($cache.primary);
        ajax.load({
            url: form.attr('action'),
            data: form.serialize(),
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                initSPC();
                validator.init();
                if ($cache.primary.find('.spc-login').length === 0) {
                    tooltip.init();
                    util.limitCharacters();
                    require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                }
                progress.hide();
                updateAnchor();
            }
        });
    });
}

function loadSPCShipping() {
    $cache.primary.on('click', '.spc-shipping-btn:not(:disabled)', function (e) {
        e.preventDefault();
        var form = $(this).closest('form');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo(form);
        form.validate();
        if (!form.valid()) {
            return false;
        }
        progress.show($cache.primary);
        ajax.load({
            url: form.attr('action'),
            data: form.serialize(),
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                updateSummary();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                progress.hide();
                if ($cache.primary.find('.spc-shipping').length === 0) {
                    updateAnchor();
                } else {
                    updateAnchor(true);
                }
            }
        });
    });

    $cache.primary.on('click', '.shiptomultiplebutton:not(:disabled)', function (e) {
        e.preventDefault();
        var form = $(this).closest('form');
        var url = util.appendParamToURL(form.attr('action'), $(this).attr('name'), true);
        url = util.appendParamToURL(url, form.find('input[name="csrf_token"]').attr('name'), form.find('input[name="csrf_token"]').attr('value'));
        progress.show($cache.primary);
        ajax.load({
            url: url,
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                updateSummary();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                progress.hide();
                updateAnchor();
            }
        });
    });
}

function loadSPCMultiShipping() {
    $cache.primary.on('click', '.spc-multi-shipping-btn:not(:disabled)', function (e) {
        e.preventDefault();
        var form = $(this).closest('form');
        $('<input type="hidden" name="' + $(this).attr('name') + '" value="true" />').appendTo(form);
        form.validate();
        if (!form.valid()) {
            return false;
        }
        progress.show($cache.primary);
        ajax.load({
            url: form.attr('action'),
            data: form.serialize(),
            type: 'POST',
            callback: function(data) {
                $cache.primary.html(data);
                handleBasketError();
                updateSummary();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                progress.hide();
                updateAnchor();
            }
        });
    });

    $cache.primary.on('click', '.shiptosinglebutton:not(:disabled)', function (e) {
        e.preventDefault();
        var form = $(this).closest('form');

        var url = util.appendParamToURL(form.attr('action'), $(this).attr('name'), true);
        url = util.appendParamToURL(url, form.find('input[name="csrf_token"]').attr('name'), form.find('input[name="csrf_token"]').attr('value'));
        progress.show($cache.primary);
        ajax.load({
            url: url,
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                updateSummary();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                progress.hide();
                updateAnchor();
            }
        });
    });

    $cache.primary.on('click', '.item-shipping-address a', function (e) {
        e.preventDefault();
        progress.show($cache.primary);
        ajax.load({
            url: $(this).attr('href'),
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                progress.hide();
                updateAnchor();
            }
        });
    });

}

function loadSPCBilling() {

    $cache.primary.on('click', '.spc-billing-btn:not(:disabled)', function (e) {
        if ($('input[name$=_selectedPaymentMethodID]:checked').val() === 'PayPal') {
            $(this).removeClass('spc-billing-btn');
            return true;
        }
        
        e.preventDefault();
        
        var form = $(this).closest('form');
        form.validate();
        if (!form.valid()) {
            return false;
        }
        progress.show($cache.primary);
        ajax.load({
            url: form.attr('action'),
            data: form.serialize(),
            type: 'POST',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                updateSummary();
                initSPC();
                if ($cache.primary.find('.spc-summary').length === 0) {
                    tooltip.init();
                    util.limitCharacters();
                    validator.init();
                }
                require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                progress.hide();
                if ($cache.primary.find('.spc-billing').length === 0) {
                    updateAnchor();
                } else {
                    updateAnchor(true);
                }
            }
        });
    });

    $cache.primary.on('click', '.redemption.giftcert a.remove', function (e) {
        e.preventDefault();
        progress.show($cache.primary);
        var url = $(this).attr('href');
        ajax.load({
            url: url,
            data: {},
            type: 'GET',
            callback: function (data) {
                $cache.primary.html(data);
                handleBasketError();
                updateSummary();
                initSPC();
                tooltip.init();
                util.limitCharacters();
                validator.init();
                require.ensure([], function (require) {
                    var chunk = require('./pages/checkout');
                    if (chunk.init) {
                        chunk.init();
                    }
                }, function (error) {
                    console.log('error!', error)
                }, 'checkout');
                progress.hide();
            }
        });
    });
}

function updateSummary() {
	var url = util.appendParamsToUrl(Urls.summaryRefreshURL, {
		pagetype: $('div.cart-wrapper').length > 0 ? 'cartPage' : null
    });
    var summary = $('#secondary.summary');
    summary.load(url, function () {
        summary.find('.checkout-mini-cart .minishipment .header a').hide();
        summary.find('.order-totals-table .order-shipping .label a').hide();
        if ($(window).width() < 959) {
        	$('.cart-wrapper .free-shipping-progress-moved').remove();
        	$('.cart-wrapper .free-shipping-progress').detach().addClass('free-shipping-progress-moved').appendTo('.cart-actions-top');
        }
    });
}

function updateAnchor(noDefault) {
    var erroredElements = $cache.primary.find('.form-row.error:visible').filter(function (i, ele) {
        return $.trim($(ele).html()).length > 0;
    });

    if (erroredElements.length > 0) {
        jQuery('html, body').animate({
            scrollTop: erroredElements.first().position().top
        }, 500);

    } else if ($('.billing-error').length > 0) {
        jQuery('html, body').animate({
            scrollTop: $('.billing-error').position().top
        }, 500);
    } else if (!noDefault) {
        jQuery('html, body').animate({
            scrollTop: $('#navigation').position().top
        }, 500);
    }
}

function handleBasketError() {
    if ($cache.primary.find('.pt_cart').length > 0) {
        window.location.href = Urls.cartShow;
    }
}

function updateCustomerInfo() {
    ajax.load({
        url: Urls.customerInfo,
        type: 'GET',
        async: false,
        callback: function (data) {
            $('#navigation').find('.menu-utility-user').find('.user-info').remove();
            $(data).insertAfter('#navigation .menu-utility-user li:first');
            $('.user-account').on('click', function (e) {
                e.preventDefault();
                $(this).parent('.user-info').toggleClass('active');
            });
        }
    });
}

function initSPC() {
    initializeCache();
    initializeDom();
    initializeEvents();
}

exports.init = function () {
    initializeCache();
    initializeDom();
    initializeEvents();
};
