'use strict';

var ajax = require('./ajax'),
    minicart = require('./minicart'),
    aep = require('../../../app_foundation/cartridge/js/aepdatacollection-util'),
    util = require('./util');


var setAddToCartHandler = function(e) {
    e.preventDefault();
    //Before adding VGC to card Must Check Max VGC amount.
    var doAddTocart = true;
    var form = $(this).closest('form');
    var $button = $(this);
    if (!form.valid()) {
        return false;
    }
    var amount = form.find('input[name $= "purchase_amount"]').val();
    if (Resources.CURRENCY_SYMBOL == '€') {
    	amount = amount.replace(/(\.)*/g, '');
	} else {
		amount = amount.replace(/(,)*/g, '');
	}
    $.ajax({
        dataType: 'json',
        url: Urls.vgcMaxAmount,
        type: 'GET',
        async: false
    }).done(function(response) {
    	var totalAmount = parseInt(amount) + response.amount;
    	//Do not consider previous GC amount
        if ($('.oldGCValue').length > 0) {
        	totalAmount -= Number($('.oldGCValue').val());
        }
        var maxCartLimit = Resources.GIFT_CERT_CART_MAX_LIMIT || 2000;
        if (response != null && response != undefined && totalAmount > maxCartLimit) {
            form.find('.max-Amount-Msg').removeClass('hide');
            form.find('input[name$= "purchase_amount"]').addClass('error1'); 
            $('html, body').animate({
                scrollTop: $('.product-col-2').offset().top
            }, 'slow');
            doAddTocart = false;
        } else {
            form.find('.max-Amount-Msg').addClass('hide');
            form.find('input[name$= "purchase_amount"]').removeClass('error1');
        }
    });
    
    if (doAddTocart) {

        var url = util.ajaxUrl(form.attr('action'))
        var options = {
            url: url,
            method: 'POST',
            cache: false,
            data: form.serialize()
        };
        $.ajax(options).done(function(response) {
            if (response.success) {
                ajax.load({
                    url: Urls.minicartGC,
                    data: {
                        lineItemId: response.result.lineItemId
                    },
                    callback: function(response) {
                    	$('#AddToBasketButton').html(Resources.Item_Add_To_Bag);
                        minicart.show(response, form, $button);
                        form.find('input,textarea').val('');
                        form.find('.max-Amount-Msg').addClass('hide');
                        //Once GC is updated remove oldGCValue
                        $('.isGCUpdate').val('');
                        $('.oldGCValue').val('');
                    }
                });
                form.find('span.error').remove();
                var $charcountclear = $('.gift-card-message').find('.gift-field');
                $charcountclear.find('input').val('');
                $charcountclear.find('span.char-count').find('.char-remain-count').html('0');

            } else {
                form.find('span.error').hide();
                if (response.errors && response.errors.FormErrors) {
                    for (var id in response.errors.FormErrors) {
                        var $errorEl = $('#' + id).addClass('error').removeClass('valid').attr('aria-invalid', 'true').attr('aria-describedby', id + '-error').next('.error');
                        var $errorEltext = response.errors.FormErrors[id].replace(/\\'/g, '\'');

                        if (!$errorEl || $errorEl.length === 0) {
                            $errorEl = $('<span id="' + id + '-error" class="error" style=""></span>');
                            $('#' + id).after($errorEl);
                            $('html, body').animate({
                                scrollTop: $('.product-detail-section').offset().top
                            }, 'slow');
                        }
                        $errorEl.text($errorEltext).show();
                    }
                }
            }
        }).fail(function(xhr, textStatus) {
            // failed
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        });
    }
};

var checkBalance = function (e) {
    e.preventDefault();
    var form = $(this).closest('form');
    form.validate();
    if (!form.valid()) {
        return false;
    }
    var giftCardNumber = $('input[name$="_balance_giftCertID"]').val();
    var captchClick = document.getElementById('clickMe-reload-recaptcha');
    form.find('.balance-result').html('');
    var options = {
        url: util.ajaxUrl(form.attr('action')),
        method: 'POST',
        cache: false,
        data: form.serialize()
    };
    $.ajax(options).done(function (response) {
        var balance = 0;
        var status = false;
        if (response.code == 'CAPTCH_ERROR') {
            $('#capcha-error').removeClass('hide');
            $('#capcha-error').addClass('error');
        } else if (response.code == 'ERROR') {
            form.find('.balance-result').html(Resources.GIFT_CERT_INVALID);
            captchClick.onclick();
            $('#capcha-error').addClass('hide');
            $('#capcha-error').removeClass('error');
            form.find('.balance-result').addClass('error');
        } else {
            form.find('.balance-result').html(Resources.GIFT_CERT_BALANCE + ' ' + response.balance);
            status = true;
            if (response.balance != undefined)
                balance = response.balance;
            captchClick.onclick();
            $('#capcha-error').addClass('hide');
            if (response.code != 'OK') {
            	form.find('.balance-result').addClass('error');
            } else {
            	form.find('.balance-result').removeClass('error');
            }
        }

        aep.coreCall('giftCardApply', giftCardNumber, status, balance);

    });
};
exports.init = function () {
    $('#AddToBasketButton').off('click').on('click', setAddToCartHandler);
    $('#CheckBalanceButton').off('click').on('click', checkBalance);
    var form = $('#GiftCertificateForm');
    var pdpForm = $('.pdpForm');
    var placeholderText = form.find('input[name$="_messageline1"]').attr('placeholder');
    var PdpPlaceholderText = pdpForm.find('input[name$="_messageline1"]').attr('placeholder');

    if (form.find('input[name$="_includegcmessage"]').is(':checked')) {
        if (form.find('input[name$="_messageline1"]').length > 0) {
            form.find('input[name$="_messageline1"]').addClass('required');
            if (placeholderText.indexOf('*') == -1) {
                form.find('input[name$="_messageline1"]').attr('placeholder', form.find('input[name$="_messageline1"]').attr('placeholder') + ' * ');            	
            }
        }

        if (form.find('input[name$="_messageline1"]').val() === '') {
            form.find(':input[type="submit"]').prop('disabled', true);
        } else {
            form.find(':input[type="submit"]').prop('disabled', false);
        }
    }

    if (pdpForm.find('input[name$="_includegcmessage"]').is(':checked')) {
        if (pdpForm.find('input[name$="_messageline1"]').length > 0) {
            pdpForm.find('input[name$="_messageline1"]').addClass('required');
            if (PdpPlaceholderText.indexOf('*') == -1) {
                pdpForm.find('input[name$="_messageline1"]').attr('placeholder', pdpForm.find('input[name$="_messageline1"]').attr('placeholder') + ' * ');            	
            }
        }

        if (pdpForm.find('input[name$="_messageline1"]').val() === '') {
            pdpForm.find(':input[type="submit"]').prop('disabled', true);
        } else if ($('select[name$="_giftCardAmount"]').val() == ''){
            pdpForm.find(':input[type="submit"]').prop('disabled', true);
        } else {
            pdpForm.find(':input[type="submit"]').prop('disabled', false);
        }
       
    }

    $('input[name$= "purchase_amount"]').on('blur focusout' , function(){
        var $this = $(this);
        if ($this.hasClass('error1')){
            $this.removeClass('error1');
        }
    });

    $('#GiftCertificateForm').on('keyup focus', 'input', function () {
        if (!$('.input.required.error').length) {
            form.find(':input[type="submit"]').prop('disabled', false);
        } else if (requiredInputs.length == 0 && !form.find('input[name$="_messageline1"]').val() == '') {
        	form.find(':input[type="submit"]').prop('disabled', false);
        }
    });
    
    /*
     * Message Line Drawer.
     */
    $('#GiftCertificateForm').on('click', 'input[name$="_includegcmessage"]', function () {
        var placeholderText = form.find('input[name$="_messageline1"]').attr('placeholder');    	
        if ($(this).is(':checked')) {
            form.find('.message-line-item').removeClass('hide');
            if (form.find('input[name$="_messageline1"]').length > 0) {
                form.find('input[name$="_messageline1"]').addClass('required');
                if (placeholderText.indexOf('*') == -1) {
                    form.find('input[name$="_messageline1"]').attr('placeholder', form.find('input[name$="_messageline1"]').attr('placeholder') + ' * ');            	
                }
            }
        } else {
            form.find('.message-line-item').addClass('hide');
            form.find('input[name$="_messageline1"]').closest('.form-row').find('label .required-indicator').remove();
            form.find('input[name$="_messageline1"]').removeClass('required');
        }

        if (form.find('input[name$="_messageline1"]').val() === '' && $(this).is(':checked')) {
            form.find(':input[type="submit"]').prop('disabled', true);
        } else if ($('select[name="_giftCardAmount"]').val() == ''){
            form.find(':input[type="submit"]').prop('disabled', true);
        } else {
            if (!form.valid()) {
                form.find(':input[type="submit"]').prop('disabled', true);
            } else {
                form.find(':input[type="submit"]').prop('disabled', false);
            }
        }

    });

    $('#GiftCertificateForm').on('change keyup mouseup', 'input[name$="_messageline1"], input[name$="messageline2"], input[name$="messageline3"], input[name$="messageline4"]', function () {
        var text = $(this).val();
        var charsLimit = $(this).data('character-limit');
        var charsUsed = text.length;
        var charsRemain = charsLimit - charsUsed;

        if (form.find('input[name$="_messageline1"]').val() === '') {
            form.find(':input[type="submit"]').prop('disabled', true);
        }

        if (charsRemain <= 0 && charsUsed >= charsLimit + 1) {
            return false;
        } else {
            $(this).closest('div.input-wrapper').next('span.char-count').find('.char-remain-count').html(charsUsed);
        }
    });

    /*
     * Message Line Drawer.
     */
    pdpForm.on('click', 'input[name$="_includegcmessage"]', function () {
        var PdpPlaceholderText = pdpForm.find('input[name$="_messageline1"]').attr('placeholder');
        if ($(this).is(':checked')) {
            pdpForm.find('.message-line-item').removeClass('hide');
            if (pdpForm.find('input[name$="_messageline1"]').length > 0) {
                pdpForm.find('input[name$="_messageline1"]').addClass('required');
                if (PdpPlaceholderText.indexOf('*') == -1) {
                    pdpForm.find('input[name$="_messageline1"]').attr('placeholder', pdpForm.find('input[name$="_messageline1"]').attr('placeholder') + ' * ');            	
                }
            }
        } else {
            pdpForm.find('.message-line-item').addClass('hide');
            pdpForm.find('input[name$="_messageline1"]').closest('.form-row').find('label .required-indicator').remove();
        }

        if (pdpForm.find('input[name$="_messageline1"]').val() === '' && $(this).is(':checked')) {
            pdpForm.find(':input[type="submit"]').prop('disabled', true);
        } else if ($('select[name$="_giftCardAmount"]').val() == ''){
            pdpForm.find(':input[type="submit"]').prop('disabled', true);
        } else {
            pdpForm.find(':input[type="submit"]').prop('disabled', false);
        }

    });

    pdpForm.on('change keyup mouseup', 'input[name$="_messageline1"], input[name$="messageline2"], input[name$="messageline3"], input[name$="messageline4"]', function () {
        var text = $(this).val();
        var charsLimit = $(this).data('character-limit');
        var charsUsed = text.length;
        var charsRemain = charsLimit - charsUsed;

        if ((pdpForm.find('input[name$="_messageline1"]').val() === '') || $('.variation-select').val().length === 0) {
            pdpForm.find(':input[type="submit"]').prop('disabled', true);
        } else {
            pdpForm.find(':input[type="submit"]').prop('disabled', false);
        }

        if (charsRemain <= 0 && charsUsed >= charsLimit + 1) {
            return false;
        } else {
            $(this).closest('div.input-wrapper').next('span.char-count').find('.char-remain-count').html(charsUsed);
        }
    });

    $('#pdpMain').on('click', '.giftproductthumbnail', function () {
        // switch indicator
        var giftcertForm = $('#GiftCertificateForm');
        $(this).closest('.vgcAltImg').find('.giftproductthumbnail.selected').removeClass('selected');
        $(this).addClass('selected');
        $('#pdpMain .product-primary-image img').attr({
            src: $(this).attr('src'),
            alt: $(this).attr('alt'),
            title: $(this).attr('alt')
        });
        var imgsrc = $('#pdpMain .product-primary-image img').attr('src');
        giftcertForm.find('input[name$="_imgurl"]').attr('value',imgsrc);
    });
};
