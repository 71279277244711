'use strict';

var util = require('./util'),
    imagesLoaded = require('imagesloaded'),
    Suggestion = require('./suggestion');
var errorsearchsuggest = require('./errorsearchsuggest');

function FlyoutSearch($flyoutSearch, $nav, defaultValue) {
    var self = this,
        currentQuery = null,
        lastQuery = null,
        runningQuery = null,
        listTotal = -1,
        listCurrent = -1,
        delay = 30,
        $resultsContainer;
    var $gutter;
    var $searchForm;
    var $searchField;
    var $searchClearButton;
    var $searchSubmitButton;
    var $staticSuggestions;
    var $flyoutContainer;
    var $header;
    var suggestion;

    /**
     * @function
     * @description Configures parameters and required object instances
     */
    this.init = function() {
        $searchForm = $flyoutSearch.find('form[name="simpleSearch"]');
        $searchField = $searchForm.find('input[name="q"]');
        $searchClearButton = $searchForm.find('.clear-search');
        $searchSubmitButton = $searchForm.find('button[type=submit]');
        $staticSuggestions = $('.static-search-suggestions', $flyoutSearch);
        $flyoutContainer = $('.flyout-results-container');
        $gutter = $flyoutSearch.find('.flyout-shadow-overlay');
        suggestion = new Suggestion($searchField);

        if (util.isMobile() && $('#navigation').length && $(window).width() < 960) {
            var overlayHeight = Math.max($(window).height(), $(document).height()) - $gutter.offset().top;
            $gutter.height(overlayHeight);
            setFlyoutMinHeight($flyoutContainer);
        }

        $searchClearButton.on('click', onClearButtonClicked);
        $searchField.attr('autocomplete', 'off'); // disable browser auto complete
        $searchField.on('focus',onSearchFieldFocus);
        $searchField.on('keyup',onKeyUp);
        $searchField.on('keydown',handleTabKey);
        $searchField.on('change',onKeyUp);

        $searchClearButton.on('keydown', function(e) {
            var key = e.which;

            if (key == 13 || key == 32)  {// the enter key code
                $(this).trigger('click');//Trigger click for keyboard accessibility
                return false;
            }
        });

        $('.menu-mask').on('click',function() {
            if ($(window).width() > 959) {
                hideDesktop();
            }
        });

        $('.search-close').on('click', function(e) {
            e.preventDefault();
            hideMobile();
        });

        return self;
    };

    this.show = function() {
        if (util.isMobile() && $(window).width() < 960) {
            showMobile();
        } else {
            showDesktop();
        }
    };

    function showMobile() {
        util.expand($flyoutSearch);
        $header = $('.top-banner-sticky').outerHeight();
        $('.header-search').css('position', 'fixed');
        $flyoutContainer.css('height', '100vh');
        $gutter.css('height', '100vh');

        if ($('.search-popular-categories > div').length != 0) {
            $('.search-popular-categories').fadeIn(100);
        }

        $nav.find('.header-search input[name="q"]').trigger('focus').val('');
        $('.search-suggestions').css('display','block');
		$('.search-popular-categories').removeClass('hide');
		$('.trending-search').removeClass('hide');
		$('.br-suggestions').addClass('hide');
		$('.br-suggestions').find('.phrase-suggestions').addClass('hide');
		$('.br-suggestions').find('.product-suggestions').addClass('hide');
		$('.br-suggestions').find('.search-all-wrapper').addClass('hide');
		$('.br-suggestions').find('#no-results').addClass('hide');
        onQueryChange();
        $flyoutContainer.removeClass('out');
        $flyoutContainer.addClass('hover');
        $searchClearButton.toggle(false); // hide

        $('body').css({
            'overflow': 'hidden'
        });

        $('#wrapper').addClass('search-active');
        $('.menu-mask').addClass('menu-mask-active');
        window.MedalliaSurveytShow = false;
        util.focusAndOpenKeyboard($searchField[0], 300); // or without the second argument
        self.clearResults();
    }

    function showDesktop() {
        util.expand($flyoutSearch);

        if ($('.search-popular-categories > div').length != 0) {
            $('.search-popular-categories').fadeIn(100);
        }

        $nav.find('.header-search input[name="q"]').trigger('focus').val('');
        $('.search-suggestions').css('display','block');
		$('.search-popular-categories').removeClass('hide');
		$('.trending-search').removeClass('hide');
		$('.br-suggestions').addClass('hide');
		$('.br-suggestions').find('.phrase-suggestions').addClass('hide');
		$('.br-suggestions').find('.product-suggestions').addClass('hide');
		$('.br-suggestions').find('.search-all-wrapper').addClass('hide');
		$('.br-suggestions').find('#no-results').addClass('hide');
        onQueryChange();
        $searchClearButton.toggle(false); // hide
        $('#wrapper').addClass('search-active');
        $('.menu-mask').addClass('menu-mask-active');
        window.MedalliaSurveytShow = false;
        $searchField.trigger('focus');
        self.clearResults();
    }

    this.hide = function() {
        if (util.isMobile() && $(window).width() < 960) {
            hideMobile();
        } else {
            hideDesktop();
        }
    };

    function hideMobile() {
        $flyoutContainer.addClass('out');
        $flyoutContainer.removeClass('hover');
        util.contract($flyoutSearch);
        $gutter.css('height', ''); // Let it flow again.
        $('body').removeAttr( 'style' );
        $('.header-search').css('position', '');
        $('#wrapper').removeClass('search-active');
        $('.menu-mask').removeClass('menu-mask-active');
        window.MedalliaSurveytShow = true;
        $('.search-icon').attr('aria-expanded', false);
    }

    function hideDesktop() {
        util.contract($flyoutSearch);
        $('#wrapper').removeClass('search-active');
        $('.menu-mask').removeClass('menu-mask-active');
        window.MedalliaSurveytShow = true;
        $('.search-icon').attr('aria-expanded', false);
    }

    /**
     * @function
     * @description trigger suggest action
     */
    this.suggest = function() {
        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)
        if (runningQuery !== currentQuery) {
            // update running query to the most recent search phrase
            runningQuery = currentQuery;
        }

        // if it's empty clear the results box and return
        if (runningQuery.length === 0) {
            self.clearResults();
            runningQuery = null;
            return;
        }

        // if the current search phrase is the same as for the last suggestion call, just return
        if (lastQuery === runningQuery) {
            runningQuery = null;
            return;
        }

        // call bloomreach API if customer is under bloomreach customer group
        if (SitePreferences.BLOOMREACH_ENABLED) {
            if (runningQuery.length <= 2 ) {
	        	self.clearResults();
	        } else {
	            var dataIDs = {
	                noResult: 'no-results',
	                querySuggest: 'query-suggest',
	                featureProduct: 'feature-products',
	                searchViewAll: 'search-view-all',
	                noHits: false
                }
            	errorsearchsuggest.getSuggestions(runningQuery, currentQuery, lastQuery, dataIDs);
            	$('.search-popular-categories').fadeOut(100);
            }
            
            // record the query that has been executed
            lastQuery = runningQuery;
    
            // reset currently running query
            runningQuery = null;
                            
            // check for another required update (if current search phrase is different from just executed call)
            if (currentQuery !== lastQuery) {
                // ... and execute immediately if search has changed while this server call was in transit
                runningQuery = currentQuery;
                setTimeout(this.suggest, delay);
            }
            self.hideLeftPanel();
        } else {
	        // build the request url
	        var reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', runningQuery);
	
	        // execute server call
	        $.get(reqUrl, function(data) {
	            var suggestionHTML = data,
	                ansLength = suggestionHTML.trim().length;
	
	            // if there are results populate the results div
	            if (ansLength === 0) {
	                self.clearResults();
	            } else {
	                // Add scroll so all results can be seen in smaller viewports
	                var winHt = $(window).outerHeight();
	                var hdrHt = $('header').outerHeight();
	                var fullSearchHt = $('.full-search').outerHeight();
	
	                // update the results div
	                $('.search-popular-categories').fadeOut(100);
	                $('.trending-search').removeClass('hide');
	                $resultsContainer.html(suggestionHTML).fadeIn(200);
	
	                updateSuggestion();
	
	                var $suggestion = $('.product-suggestions .product-suggestion, .search-suggestion-wrapper.no-result');
	                if ($suggestion.length != 0) {
	                	$('.br-suggestions').addClass('hide');
	                    // hide static suggestions
	                    $staticSuggestions.length && $staticSuggestions.hide();
	                    $staticSuggestions.hide();
	                    imagesLoaded('.product-suggestions').on('done', function() {
	                        if ((fullSearchHt + $resultsContainer.outerHeight() + hdrHt) > winHt) {
	                            $('.search-suggestions').addClass('scroll');
	                            $('.flyout-results-container').css('min-height', 0);
	                        }
	                    });
	                } else {
	                    $staticSuggestions.length && $staticSuggestions.show();
	                }
	            }
	
	            // record the query that has been executed
	            lastQuery = runningQuery;
	
	            // reset currently running query
	            runningQuery = null;
	
	            // check for another required update (if current search phrase is different from just executed call)
	            if (currentQuery !== lastQuery) {
	                // ... and execute immediately if search has changed while this server call was in transit
	                runningQuery = currentQuery;
	                setTimeout(self.suggest, delay);
	            }
	
	            self.hideLeftPanel();
	        });
        }
    };

    function updateSuggestion() {
        try {
            var $phraseContainer = $('.suggested-phrase', $resultsContainer);

            if ($phraseContainer && $phraseContainer.length) {
                var suggestedPhrase = $phraseContainer.text().replace(/\s+/g, ' ').trim();
                suggestion.suggest(suggestedPhrase);
            } else {
                suggestion.suggest('');
            }
        } catch(e) {
            suggestion.suggest('');
        }
    }

    /**
     * @function
     * @description
     */
    this.clearResults = function() {
        if (!$resultsContainer) { return; }
        $resultsContainer.html('');
        $staticSuggestions.length && $staticSuggestions.show();
        $('.trending-search').removeClass('hide');
        $('.br-suggestions').addClass('hide');
        if ($('.search-popular-categories > div').length != 0) {
            $('.search-popular-categories').fadeIn(100);
        }
    };

    /**
     * @function
     * @description
     */
    this.hideLeftPanel = function() {
        //hide left panel if there is only a matching suggested custom phrase
        if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
            $('.search-suggestion-left-panel').css('display', 'none');
            $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
            $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
        }
    };

    this.gutterClick = function(cb) {
        $gutter.on('click',function(e) {
            if ($gutter.is(e.target)) {
                e.preventDefault();
                cb();
            }
        });
    };

    function setFlyoutMinHeight($flyoutContainer) {
        $flyoutContainer.css({
            height: $(window).height()
        });
    }

    function onQueryChange() {
        currentQuery = $searchField.val() ? $searchField.val().trim() : '';
        var empty = currentQuery == '';
        $searchSubmitButton.toggleClass('faded', empty);

        if (util.isMobile()) {
            $searchClearButton.toggle(!empty);
        }
    }

    // on key up listener
    function onKeyUp(e) {
        // check and treat up and down arrows
        if (handleArrowKeys(e)) {
            return;
        } else {
            onQueryChange();

            // BUG: causes new input to be ignored while/after req is pending
            // SHOULD: Cancel previous req, queue new one.
            // no query currently running, init an update
            if (!runningQuery) {
                runningQuery = currentQuery;
                setTimeout(self.suggest, delay);
            }
        }
    }

    // on focus listener (clear default value)
    function onSearchFieldFocus() {
        var $searchSuggestions = $flyoutSearch.find('.search-suggestions');
        if (!$searchSuggestions.length) {
            // create results container if needed
            $resultsContainer = $('<div/>').attr('id', 'search-suggestions').appendTo($flyoutSearch);
            $resultsContainer = $('<div/>').attr('class', 'search-suggestions').appendTo($flyoutSearch);
        } else {
            $resultsContainer = $searchSuggestions;
        }

        if ($searchField.val() === defaultValue) {
            $searchField.val('');
        }

        if($staticSuggestions.length && !$('.product-suggestions .product-suggestion').length){
            $staticSuggestions.show();
        }
    }

    function onClearButtonClicked() {
        $searchField.val('');
        $staticSuggestions.show();

        if ($('.search-popular-categories > div').length != 0) {
            $('.search-popular-categories').fadeIn(100);
        }

        onQueryChange();
        $searchField.trigger('focus');
    }

    function handleTabKey(e) {
        var keyCode = e.keyCode || window.event.keyCode;

        if (keyCode == 9 && suggestion.hasSuggestion()) {
            e.preventDefault();
            $searchField.val(suggestion.getSuggestion());
        }
    }

    this.onEnterEscKey = function(cb) {
        $searchField.on('keydown', function(e) {
            try {
                var keyCode = e.keyCode || window.event.keyCode;

                if (keyCode === 13 || keyCode === 27) { // check for an ENTER or ESC
                    cb();
                }
            } catch(e) {
                // error happened.
            }
        });
    };

    /**
     * @function
     * @description Handles keyboard's arrow keys
     * @param keyCode Code of an arrow key to be handled
     */
    function handleArrowKeys(e) {
        // get keyCode (window.event is for IE)
        var keyCode = e.keyCode || window.event.keyCode;

        switch (keyCode) {
            case 38:
                // keyUp
                listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
                break;
            case 40:
                // keyDown
                listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
                break;
            default:
                // reset
                listCurrent = -1;
                return false;
        }

        $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
        $('input[name="q"]').val($resultsContainer.find('.selected .suggestionterm').first().text());
        return true;
    }
}

module.exports = FlyoutSearch;
