'use strict';

var util = require('./util');
var $socialBtn = $('.social-links ul li.level-one button');
var $socialContent = $('.social-links .level-two');

module.exports = function () {
    $socialBtn.on('mouseenter', function (e) {
        e.preventDefault();
        var $el = $(this);
        var $social = $el.data('social');

        $socialBtn.removeClass('hover').removeClass('active').attr('aria-expanded', false);
        $el.addClass('hover').attr('aria-expanded', true);
        
        $socialContent.each(function() {
            var $this = $(this);
            if (($this.data('social')) === $social) {
                util.expand($this);
                var padding = $this.height();
                $('.social-links ul').css('padding-bottom', padding );
            } else {
                util.contract($this);
            }
        });
    });

    $socialBtn.on('click', function (e) {
        e.preventDefault();
        var $el = $(this);
        var $social = $el.data('social');

        if ($el.hasClass('active')) {
            $el.removeClass('active').attr('aria-expanded', false);
            $socialContent.each(function() {
                var $this = $(this);
                util.contract($this);
                $('.social-links ul').css('padding-bottom', 0 );
            });
        } else if ($el.hasClass('hover')) {
            $el.removeClass('hover');
            $el.addClass('active');
        } else {
            $socialBtn.removeClass('active').removeClass('hover').attr('aria-expanded', false);
            $el.addClass('active').attr('aria-expanded', true);
        
            $socialContent.each(function() {
                var $this = $(this);
                if (($this.data('social')) === $social) {
                    util.expand($this);
                    var padding = $this.height();
                    $('.social-links ul').css('padding-bottom', padding );
                } else {
                    util.contract($this);
                }
            });
        }

        
    });
    $socialBtn.on('keydown', function (e) {
        var $el = $(this);
        if (e.which === 13 || e.which === 32 ) {
            e.preventDefault();
            $el.trigger('click');
        }
    });
}

