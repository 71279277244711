'use strict';

const OBSERVABLE_CONTAINER_CLASS = 'js-observable-container';
const productTiles = require('../../pages/plp/product-tiles');
var observer;

function initIntersectionObserver(callback) {
    const observerOptions = {
        rootMargin: '0px 0px 200px 0px',
        threshold: 0.5
    };

    if ('IntersectionObserver' in window) {
        observer = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    if (callback && typeof callback === 'function') {
                        callback(entry);
                    }

                    entry.target.classList.remove(OBSERVABLE_CONTAINER_CLASS);
                    observer.unobserve(entry.target);
                }
            });
        },observerOptions);
    }

    return observer;
}

function lazyLoadVideos(container, firstHover) {
    const LAZY_LOAD_VIDEO_CLASS = 'js-lazy';
    if (container) {
        const lazyVideos = container.querySelectorAll('video.' + LAZY_LOAD_VIDEO_CLASS);
        lazyVideos.forEach(function (video) {
            const swiper = video.closest('.js-swiper-slide-container');
            const isActiveSlide = swiper ? swiper.classList.contains('swiper-slide-active') : true;
            var wasSet = false;
            video.querySelectorAll('source').forEach((videoSource) => {
                var setSrc = typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE';
                setSrc = setSrc && (!firstHover || firstHover && isActiveSlide);
                if (setSrc) {
                    videoSource.src = videoSource.dataset.src;
                    wasSet = true;
                }
            });

            if (wasSet) {
                video.load();
                video.classList.remove(LAZY_LOAD_VIDEO_CLASS);
            }
        });
    }
}

function initProductTileIntersectionObserver() {
    return initIntersectionObserver(function (entry) {
        const SWIPER_CONTAINER_CLASS = 'js-product-tile-swiper';
        const SWIPER_SLIDE_CLASS = 'js-swiper-slide-container';
        const TILE_FIRST_IMAGE = 'js-product-tile-first-image';
        const FEATURED = 'js-featured-image';

        const swiperContainer = entry.target.querySelector('.' + SWIPER_CONTAINER_CLASS);
        const firstImage = entry.target.querySelector('.' + TILE_FIRST_IMAGE);
        const featuredImages = entry.target.querySelectorAll('.' + FEATURED);

        if (swiperContainer) {
            productTiles.initProductTileSwiper(`.${SWIPER_CONTAINER_CLASS}[data-initialized="false"]`, $(entry.target));
            const firstSlideElement = swiperContainer.swiper ? swiperContainer.swiper.slides[swiperContainer.swiper.activeIndex] : swiperContainer.querySelector('.' + SWIPER_SLIDE_CLASS);
            lazyLoadVideos(firstSlideElement);
        }

        if (firstImage) {
            lazyLoadVideos(firstImage);
        }

        if (featuredImages.length) {
            featuredImages.forEach((image) => {
                lazyLoadVideos(image);
            });
        }
    });
}

function addElementsToObserver(container, observer) {
    const OBSERVING_LOCKED_ATTRIBUTE = 'data-observing';

    if (container) {
        container.querySelectorAll('.' + OBSERVABLE_CONTAINER_CLASS).forEach(function (el) {
            if (!el.getAttribute(OBSERVING_LOCKED_ATTRIBUTE)) {
                observer.observe(el);
                el.setAttribute(OBSERVING_LOCKED_ATTRIBUTE, true);
            }
        });
    }
}

exports.initProductTileIntersectionObserver = initProductTileIntersectionObserver;
exports.addElementsToObserver = addElementsToObserver;
exports.lazyLoadVideos = lazyLoadVideos;
