'use strict';

/*************************************************************************************************/
/**********************************Page Tracking for Ajax calls***********************************/
/*************************************************************************************************/
exports.quickview = function (quickviewURL) {
    if (!quickviewURL) {
        return;
    }

    ga('set', 'page', quickviewURL);
    ga('send', 'pageview');
    return;
};

//SPC page tracking - This can be removed if SPC is not in scope.
exports.spc = function () {

    var isSPCLogin = $('.spc-login').length > 0,
        isSPCShipping = $('.spc-shipping').length > 0,
        isSPCMultiShipping = $('.spc-multi-shipping').length > 0,
        isSPCBilling = $('.spc-billing').length > 0,
        isSPCSummary = $('.spc-summary').length > 0;

    if (isSPCLogin){
        ga('set', 'page', '/spccheckoutlogin.html');
        ga('send', 'pageview');
        return;
    } else if (isSPCShipping){
        ga('set', 'page', '/spcshipping.html');
        ga('send', 'pageview');
        return;
    } else if (isSPCMultiShipping){
        ga('set', 'page', '/spcmultishipping.html');
        ga('send', 'pageview');
        return;
    } else if (isSPCBilling){
        ga('set', 'page', '/spcbilling.html');
        ga('send', 'pageview');
        return;
    } else if (isSPCSummary){
        ga('set', 'page', '/spcsummary.html');
        ga('send', 'pageview');
        return;
    }
};
