'use strict';

var naPhone = /\d/g;
var regex = {
    phone: {
        us: naPhone,
        other: /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/
    },
     postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        ch: /^([0-9]){4}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/
};
// global form validator settings
var settings = {
    ignore:'.form-ignore, :hidden',
    errorClass: 'error',
    errorElement: 'span',
    errorPlacement: function (error, element) {
        if (element.is('.input-checkbox')) {
            error.appendTo(element.parent());
        } else {
            // default from plugin
            element.after(error);
        }
    },
    onkeyup:  false,
    onfocusout: function (element) {
        var $this = this;
        var currentForm = this.currentForm;

        if (!this.checkable(element)) {
            this.element(element);
        }
        if ($(element).closest('.cc-number').length > 0 && $(element).closest('.cc-number').find('.form-caption').text() == 'Invalid Credit Card Number. Please check number and re-enter.' && !$(element).closest('.cc-number').find('.form-caption').hasClass('cc-error')) {
            $(element).removeClass('cc-error');
            $(element).closest('.cc-number').find('.form-caption').text('');
        }
        
        if ($(element).closest('.user-cred-details').length > 0) {
    		if ($(element).closest('.user-cred-details').find('.cc-error').length > 1 || $(element).closest('.user-cred-details').find('.cvn span.error').css('display') == 'block') {
        		$(element).closest('form').find('.button-fancy-large').prop('disabled', true);
        		$(element).closest('#container-wrap').find('.mini-summary-btn').prop('disabled',true);
        	}
            if ($(element).closest('.user-cred-details').find('.expired').length > 0) {
                $(element).closest('form').find('.button-fancy-large').prop('disabled', true);
                $(element).closest('#container-wrap').find('.mini-summary-btn').prop('disabled',true);
            }
    		if (($(element).closest('.user-cred-details').find('.expired').length == 0) && $(element).closest('.input-wrapper').hasClass('cc-number') && $(element).closest('.user-cred-details').find('.cc-error').length == 0 && $(element).closest('.user-cred-details').find('.cvn span.error').css('display') != 'block' && $(element).closest('form').valid()) {
        		$(element).closest('form').find('.button-fancy-large').prop('disabled', false);
        		$(element).closest('#container-wrap').find('.mini-summary-btn').prop('disabled',false);  		
    		}
        }
        
        $.each(currentForm, function (key, value) {
            if ($(value).val()) {
                if (!$this.checkable(value)) {
                    $this.element(value);
                }
            }
        });

        var searchRegex = new RegExp('[\/|\\\\]');
        var showPatternError = $(element).closest('#dwfrm_storelocator').length && $(element).hasClass('searchKey') && $(element).val() && searchRegex.test($(element).val());
        if (showPatternError && !$(element).siblings('span.error.regex').length) {
            $("<span id='" + $(element).attr('name') + "-error'" + " class='error regex'>" + window.Resources.STORE_PATTERN_ERROR + "</span>").insertAfter($(element));
        } else if (showPatternError) {
            $(element).siblings('span.error.regex').text(window.Resources.STORE_PATTERN_ERROR);
            $(element).siblings('span.error.regex').show();
        } else {
            $(element).siblings('span.error.regex').remove();
        }

        if ($('.gift-Message-error').length > 0) {
        	$('button[name$="chkoutButton"]').prop("disabled", true);
        	$('button[name$="_shippingAddress_save"]').prop("disabled", true);
        }
        if($(currentForm).find('input[name="dwfrm_giftcert_purchase_customPGCAmount"]').hasClass('rangeError') || $(currentForm).find('input[name="dwfrm_giftcert_purchase_customPGCAmount"]').hasClass('amountError') || $(currentForm).find('input[name="dwfrm_giftcert_purchase_customPGCAmount"]').hasClass('error')){
        	$(currentForm).find(':input[type="submit"]').prop('disabled', true);
        	if($('#GiftCertificateForm .cert-amount').find('.empty-error').length == 1) {
        		$('#GiftCertificateForm .cert-amount').find('.empty-error').hide();
        	}
        }
    },
    onclick: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
        if ($('.gift-Message-error').length > 0) {
        	$('button[name$="chkoutButton"]').prop("disabled", true);
        	$('button[name$="_shippingAddress_save"]').prop("disabled", true);
        }
    },
    unhighlight: function (element, errorClass, validClass) {
        if (!$(element).hasClass('required') && $(element).val() == '') {
            $(element).removeClass(errorClass).removeClass(validClass);
        } else {
            $(element).removeClass(errorClass).addClass(validClass);
        }
        if ($('.gift-Message-error').length > 0) {
        	$('button[name$="chkoutButton"]').prop("disabled", true);
        	$('button[name$="_shippingAddress_save"]').prop("disabled", true);
        } 
    }
};



/**
 * @function
 * @description Validates a given password input
 * @param {String} value The password which will be validated
 * @param {String} messages The messages container
 */

var validatePassword= function (value, messages) {

    var isValidPassword = true;
    if(value.length < 8 || value.length > 20) {  // 8 - 20 characters
        isValidPassword = false;
        $(messages).find('.length-error').removeClass('valid');
    } else {
        $(messages).find('.length-error').addClass('valid').removeClass('pwerror');
    }

    if( /^[a-zA-Z0-9 ]*$/.test(value)) { // symbol
        isValidPassword = false;
        $(messages).find('.symbol-error').removeClass('valid');
    } else {
        $(messages).find('.symbol-error').addClass('valid').removeClass('pwerror');
    }

    if(!/\d/.test(value)) { // number
        isValidPassword = false;
        $(messages).find('.number-error').removeClass('valid');
    } else {
        $(messages).find('.number-error').addClass('valid').removeClass('pwerror');
    }

    if(!/[A-Z]/.test(value)) { // uppercase letters
        isValidPassword = false;
        $(messages).find('.uppercase-error').removeClass('valid');
    } else {
        $(messages).find('.uppercase-error').addClass('valid').removeClass('pwerror');
    }

    if(!/[a-z]/.test(value)) { // lowercase letters
        isValidPassword = false;
        $(messages).find('.lowercase-error').removeClass('valid');
    } else {
        $(messages).find('.lowercase-error').addClass('valid').removeClass('pwerror');
    }

    return isValidPassword;
};

var passwordConstraints = function () {
	$('.save-favorites-dialog .wishlist-favorites').find('.pw-container input.password').addClass('wlpassword');
	$('.pw-container input[type=password]').on('keyup', function() {
	    var $messagesContainer = $(this).parents('.pw-container').find('.pw-errors');
	    var isValid = validatePassword($(this).val(), $messagesContainer);
        $(this).parents('.pw-container').find('.show-password').fadeIn();
        $(this).parents('.pw-container').find('span.error').hide();
	    if(!isValid) {
	        $(this).parents('.pw-container').find('.pw-errors').slideDown();
	    } else {
	        $(this).parents('.pw-container').find('.pw-errors').slideUp();
	    }
	})

    $('.pw-container input.password').on('focusout', function() {
        if($('.pw-errors').is(':visible')) {
            $('.pw-errors div:not(.valid)').addClass('pwerror');
        }
        if( $('.pw-errors .pwerror').length > 0 && $('.pw-errors').is(":visible")) {
        	if ($('.save-favorites-dialog .wishlist-favorites').is(":visible")) {
        		$('.pw-container input.password').removeClass('valid').addClass('error');
        	}
        } else {
        	if ($('.save-favorites-dialog .wishlist-favorites').is(":visible")) {
        		$('.pw-container input.password').removeClass('error').addClass('valid');
        	}
        }
        if ($(this).val().length > 0) {
            $('.pw-errors .pw-requirements-not-met').show();
        } else {
            $('.pw-errors .pw-requirements-not-met').hide();
        }
    });

    if ($('.pw-container') && $('.pw-container').find('input').hasClass('error')) {
        var $messagesContainer = $('.pw-container').find('.pw-errors');
        var $pwInput = $('.pw-container').find('input.error');
	    var isValid = validatePassword($pwInput.val(), $messagesContainer);
        $pwInput.parents('.pw-container').find('.show-password').fadeIn();
	    if(!isValid) {
            $('.pw-errors div:not(.valid)').addClass('pwerror');
            $pwInput.parents('.pw-container').find('.pw-errors').slideDown();
	    } else {
	        $pwInput.parents('.pw-container').find('.pw-errors').slideUp();
	    }
    }

    // Show / Hide Password
    $(".show-password, .hide-password").on('click', function() {
        var passwordId = $(this).parents('.pw-container').find('input').attr('id');
        if ($(this).hasClass('show-password')) {
            $("#" + passwordId).attr("type", "text");
            $(this).parent().find(".show-password").hide();
            $(this).parent().find(".hide-password").show();
        } else {
            $("#" + passwordId).attr("type", "password");
            $(this).parent().find(".hide-password").hide();
            $(this).parent().find(".show-password").show();
        }
    });
}

passwordConstraints();

var validateWhishlistPassword = function(value, el) {
	if ($('.save-favorites-dialog .wishlist-favorites').is(":visible")) {
		var isValid = false;
	    if (!$(el).hasClass('error')) {
	    	isValid = true;
	    }
	    return isValid;
	}
}

/**
 * @function
 * @description Validates confirm password field with given password
 * @param {String} value The password which will be validated
 * @param {String} el The input field
 */
var validateConfirmPassword = function (value, el) {
	var $elementForm = $(el).closest("form");
	return $elementForm.find('.password').val() === $elementForm.find('.confirmpassword').val();
};

/**
 * @function
 * @description Validates confirm password field with given password
 * @param {String} value The password which will be validated
 * @param {String} el The input field
 */
var validateConfirmNewPassword = function (value, el) {
    var $elementForm = $(el).closest("form");
    return $elementForm.find('.newpassword').val() === $elementForm.find('.newpasswordconfirm').val();
};

/**
 * Add email match
 */
var emailMatch = function (value, el) {
    var isValid = false;
    
    var email = $(el).closest('form').find('input[name$="_customer_email"]').val();
    var emailConfirm = $(el).closest('form').find('input[name$="_customer_emailconfirm"]').val();
    var originalEmail = $(el).closest('form').find('input[name$="originalEmail"]').val();

    if (emailConfirm != '' && email.toLowerCase() != emailConfirm.toLowerCase()) {
        isValid = false;
    } else if (emailConfirm == '' && email != originalEmail) {
    	isValid = false;
	} else {
		isValid = true;
	}

    return isValid;
};


/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var isOptional = this.optional(el);
    var isValid = false;
    var isValidPhone = false;
    var phoneInput = $('input[name$=_addressFields_phone]');
    var isValidPhoneIntl = phoneInput.intlTelInput('isValidNumber');
    var countryval = $(el).closest('form').find('.country').val() || $(el).closest('form').find('.country  option:selected').val();
    // value = value.replace(/\+/g, '');
    
    if (value != null && value != '') {
        for (var phRegex in regex.phone) {
            var country = regex.phone[phRegex];
            var phoneval = value.match(/\d/g);
            if (countryval === 'US' && phoneval != null && phoneval.length == 10) {
                isValidPhone = true;
                continue;
            } else if (countryval !== 'US' && phoneval != null && phoneval.length >= 10) {
                isValidPhone = true;
                continue;
            }
            if (country.test(value) && phoneval != null && phoneval.length >= 10) {
                isValidPhone = true;
                continue;
            }
        }   
    }

    if (isValidPhone === true || isValidPhoneIntl === true) {
        isValid = true;
        phoneInput.val(value);
    }

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    //DIGITAL-15911,DIGITAL-16039: We also need to allow only these special characters: éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒ
    var nameRegEx = /^[éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒìòÌÒa-zA-Z0-9\s]+$/;
    var isValid = nameRegEx.test(value);
    return isOptional || isValid;
};

/**
 * @function
 * @description Validates AOS Employee Number based on site preference
 */
var validateAOSemployee = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var nameRegEx = /^[0-9]{1,10}$/;
    var isValid = false;
        if (SitePreferences.AOSValidation) {
            isValid = true; 
        } else {
            isValid = nameRegEx.test(value);
        }
        
    return isOptional || isValid;
};

/**
 * Add card CVN validation method to jQuery validation plugin.
 */
var cardcvnValidation = function (value, el) {
    value = value.replace(/\s+/g, '');
    $('.cvn .error-message').hide();
    if (value.length<3 ||value.length>4 ){
        return false;
    }
    var cardType = '';
    var cardNumber = '';
    if($(el).hasClass('express_checkout_cvn')) {	//For express checkout
    	cardType = $(el).closest('.details.payment-instruments').find('.payment-type').data('cardtype');
    	cardNumber = $(el).closest('.details.payment-instruments').find('.cc-type').text();
    } else {
    	cardType = $(el).closest('form').find('.user-cred-details').find('[name$="_creditCard_type"]').val();
        cardNumber = $(el).closest('form').find('.user-cred-details').find('.cc-number input').val();
    }
    if ((cardNumber != '' && cardType != null && cardType != 'Amex' && value.length > 3) || (cardNumber != '' && cardType != null && cardType == 'Amex' && value.length == 3)) {
        $(el).closest('form').find('.button-fancy-large').prop('disabled', true);
        $(el).closest('#container-wrap').find('.mini-summary-btn').prop('disabled',true);
        return false;   
    }
    
    return this.optional(el) || value.match(/^[0-9,*]*$/);
};

/**
 * Add card exp date validation
 */
var cardexpdateValidation = function (value, el) {
    var isValid = false;
	var expDate = $(el).closest('form').find('[name$="_expirationdate_date"]').val();
	var expMonth = expDate.split("/")[0];
	var expYear =  expDate.split("/")[1];
    var currentYear = parseInt(new Date().getFullYear().toString().substr(2,2));
    var currentMonth = parseInt(new Date().getMonth()+1);

    if ((expYear == currentYear && expMonth < currentMonth) || (expYear < currentYear) || (expMonth > 12) || (expYear > (currentYear+20)) || (expYear == (currentYear+20) && expMonth > currentMonth)) {
		isValid = false;
	} else if ((value.length == 5) && ((expYear > currentYear) || (expYear == currentYear && expMonth >= currentMonth))) {
		isValid = true;
    }
    return isValid;
};

// DIGITAL-21853
var ProfanityCheck = function(value, el) {
    var profanityWords = SitePreferences.PROFANITY_WORDS;
    var result = false;
    if (profanityWords == undefined || profanityWords == 'null' || profanityWords == '' || value == '') {
        if ($(el).closest('#GiftCertificateForm').length > 0) {
            if ($(el).closest('#GiftCertificateForm').find('.Profanity').closest('.field-wrapper').find('span').length == 0 || !($(el).closest('#GiftCertificateForm').find('.Profanity').hasClass('error'))) {
                $(el).closest('#GiftCertificateForm').find(':input[type="submit"]').prop('disabled', false);
            }
    } 
    else if($(el).closest(".gift-message").length > 0) {
        	$(el).closest('.gift-field').find('.gift-Message-error').remove();
        	$(el).removeClass('customerror');
            if ($('.gift-Message-error').length == 0 && $(el).closest('form').valid()) {
                $('button[name$="chkoutButton"]').prop("disabled", false);
                $('button[name$="_shippingAddress_save"]').prop("disabled", false);
            }
        }
        return true;
    }
    profanityWords = profanityWords.split('|');
    var valuecheck = value.toLowerCase().replace(/[.,\/#@!$%\^&\*;:{}=\-_`~()?\+\[\]|'"<>]/g," ");
    var newValueCheck = valuecheck.replace(/[0-9]/g," ");
    var newValueCheckArray = newValueCheck.split(" ");
    if ($(el).closest("#ContactForm").length > 0 || $(el).closest('#GiftCertificateForm').length > 0 || $(el).closest(".gift-message").length > 0) {
        var intersection = newValueCheckArray.filter(element => profanityWords.includes(element));
        if(intersection.length > 0 && intersection !== 'undefined') {
            result = true;
        }
    }
    
    if(result) {
        if ($(el).closest('#GiftCertificateForm').length > 0) {
            $(el).closest('#GiftCertificateForm').find(':input[type="submit"]').prop('disabled', true);
        }
        else if($(el).closest(".gift-message").length > 0) {
            $(el).addClass('customerror');
            $(el).closest('.gift-field').find('.gift-Message-error').remove();
            $(el).closest('.gift-field').append('<span class="gift-Message-error error">' + Resources.PROFANITY_INCORRECTCHARACTERSS + '</span>');
            $('button[name$="chkoutButton"]').prop('disabled', true);
            $('button[name$="_shippingAddress_save"]').prop('disabled', true); 
            
        }
    	return false;
    }
    if ($(el).closest('#GiftCertificateForm').length > 0) {
        if ($(el).closest('#GiftCertificateForm').find('.Profanity').closest('.field-wrapper').find('span').length == 0 || !($(el).closest('#GiftCertificateForm').find('.Profanity').hasClass('error'))) {
            $(el).closest('#GiftCertificateForm').find(':input[type="submit"]').prop('disabled', false);
        }
    }
    else if($(el).closest(".gift-message").length > 0){
    	$(el).closest('.gift-field').find('.gift-Message-error').remove();
    	$(el).removeClass('customerror');
        if ($('.gift-Message-error').length == 0 && $(el).closest('form').valid()) {
            $('button[name$="chkoutButton"]').prop("disabled", false);
            $('button[name$="_shippingAddress_save"]').prop("disabled", false);
        }
    }
    return true;
};

/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.VALIDATE_PHONE);
$.validator.addMethod('mobile', validatePhone, Resources.VALIDATE_PHONE);
$.validator.addMethod('Profanity', ProfanityCheck, Resources.PROFANITY_INCORRECTCHARACTERSS);
$.validator.addMethod('emailconfirm', emailMatch, Resources.EMAIL_MISMATCH_ERROR);
$.validator.addMethod('confirmpassword', validateConfirmPassword, Resources.VALIDATE_CONFIRM_PASSWORD);
$.validator.addMethod('newpasswordconfirm', validateConfirmNewPassword, Resources.VALIDATE_CONFIRM_PASSWORD);
$.validator.addMethod('newpassword', validatePassword, '');
$.validator.addMethod('wlpassword', validateWhishlistPassword, '');
/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.VALIDATE_INCORRECTCHARACTERSS);

/**
 * Add Employee Shopping validation method to jQuery validation plugin.
 * Text fields must have 'employee-aos-number' css class to be validated as not an employee
 */
$.validator.addMethod('employee-aos-number', validateAOSemployee, Resources.VALIDATE_VALIDCHARACTERS);

/**
 * Add credit card  validation method to jQuery validation plugin.
 * Text fields must have 'cvn_number' css class to be validated as not a credit card
 */
$.validator.addMethod('cvn_number', cardcvnValidation, Resources.CREDIT_CVN_VALIDATION);
$.validator.addMethod('exp_date', cardexpdateValidation, Resources.CREDIT_EXPDT_VALIDATION);
/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= Resources.GIFT_CERT_AMOUNT_MIN_VALUE) && (parseFloat(value) <= Resources.GIFT_CERT_AMOUNT_MAX_VALUE);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) {
        return true;
    }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

$.validator.addMethod('message1', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = false;

    if ($.trim(value).length === 0) {
        isValid = false;
    } else if ($.trim(value).length > 0) {
        isValid = true;
    }
    return isOptional || isValid;
}, Resources.VALIDATE_REQUIRED);

function validateZip(value, el) {
    var isValid = false;
    var country = $(el).closest('form').find('.country').val() || $(el).closest('form').find('.country  option:selected').val();
    var isOptional = this.optional(el);
    var zipRegEx = /^\d{5}(-\d{4})?$/;
    var isStoreLocatorZip = $(el).attr('id') === 'dwfrm_storelocator_postalCode' ? true : false;
    value = $.trim(value);
    $(el).val(value);

    if (country === undefined && isStoreLocatorZip !== true) {
        return true;
    }
    if (country != 'US') {
        var regXLatin = /[^\u0020-\u007F\u00A0-\u024F\u1E00-\u1EFF]/g;  
        var isnonlatinValid = !(regXLatin.test(value));
        return isnonlatinValid;
    }

    isValid = zipRegEx.test(value);
    return isOptional || isValid;
}
$.validator.addMethod('postal', validateZip, Resources.INVALID_POSTAL);

/*Start JIRA PREV-678 : Email and confirm email fields validation in gift certificate page not as expected.*/
var validateEmail = function (value, el) {
    value = $.trim(value);
    $(el).val(value);
    var isOptional = this.optional(el);
    var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = emailRegEx.test(value);
    return isOptional || isValid;
};


$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);
/*End JIRA PREV-678*/

$.validator.addMethod('countryValid', function (value, el) {
	$(el).val(value);
    var isOptional = this.optional(el),
    	isValid = true;
    if($(el).hasClass("paypal-error-field")) {
    	isValid = false;
    }
    return isOptional || isValid;
}, function(params, el) {
	var thsParent = $(el).closest(".input-wrapper");
	var existingMsg = "";
	if(thsParent.find(".form-caption.error-message").text().length > 0) {
		existingMsg = thsParent.find(".form-caption.error-message").text();
	} else {
		existingMsg = thsParent.find("span.error").text();
	}
	return existingMsg;
});


var validateFirstName = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    //DIGITAL-15911,DIGITAL-16039: We also need to allow only these special characters: éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒ
    var nameRegEx = /^[éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒìòÌÒa-zA-Z\s\'\-]+$/;
    var isValid = nameRegEx.test(value);
    return isOptional || isValid;
};

var validateLastName = function (value, el) { 
    $(el).val(value);
    var isOptional = this.optional(el);
    // Minimum 2 characters that may contain alphabet or space or hypen, restricting special characters
    //DIGITAL-15911,DIGITAL-16039: Previous regex: /^[a-zA-Z0-9\'\-\s]{2,}$/ Similar to firstname, We need to allow only these special characters: éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒ
    var nameRegEx = /^[éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒìòÌÒa-zA-Z\s\'\-]+$/;
    var isValid = nameRegEx.test(value);
    return isOptional || isValid;
};

var getAmount = function (str) {
	var isValid;
	var spCharCounter = 0;
	//Filter the string to get a proper number. Eg 1,000.00 becomes 1000.00 for dollars/ pounds and
	//1.000,00 becomes 1000,00 for Euro
	if (Resources.CURRENCY_SYMBOL == '€') {
		str = str.replace(/(\.)*/g, '');	
	} else {
		str = str.replace(/(,)*/g, '');
	}
	for (var i = 0; i < str.length; i++) {
		if (str.charAt(i) == '.' || str.charAt(i) == ',') {
			spCharCounter++;
		}
	}
	//To be a valid number, there should be either one special character or 0 special characters
	if (spCharCounter > 1) {
		isValid = false;
	} else {
		isValid = true;
	}
	if (isValid){
		return parseFloat(str.replace(/ |,/g, function ($0){
			return $0 == ',' ? '.' : '';
		}))
	}
	return null
}

var validateRange = function (val, min, max) {
    return (val >= min && val <= max)
};

var validateDonationAmount = function (value, el) {	
	$('.pdpForm').find('.pdp-messaging .pdp-error-msg').text('');
   $(el).val(value);
   var isOptional = this.optional(el);
   var amount = getAmount(value);
   var isValid = validateRange(amount, 1, 10000);
   $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
   return isOptional || isValid;
};
var validateDonationCheckout = function (value, el) {	
	$('.pdpForm').find('.pdp-messaging .pdp-error-msg').text('');
   $(el).val(value);
   var isOptional = this.optional(el);
   var amount = getAmount(value);
   var isValid = isNaN(amount) || validateRange(amount, 1, 10000);
   $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
   return isOptional || isValid;
};
var validateGiftcertAmount = function (value, el) {	
	$(el).val(value);
    var isOptional = this.optional(el);
    value = value.replace(/C?\$/, '');
    var amount = getAmount(value);
    var amountRegEx = /^[0-9.,]+$/;
    var isValid = !isNaN(amount) && parseInt(amount) === amount && amountRegEx.test(value);
    $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
    var PGCAmount =  $(el).closest('form').find('input[name="dwfrm_giftcert_purchase_customPGCAmount"]');
    if(PGCAmount.length != 0){
    	$(PGCAmount).toggleClass('amountError', !isValid);
    }
    return isOptional || isValid;
};
var validateGiftcertRange = function (value, el) {	
	 $(el).val(value);
    var isOptional = this.optional(el);
    value = value.replace(/C?\$/, '');
    var amount = getAmount(value);
    var isValid = validateRange(amount, Resources.GIFT_CERT_AMOUNT_MIN_VALUE, Resources.GIFT_CERT_AMOUNT_MAX_VALUE);
    $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
    var PGCAmount =  $(el).closest('form').find('input[name="dwfrm_giftcert_purchase_customPGCAmount"]');
    if(PGCAmount.length != 0){
    	$(PGCAmount).toggleClass('rangeError', !isValid);
    }
    return isOptional || isValid;
};
var validateAmount = function (value, el) {	
    $(el).val(value);
    var isOptional = this.optional(el);
    var amount = getAmount(value);
    var amountRegEx = /^[0-9.,]+$/;
    var isValid = !isNaN(amount) && parseInt(amount) === amount && amountRegEx.test(value);
    $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
    return isOptional || isValid;
};

var validateLatin = function(value) {
    var regXLatin = /[^\u0020-\u007F\u00A0-\u024F\u1E00-\u1EFF]/g;  
    var isValid = !(regXLatin.test(value));
    return isValid;
};

var validatespecialchar = function(value) {
	//DIGITAL-15911,DIGITAL-16039: We also need to allow only these special characters: éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒ
	var regXSpecialChar = /^[éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒìòÌÒa-zA-Z0-9\(\s\'\-\#\.\,\/\)]+$/; 
    var isValid = regXSpecialChar.test(value);
    if(value == ""){
    	isValid = true;
    }
    return isValid;
};

var validatespecialcharfortradeform = function(value, el) {
	// for trade program form 
	var regXSpecialChar = /^[éâêîôûàèùëïüÉÂÊÎÔÛÀÈÙÜËÏçöäæœÇÖÄÆŒìòÌÒa-zA-Z0-9\s\'\-]+$/;
    if ($(el).parents().hasClass('existing-trade')) {
        regXSpecialChar = /^[a-zA-Z0-9\-]+$/;
    }
    var isValid = regXSpecialChar.test(value);
    if(value == ""){
    	isValid = true;
    }
    return isValid;
};

$.validator.addMethod('trade-specialcharcheck', validatespecialcharfortradeform, Resources.VALIDATE_VALIDCHARACTERS);
$.validator.addMethod('latincheck', validateLatin, Resources.VALIDATE_VALIDCHARACTERS);
$.validator.addMethod('specialcharcheck', validatespecialchar, Resources.VALIDATE_VALIDCHARACTERS);
$.validator.addMethod('firstName', validateFirstName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('lastName', validateLastName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('firstname', validateFirstName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('lastname', validateLastName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('amount', validateAmount, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('giftcertAmount', validateGiftcertAmount, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('giftcertAmountRange', validateGiftcertRange, Resources.GIFT_CERT_AMOUNT_INVALID);
$.validator.addMethod('donationAmountRange', validateDonationAmount, Resources.DONATION_AMOUNT_INVALID);
$.validator.addMethod('donationAmountCheckout', validateDonationCheckout, Resources.DONATION_AMOUNT_INVALID);

var validateStoreNumber = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var numberRegEx = /^\d{1,5}$/;
    var isValid = numberRegEx.test(value);
    return isOptional || isValid;
}

var validateAccntNumber = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var numberRegEx = /^\d{1,6}$/;
    var isValid = numberRegEx.test(value);
    return isOptional || isValid;
}

$.validator.addMethod('storeNumber', validateStoreNumber, Resources.VALIDATE_NUMBER);
$.validator.addMethod('employeeNumber', validateAccntNumber, Resources.VALIDATE_NUMBER);

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    profanityCheck: ProfanityCheck,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    },
    passwordConstraints: function () {
    	passwordConstraints();
    }
};

module.exports = validator;
