'use strict';

module.exports = function() {
    $(window).on('load',function () {
        var swiperInstances = {};
        $('.triple-carousel').each(function(index, element) {
            var $this = $(this);
            $this.addClass('instance-' + index);
            $this.siblings('.horizontal-carousel-control-prev').addClass('btn-prev-' + index);
            $this.siblings('.horizontal-carousel-control-next').addClass('btn-next-' + index);
            swiperInstances[index] = new Swiper('.instance-' + index, {
                slidesPerView: 3,
                spaceBetween: 20,
                loop: true,
                navigation: {
                    nextEl: '.btn-next-' + index,
                    prevEl: '.btn-prev-' + index,
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    960: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    600: {
                        slidesPerView: 1.75,
                        spaceBetween: 10,
                    }
                }
            });
        });
    });

    var singleswiperInstances = {};
    $('.single-carousel').each(function(index, element) {
        var $this = $(this);
        $this.addClass('single-instance-' + index);
        $this.siblings('.horizontal-carousel-control-prev').addClass('single-btn-prev-' + index);
        $this.siblings('.horizontal-carousel-control-next').addClass('single-btn-next-' + index);
        singleswiperInstances[index] = new Swiper('.single-instance-' + index, {
            slidesPerView: 1,
            loop: false,
            navigation: {
                nextEl: '.single-btn-next-' + index,
                prevEl: '.single-btn-prev-' + index,
            },
            on: {
                init: function() {
                    if(this.slides.length<2){
                        this.allowTouchMove = false;
                    }
                    this.el.dispatchEvent(new CustomEvent('SwiperEvent',{detail: 'init', bubbles: true, cancelBubble: true, cancelable: true}));
                },
                transitionStart:function (){
                    this.el.dispatchEvent(new CustomEvent('SwiperEvent',{detail: 'transitionStart', bubbles: true, cancelBubble: true, cancelable: true}));
                },
                transitionEnd:function (){
                    this.el.dispatchEvent(new CustomEvent('SwiperEvent',{detail: 'transitionEnd', bubbles: true, cancelBubble: true, cancelable: true}));
                }
            }
        });
        var count = singleswiperInstances[index].slides.length;

        if (count < 2) {
            $(this).siblings('.horizontal-carousel-control-prev, .horizontal-carousel-control-next').remove();
        }
    });
}
