'use strict';

var util = require('../../util');
var level2 = require('./level2');
const ACTIVE_CLASS = 'active';
const SUB_NAV_ACTIVE_CLASS = 'sub-nav-active';
const FADE_IN_CLASS = 'fadein';

const $header = $('header');

const {gsap}  = require('gsap');

function cleanFakeNav() {
    $header.removeClass('no-js');
}

async function loadLevel2Flyouts($item) {
    if (isAllNavLoaded()) {
        return;
    }
    if ($item.is('.js-nav-item-with-flyout') && !($item.data('loadState') === 'loading' || $item.data('loadState') === 'loaded')) {
        $item.data('loadState', 'loading');
        await level2.loadFlyouts($item.data('flyout'), $item.find('.js-nav-level-2-item'));
        $item.data('loadState', 'loaded');
    }
}

const HOVER_CLASS = 'hover';
const OUT_CLASS = 'out';

async function onDesktopHover($item) {
    $item.find('.js-nav-level-2').scrollTop(0); //reset scrolls for smaller screens

    $item.removeClass(OUT_CLASS).addClass(HOVER_CLASS);

    const $navContainer = $item.find('.js-nav-level-2');

    // mark default level 2 item as highlighted
    level2.highlightDefaultItem($navContainer);

    $navContainer.attr('aria-expanded', true).attr('aria-hidden', false);

    const $l3Container = $navContainer.find('.js-level3-items-container');

    // hide all level 3 flyouts
    $l3Container.find('.js-nav-level-3').attr('aria-hidden', true).attr('aria-expanded', false).hide();
    if ($item.data('loadState') === 'loaded') {
        cleanFakeNav();
    } else {
        await loadLevel2Flyouts($item).then(function () {
            cleanFakeNav();
        });
    }

    // show default level 3 flyout
    const $currentFlyout = $l3Container.find(`.${$item.find('.js-nav-item-default').data('flyout')}`);
    $currentFlyout.attr('aria-hidden', false).attr('aria-expanded', true).show();
    $currentFlyout.find('.js-nav-level-3-wrapper').css({ opacity: 1 });
    document.dispatchEvent(new CustomEvent('contentChanged', { detail: { action: 'flyout_in', cont: $currentFlyout.length && $currentFlyout[0] } }));
}

function initializeEventsForDesktop() {

    const $navigation = $('#navigation');

    function onHoverIntentOver() {
        if (util.isDesktopSize()) { // >= 960px
            onDesktopHover($(this))
        }
    }

    function onHoverIntentOut() {
        if (util.isDesktopSize()) { // >= 960px
            const $item = $(this); // li.js-nav-level-1-item

            $item.removeClass(HOVER_CLASS).addClass(OUT_CLASS);

            const $navContainer = $item.find('.js-nav-level-2');

            $navContainer.attr('aria-expanded', false).attr('aria-hidden', true);

            const $l3Container = $navContainer.find('.js-level3-items-container');
            $l3Container.find('.js-nav-level-3-wrapper').css({ opacity: 0 });
            if ($('.highlights-alert-message').length) {
                $('.highlights-alert-message').remove();
            }

            $navigation.find('ul > li a[href$="' + window.location.href + '"]').each(function () {
                if ($(this).closest('.js-nav-level-3').is(':visible')) {
                    $(this).attr('aria-describedby', 'currentpage');
                }
            });
        }
    }

    $navigation.find('.js-nav-level-1-item').hoverIntent({
        sensitivity: 3, // number = sensitivity threshold (must be 1 or higher)
        interval: 200, // number = milliseconds for onMouseOver polling interval
        timeout: 300, // number = milliseconds delay before onMouseOut
        over: onHoverIntentOver,
        out: onHoverIntentOut
    });

    if (util.isDesktopSize()) {
        const $hovered = $navigation.find('.js-nav-level-1-item:hover');
        if ($hovered.length) {
            // mimic hoverintent once
            $hovered.addClass(HOVER_CLASS);
            $hovered.one('mouseleave', function () {
                $(this).removeClass(HOVER_CLASS).addClass(OUT_CLASS);
            })
            onDesktopHover($hovered);
        }        
    }
    
    // safety
    setTimeout(cleanFakeNav, 5000);

    // tablet clicks
    if (util.isTablet() || util.isMobile()) {
        $navigation.find('.js-nav-level-1-link').off('click touch').on('click touch', function (e) {
            const $item = $(this).closest('.js-nav-level-item'); // li.js-nav-level-1-item

            if ($(this).hasClass('js-direct-link')) {
                return true;
            } else if (!$item.hasClass(HOVER_CLASS)) {
                e.preventDefault();
            } else {
                return true;
            }

            $item.siblings('.js-nav-level-item.hover').each(function () {
                onHoverIntentOut.call($item);
            });

            onHoverIntentOver.call($item);

            $navigation.find('ul > li a[href$="' + window.location.href + '"]').each(function () {
                if ($(this).closest('.js-nav-level-3').is(':visible')) {
                    $(this).attr('aria-describedby', 'currentpage');
                }
            });

            return false;
        });
    }

    $('.js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
}


async function mobileOpenL2(e) {
    e.preventDefault();

    const $topBanner = $('#top-banner');
    const $navigation = $('#navigation');
    const $button = $(this);
    const $item = $button.closest('.js-nav-level-item'); // li.js-nav-level-1-item

    $item.find('.js-nav-level-2').css({
        top: $navigation[0].scrollTop + 'px'
    });

    // lazy load level 2 flyouts
    // await lazyLoadLevel2Flyouts($item);
    await loadLevel2Flyouts($item);

    $button.attr('aria-expanded', true);

    // hide logo and show flyout title
    $topBanner.addClass(SUB_NAV_ACTIVE_CLASS);
    $('#wrapper').addClass(SUB_NAV_ACTIVE_CLASS);

    // open next level nav panel
    const $nextLevelNavPanel = $item.addClass(ACTIVE_CLASS).children('.js-nav-level-wrapper').first();

    var t1 = gsap.timeline();
    var translateValue = window.SitePreferences.RTL_LOCALE? -200 : 200;
    t1
        .fromTo('.js-flyout-title-wrapper',
            { xPercent: translateValue, autoAlpha: 0},
            { xPercent: 0, autoAlpha: 1, duration: .5 }
        );

    // copy proper title value
    $topBanner.find('.js-flyout-title-wrapper').html($nextLevelNavPanel.find('.js-level-2-inner > .js-menu-wrap > .js-nav-flyout-top .js-nav-flyout-title-content').html() || '');
    // $item.find('.js-nav-level-2').css({'margin-left': 0});
    $nextLevelNavPanel.show('slide', {
        direction: 'right'
    }, 500, function () {
        document.dispatchEvent(new CustomEvent('contentChanged', { detail: { action: 'flyout_in', cont: $item.length && $item[0] } }));
        util.setTopPosition($item.find('.js-nav-level-2'));
        // lock scroll
        $navigation.scrollTop(0).css({ overflow: 'hidden' });
    });

    $item.closest('.js-nav-level-wrapper').find('.js-nav-level-1-item > .js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
    $('.mobile-utility > .js-nav-fade-in-element').removeClass(FADE_IN_CLASS);

    setTimeout(function fadeNav() {
        $item.find('.js-nav-level-2-item > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
        $item.find('.js-nav-level-2 .js-flyout-slot-content.js-nav-fade-in-element').addClass(FADE_IN_CLASS);
    }, 350); // delay for nav to finish slide in
}

async function preloadL2Flyouts($item) {
    if ($item.length) {
        if ($item.is('.js-nav-item-with-flyout')) {
            await loadLevel2Flyouts($item, true);
        }
        window.requestAnimationFrame(function () {
            preloadL2Flyouts($item.next('.js-nav-level-1-item'));
        })
    } else {
        // all done
    }
}

function isAllNavLoaded() {
    return Boolean($('.js-top-banner').data('load-complete-nav'))
}


async function initializeEventsForMobile() {

    const $navigation = $('#navigation');
    const $level1Items = $navigation.find('.js-nav-level-1-item')

    $level1Items.children('.js-mobile-open-sub-menu').off('click touch').on('click touch', mobileOpenL2);
    //await level2.lazyLoadDefaultFlyouts();
    //await level2.loadFlyouts($('#navigation').find('.js-nav-item-default'));
    if (isAllNavLoaded()) {
        level2.showDefaultFlyout();
        return;
    }

    window.requestAnimationFrame(function () {
        preloadL2Flyouts($navigation.find('.js-nav-level-1-item.js-nav-item-with-flyout').first());
    });

}

function initializeEvents() {
    initializeEventsForDesktop();
    initializeEventsForMobile();
}

exports.init = function () {
    initializeEvents();
};
