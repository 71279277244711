'use strict';

var _ = require('lodash');
var cl = document.querySelector("meta[http-equiv='content-language']");
var currentLocale = (cl && cl.getAttribute("content") != '') ? cl.getAttribute("content") : '';
// var US_regex=/^[+-]?[0-9]{1,3}(?:[0-9]*(?:,[0-9]{3})*(?:\.[0-9]{2})?)$/; // 10,123.25
var EU_regex=/^[+-]?[0-9]{1,3}(?:[0-9]*|(?:\.[0-9]{3})*(?:,[0-9]{2})?)$/; // 10.123,25
var browser, device, support;
var max_mobile_breakpoint = 768;
var minDesktopBreakpoint = 960;

var util = {
    /**
     * @function
     * @description check the str whether it is json
     * @param {String} str str
     */
    isJson: function(str) {
        var parsedJSON = null;
        try {
            parsedJSON = JSON.parse(str);
        } catch (e) {
            return false;
        }
        return parsedJSON;
    },
    updateDpidWardrobe: function (json,page,modal) {
        var $parentContainer = $('.rlwardrobecta');
        if (json.success && json.redirecturl) {
            page.redirect(json.redirecturl);
        } else if (json.productExist) {
            $('.dpid-productrlwardrobe__body--text').empty().append(`<p>${json.successMessage}</p>`);
            $parentContainer.find('.icon').addClass('hide');
            $parentContainer.find('.RDqrcode-addtowardrobe').removeClass('RDqrcode-addtowardrobe signIn').addClass('view-now');
            $parentContainer.find('.view-now').attr('href', json.showwardrobeURL);
            $parentContainer.find('.view-now').text(json.buttonText);
            modal.close($('.account-login-register-modal'));
        } else {
            $parentContainer.find('.RDqrcode-addtowardrobe').removeClass('RDqrcode-addtowardrobe').addClass('add-to-wardrobe');
            $parentContainer.find('.add-to-wardrobe').attr('href', json.addtowardrobeURL);
            modal.close($('.account-login-register-modal'));
            if ($('.error-msg').length > 0) {
                $('.error-msg').remove();
                $(`<div class="error-msg"><p>${json.errorMessage}</p></div>`).insertAfter($('.dpid-productrlwardrobe__body--text'));
            }
        }
    },
    /**
     * @function
     * @description This function updates the main page with relevant information in case of a login failure or, upon successful login, redirects the user to the appropriate success page.
     * @param {String} json response received after successfull of ajax call
     * @param {String} page the name of the parameter
     * @param {String} logintype based on this two diffirent code execute
     */
    update_wardrobe_for_old_login_modal: function (json, page, logintype) {
        try {
            var $parentContainer = $('.rlwardrobecta');
            if (logintype === 'signin') {
                if (json.success && json.redirecturl) {
                    page.redirect(json.redirecturl);
                } else if (json.productExist) {
                    $('.dpid-productrlwardrobe__body--text').empty().append(`<p>${json.successMessage}</p>`);
                    $parentContainer.find('.icon').addClass('hide');
                    $parentContainer.find('.RDqrcode-addtowardrobe').removeClass('RDqrcode-addtowardrobe signIn').addClass('view-now');
                    $parentContainer.find('.view-now').attr('href', json.showwardrobeURL);
                    $parentContainer.find('.view-now').text(json.buttonText);
                    $('.save-favorites-dialog button.ui-dialog-titlebar-close').trigger('click');
                } else if ('errorMessage' in json) {
                    if ($('.error-msg').length > 0) {
                        $('.error-msg').remove();
                    }
                    $(`<div class="error-msg"><p>${json.errorMessage}</p></div>`).insertAfter($('.dpid-productrlwardrobe__body--text'));
                    $parentContainer.find('.RDqrcode-addtowardrobe').removeClass('RDqrcode-addtowardrobe signIn').addClass('add-to-wardrobe');
                    $parentContainer.find('.add-to-wardrobe').attr('href', json.addtowardrobeURL);
                    $('.save-favorites-dialog button.ui-dialog-titlebar-close').trigger('click');
                }
            } else if (logintype === 'createaccount') {
                if ('status' in json && json.status === 'accountexist') {
                    $('.login-message').removeClass('hide');
                } else if ('success' in json && json.success && json.redirecturl) {
                    page.redirect(json.redirecturl);
                } else if ('errorMessage' in json) {
                    if ($('.error-msg').length > 0) {
                        $('.error-msg').remove();
                    }
                    $(`<div class="error-msg"><p>${json.errorMessage}</p></div>`).insertAfter($('.dpid-productrlwardrobe__body--text'));
                    $parentContainer.find('.RDqrcode-addtowardrobe').removeClass('RDqrcode-addtowardrobe signIn').addClass('add-to-wardrobe');
                    $parentContainer.find('.add-to-wardrobe').attr('href', json.addtowardrobeURL);
                    $('.save-favorites-dialog button.ui-dialog-titlebar-close').trigger('click');
                }
            }
        } catch (e) {
            console.log(e);
        }
    },
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },
    updateSuburbOptions: function (form) {
        var $form = $(form);
        var postalCodeEl = $form.find('input[id$="_postal"]');
        var countryEl = $form.find('[name$="_country"]');
        var postalCodeVal = postalCodeEl.val();
        if (postalCodeVal && countryEl && countryEl.length && ((postalCodeVal.trim().length == 4 && countryEl.val() == "AU") ||
            (postalCodeVal.trim().length > 4 && postalCodeVal.trim().length < 10 && countryEl.val() == "MY"))) {
            var cityEl = $form.find('select[id$="_city"]');
            var citySaved = $form.find('input[id$="citySaved"]');
            var citySavedVal = '';
            if (citySaved.length && citySaved.val()) {
                citySavedVal = citySaved.val();
                citySaved.val('');
            }
            cityEl.find('option[value!=""]').each(function() {
                $(this).remove();
            });

            $.ajax({
                type: 'GET',
                async: true,
                dataType: 'json',
                contentType: 'application/json',
                url: util.appendParamsToUrl(Urls.getSuburbList, {
                    postcode: postalCodeVal.trim(),
                    country: countryEl.val()
                }),
                success: function(response) {
                   if(response.success) {
                        cityEl.find('option[value!=""]').each(function() {
                            $(this).remove();
                        });
                        response.suburb.forEach(function(item) {
                            cityEl.append($('<option>', {
                                value: item,
                                text: item
                            }));
                        });

                        postalCodeEl.removeClass('notFoundCity');
                        if (countryEl.val() == "MY") {
                            cityEl.addClass('required').removeClass('invalidPostCode');
                        }

                        if (citySavedVal && response.suburb && response.suburb.includes(citySavedVal.toUpperCase())) {
                            cityEl.val(citySavedVal.toUpperCase()).trigger('change').trigger('click');
                        } else if (response.suburb && response.suburb.length === 1) {
                            var firstNonEmptyOption = cityEl.find('option').filter(function() {
                                return $(this).val().toUpperCase() === response.suburb[0].toUpperCase();
                            }).first();
                            if (firstNonEmptyOption.length > 0) {
                                firstNonEmptyOption.prop('selected', true).trigger('change').trigger('click');
                            }
                        } else {
                            // var experianAddress = $form.find('input[id$="experianAddress"]');
                            // if (experianAddress.length && experianAddress.val()) {
                            //     var addressJson = JSON.parse(experianAddress.val());
                            //     if (addressJson.address[6].country == "AUSTRALIA" && addressJson.address[5].postalCode == postalCodeVal.trim() && response.suburb && response.suburb.includes(addressJson.address[3].locality.toUpperCase())) {
                            //         cityEl.val(addressJson.address[3].locality.toUpperCase()).trigger('change').trigger('click')
                            //     } else {
                            //         cityEl.val([]).trigger('change').trigger('click');
                            //     }
                            // } else {
                                cityEl.val('').trigger('change').trigger('click');
                            // }
                        }

                        cityEl.closest('.form-row').find('span.error').remove();
                        cityEl.removeClass('error');

                    } else {
                        if (countryEl.val() == "MY") {
                            cityEl.addClass('invalidPostCode').removeClass('required');
                        }
                        cityEl.val('').trigger('change').trigger('click');
                        postalCodeEl.closest('.input-wrapper').find('.valid-icon').remove();
                        postalCodeEl.addClass('notFoundCity');
                    }
                    $form.validate().element('input[id$="_postal"]');
                }
            });
        } else if (countryEl.val() === 'MY' || countryEl.val() === 'AU') {
            // remove all options and value of suburb
            var $suburb = $form.find('select[id$="_city"]');
            if (countryEl.val() === 'MY') {
                $suburb.addClass('invalidPostCode').removeClass('required');
            }
            $suburb.val('');
            $suburb.find('option[value!=""]').each(function() {
                $(this).remove();
            });
            if (postalCodeVal.length) {
                $suburb.trigger('change').trigger('click');
            }
        }
    },
    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} countrySelect The selected country
     */
    updateStateOptions: function (form) {
        var $form = $(form);
        var $country = $form.find('select[id$="_country"]');
        var $frmState = $form.find('select[id$="_state"]');
        var stVal = $frmState.val();
        var country = $country.val();
        var countryObj = window.Countries[country];
        var arrHtml = '';
        var arrlist = '';
        var selectID = '';
        var autocomplete = '';
        var selectName = '';
        var inputField;
        var selectBox;
        var cityField;
        var cityID;
        var citySelectName;
        var $frmCity = $form.find('select[id$="_city"]');
        var cityVal = $frmCity.val();

        $form.find('[id$="_state"]').closest('.form-row').find('span.error').remove();
        $form.find('[id$="_state"]').removeClass('error');
        $form.find('[id$="_city"]').closest('.form-row').find('span.error').remove();
        $form.find('[id$="_city"]').removeClass('error');

        // if statement execute only for the countries which has states as dropdown( ex : US, CA)
        if (countryObj != undefined && countryObj != null && countryObj.regions != undefined && countryObj.regions != null && Object.keys(countryObj.regions).length > 0) {
            selectID = 'id="' + $form.find('[name$="_state"]').attr('id') + '"';
            selectName = 'name="' + $form.find('[name$="_state"]').attr('name') + '"';
            autocomplete = 'autocomplete="address-level1"';
            cityID = 'id="' + $form.find('[name$="_city"]').attr('id') + '"';
            citySelectName = 'name="' + $form.find('[name$="_city"]').attr('name') + '"';
            if ($form.hasClass("eform-tw")) {
                inputField = $('<input class="input-text state" placeholder="狀態" type="text" ' + selectID + ' ' + selectName + ' ' + autocomplete + ' />');
            } else {
                inputField = $('<input class="input-text state" placeholder="State" type="text" ' + selectID + ' ' + selectName + ' ' + autocomplete + ' />');
                cityField = $('<input class="input-text city latincheck specialcharcheck countryValid emptycheck required empty" placeholder="City" type="text" ' + cityID + ' ' + citySelectName + ' ' + autocomplete + ' />');
            }

            if ($form.hasClass("eform-kr")) {
                arrHtml = '<option value="">주를 선택</option>';
            } else {
                arrHtml = '<option value="">'+ Resources.STATE_LABEL +'</option>';
            }

            for (var stateValue in countryObj.regions) {
                arrHtml += '<option value="' + stateValue + '">' + countryObj.regions[stateValue] + '</option>';
                arrlist += '<li>' + countryObj.regions[stateValue] + '</li>';
            }

            selectBox = $('<select class="input-select required" ' + selectID + ' ' + selectName + ' ' + autocomplete + '>' + arrHtml + '</select>');

            if (country !== 'US' && country != 'CA' && country != 'AU' && country != 'KR' && country != 'TW' && country != 'MY') {
                $form.find('select[name$="_state"]').replaceWith($(inputField));
                if (country == 'SG') {
                    $form.find('input[name$="_state"]').addClass('required').attr('placeholder', "County/State/Region *").closest('.form-row').addClass('required').find('label').first().html('<span class="required-indicator"> * </span> County/State/Region');
                } else {
                    $form.find('input[name$="_state"]').closest('.form-row').find('label').first().html('State/Province');
                    $form.find('input[name$="_state"]').removeClass('required').attr('placeholder', "County/State/Region").closest('.form-row').removeClass('required').find('label .required-indicator').remove();
                }

                if ($form.find('input[id$="citySaved"]').length) {
                    $form.find('select[name$="_city"]').replaceWith($(cityField));
                    $form.find('input[name$="_city"]').closest('.form-row').addClass('required').find('label').first().html('<span class="required-indicator"> * </span> City');
                }

            } else {
                if (country !== 'TW') {
                    $form.find('input[name$="_state"]').replaceWith($(selectBox));
                    $form.find('select[name$="_state"]').replaceWith($(selectBox));
                    if ($form.hasClass("eform-kr")) {
                        $form.find('select[name$="_state"]').closest('.form-row').addClass('required').find('label').first().html('국가');
                    } else {
                        $form.find('select[name$="_state"]').closest('.form-row').addClass('required').find('label').first().html('State');
                        if ($form.find('input[id$="citySaved"]').length) {
                            if (country === 'AU') {
                                var citySelectBox;
                                var cityArrHtml;
                                cityArrHtml = '<option value="">'+ Resources.SUBURB_LABEL +'</option>';
                                citySelectBox = $('<select class="input-select required" ' + cityID + ' ' + citySelectName + ' ' + autocomplete + '>' + cityArrHtml + '</select>');
                                $form.find('input[name$="_city"]').replaceWith($(citySelectBox));
                                $form.find('select[name$="_city"]').replaceWith($(citySelectBox));
                                $form.find('select[name$="_city"]').closest('.form-row').addClass('required').find('label').first().html('Suburb');
                                util.updateSuburbOptions(form);
                            } else if (country === 'MY') {
                                var cityArrHtml = '<option value="">'+ Resources.SELECT_CITY +'</option>';
                                var citySelectBox = $('<select class="input-select required" ' + cityID + ' ' + citySelectName + ' ' + autocomplete + '>' + cityArrHtml + '</select>');
                                $form.find('[name$="_city"]').replaceWith($(citySelectBox));
                                $form.find('select[name$="_city"]').addClass('city').closest('.form-row').addClass('required').find('label').first().html(Resources.LABEL_CITY);
                                util.updateSuburbOptions(form);
                            } else {
                                $form.find('select[name$="_city"]').replaceWith($(cityField));
                                $form.find('input[name$="_city"]').closest('.form-row').addClass('required').find('label').first().html('<span class="required-indicator"> * </span> City');
                            }
                        }
                    }

                } else {
                    var eformTemplate = $('.eform-template');
                    if (eformTemplate.length) {
                        $form.find('select[name$="_state"]').replaceWith($(inputField));
                        $form.find('input[name$="_state"]').closest('.form-row').find('label').first().html('State/Province');
                        $form.find('input[name$="_state"]').closest('.form-row').removeClass('required').find('label .required-indicator').remove();
                    } else {
                        $form.find('select[name$="_state"]').closest('.form-row').addClass('required').find('label').first().html('<span class="required-indicator"> * </span> ' + window.Resources.SELECT_DISTRICT);
                    }
                }

                if (!$form.find('select[name$="_state"]').closest('.form-row').find('label').find('span').hasClass('required-indicator')) {
                    $form.find('select[name$="_state"]').closest('.form-row').find('label').prepend('<span class="required-indicator"> * </span>');
                }

                if (!$form.find('select[name$="_city"]').closest('.form-row').find('label').find('span').hasClass('required-indicator')) {
                    $form.find('select[name$="_city"]').closest('.form-row').find('label').prepend('<span class="required-indicator"> * </span>');
                }

                $form.find('select[name$="_postal"]').closest('.form-row').find('label').first().html('Zip Code');
                if (stVal && stVal != undefined && stVal != null) {
                    $form.find('select[name$="_state"] option[value="' + stVal + '"]').prop('selected', true);
                }
                if (cityVal && cityVal != undefined && cityVal != null) {
                    $form.find('select[name$="_state"] option[value="' + cityVal + '"]').prop('selected', true);
                } 
            }

            var sesnstate = $('input[name$="stateSessionVal"]').val();
            if (sesnstate && sesnstate != undefined && sesnstate != 'null' && (stVal == undefined || stVal == 'null') && country && (country == 'US' || country == 'CA' || country == 'AU' || country == 'SG' || country == 'MY')) {
                $form.find('select[id$="_state"]').val(sesnstate);
            }

            var sesncity = $('input[name$="citySessionVal"]').val();
            if (sesncity && sesncity != undefined && sesncity != 'null' && (stVal == undefined || stVal == 'null') && country && (country == 'AU' || country == 'MY')) {
                $form.find('select[id$="_city"]').val(sesncity);
            }

            $('select').each(function() {
                if ($(this).find('option:selected').val() == null || $(this).find('option:selected').val() == '') {
                    $(this).removeClass('valid');
                } else {
                    $(this).addClass('valid');
                }
            });

            $('select').on('change', function() {
                if ($(this).find('option:selected').val() == null || $(this).find('option:selected').val() == '') {
                    $(this).removeClass('valid');
                } else {
                    $(this).addClass('valid');
                }
            });
        }
    },
    getCreditCardType: function(accountNumber) {
        //start without knowing the credit card type
        var result = 'unknown';
        //first check for MasterCard
        if (/^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]/.test(accountNumber)) {
            result = 'masterCard';
        } else if (/^(5018|5020|5038|5612|5893|5811|6304|6759|6761|6763|67|5000|6771|6333|6799|6762|0604|6790)[0-9]{8,19}$/.test(accountNumber)) {
            result = 'maestro';
        } else if (/^4/.test(accountNumber)) {
            result = 'visa';
        } else if (/^3[47]/.test(accountNumber)) {
            result = 'americanExpress';
        } else if (/^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65|64)/.test(accountNumber)) {
        	result = 'discover';
        } else if (/^(?:2131|1800)\d{11,14}|352[8-9]\d{12,15}|35[3-7]\d{13,16}|35[8]\d{13,16}|3337\d{12,15}$/.test(accountNumber)) {
            result = 'jcb';
        } else if (/^30[0-5]\d{13,16}|3095\d{12,15}$/.test(accountNumber)) {
            result = 'diners';
        }
        return result;
    },
    appendcreditcardType: function(type, cardType) {
        switch (type) {
            case 'masterCard':
            case 'Master':
                cardType.val('Master').trigger('change');
                if (SitePreferences.CYBS_ENABLED) {
                    cardType.val('MasterCard').trigger('change');
                }
                break;
            case 'visa':
            case 'Visa':
                cardType.val('Visa').trigger('change');
                break;
            case 'americanExpress':
            case 'Amex':
                cardType.val('Amex').trigger('change');
                break;
            case 'discover':
            case 'Discover':
                cardType.val('Discover').trigger('change');
                break;
            case 'jcb':
            case 'JCB':
                cardType.val('JCB').trigger('change');
                break;
            case 'maestro':
            case 'Maestro':
                cardType.val('Maestro').trigger('change');
                break;
            default:
                break;
        }
    },
    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        _.each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!_.isString(url)) {
            return;
        }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description extract the query variable from URL
     * @param {String} pair[1] the url to extra query variable from
     **/
    getQueryVariable: function(url, name) {
        var query = url.substring(1);
        var vars = query.split('&');

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');

            if (decodeURIComponent(pair[0]) == name) {
                return decodeURIComponent(pair[1]);
            }
        }

        return undefined;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof (offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },
    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },
    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },
    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],
    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) {
            return {};
        }
        var params = {},
            unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },
    getQueryStringParamsFromUrl: function(url) {
        return Object.fromEntries(new URL(url).searchParams);
    },
    fillAddressFields: function (address, $form) {
        var creditCardType = $('[id$="_creditCard_type"]').val();
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
        $('[id$="_creditCard_type"]').val(creditCardType).trigger('change');
    },
    personalizeCheck: function(){
    	var $currObj = $('.personalize-check .input-checkbox');
    	$currObj.prop( "checked", false );
    	
    	$('.personalize-initials input').on('focus',function() {
    		$(this).closest('.product-options').siblings('.product-add-to-cart').find('#add-to-cart').removeClass('font-check-disabled');
    		$(this).closest('.product-options').siblings('.product-add-to-cart').find('#add-to-cart').prop('disabled', false);
    	});
    	if(window.location.href.indexOf('personalizeIt') > -1){
    		$currObj.prop( "checked", true ).trigger('change');
    		var mobileScroll = $('.product-col-1.product-image-container').height() + $(".product-top-content .personalize-checkbox").position().top - $('.top-banner-sticky').height();
    		var desktopScroll = $(".product-top-content .personalize-checkbox").position().top;
    		var scrollPositionTop = $(window).width() < 960 ? mobileScroll : desktopScroll;
    		$(window).on('load', function(){
        		$('.personalize-close').addClass('personalize-open');
    			$('html, body').animate({
        	        'scrollTop' : scrollPositionTop + 20
        	    },500);
    		});
    	}
    	$currObj.on('change',function() {
    		var $checkedValue = $(this).closest('.product-options');
    	    if($(this).is(":checked")) {
    	    	$checkedValue.find('.personalize-close').addClass('personalize-open');
    	    	$checkedValue.find('.personalize-value .personalize-Yes').prop( "checked", true );
    	    	$checkedValue.find('.personalize-value .personalize-No').prop( "checked", false );
    	    	$(".personalize-close").find(".personalize-char ul.value li:first-child").trigger("click");
    	    	if ($checkedValue.find('.attribute.sheeting-size-variant').length > 0 || $checkedValue.find('.attribute.towel-size-variant').length > 0) {
	    	    	var sizeCheckboxLen = $checkedValue.find('.personalize-size-variants .size-checkbox').length,
		    	    	check = 0;
	    	    	$checkedValue.find('.personalize-size-variants .size-checkbox').each(function(){
		    	    	if($(this).hasClass("hide")) {
		    	    		check++;
		    	        }
		    	    });
		    	    if(sizeCheckboxLen != check) {
		    	    	$checkedValue.find(".personalize-size-variants").removeClass("hide");
		    	    	$checkedValue.find(".all-qty-zero-error-msg").addClass("hide");
		    	    } else {
		    	    	$checkedValue.find(".personalize-size-variants").addClass("hide");
		    	    	$checkedValue.find(".all-qty-zero-error-msg").removeClass("hide");
		    	    }
    	    	}
    	    } else {
    	    	var $closingpersonal = $checkedValue.siblings('.product-add-to-cart').find('#add-to-cart');
    	    	$checkedValue.find('.personalize-close').removeClass('personalize-open');
    	    	$checkedValue.find('.personalize-value .personalize-No').prop( "checked", true );
    	    	$checkedValue.find('.personalize-value .personalize-Yes').prop( "checked", false );
    	    	if(!$closingpersonal.hasClass('disabled-size')){
    	    		$closingpersonal.removeClass('font-check-disabled');
    	    		$closingpersonal.prop('disabled', false);
	    	    	$checkedValue.siblings('.product-add-to-cart').find('.pdp-messaging .pdp-error-msg').text('');
    	    	}
    	    	$checkedValue.find('.personalize-variations .size-checkbox').each(function(i,e){
	    	    	if($('.personalize-product',this).is(':checked')){
	    	    		$(".personalize-product",this).trigger("click");
	    	    	}
    	    	});
    	    	$('.monogramingAmount').hide();
    	    	$('.product-add-to-cart').find('#add-all-to-cart').prop('disabled', false);
    	    	$('.product-add-to-cart').find('.monogramCls .pdp-error-msg').text('');
    	    }
    	    var selectioncount, selectioncolor;
    	    if($( window ).width() >= 480){
    	    	selectioncount = 5;
    	    	selectioncolor = 9;
    	    }
    	    else{
    	    	selectioncount = 3;
    	    	selectioncolor = 5;
    	    }
    	    
    	    var $personalizeli = $(this).closest('.product-options').find('.personalize-close .personalize-color li');
    	    var $personalizelichar = $(this).closest('.product-options').find('.personalize-close .personalize-char li');
    	    if( $personalizeli.length > selectioncolor){
    	    	$personalizeli.closest('.jcarousel-wrapper').removeClass('no-slide');
    	    	$personalizeli.closest('.jcarousel-content')
	            // responsive slides
	            .on('jcarousel:create jcarousel:reload', function () {
	                
	            })
	            .jcarousel({
	                
	            });
    	    	$personalizeli.closest('.jcarousel-wrapper').find('.jcarousel-control-prev-color')
				.on('jcarouselcontrol:active', function() {
					$(this).removeClass('inactive');
				})
				.on('jcarouselcontrol:inactive', function() {
					$(this).addClass('inactive');
				})
				.jcarouselControl({
					target: '-=1'
				});
    	    	$personalizeli.closest('.jcarousel-wrapper').find('.jcarousel-control-next-color')
				.on('jcarouselcontrol:active', function() {
					$(this).removeClass('inactive');
				})
				.on('jcarouselcontrol:inactive', function() {
					$(this).addClass('inactive');
				})
				.jcarouselControl({
					target: '+=1'
				});
    	    } else {
    	    	$personalizeli.closest('.jcarousel-wrapper').addClass('no-slide');
    	    }
    	    if($personalizelichar.length > selectioncount) {
    	    	$personalizelichar.closest('.jcarousel-wrapper').removeClass('no-slide');
    	    	$personalizelichar.closest('.jcarousel-content')
	            // responsive slides
	            .on('jcarousel:create jcarousel:reload', function () {
	                
	            })
	            .jcarousel({
	                
	            });
    	    	$personalizelichar.closest('.jcarousel-wrapper').find('.jcarousel-control-prev')
				.on('jcarouselcontrol:active', function() {
					$(this).removeClass('inactive');
				})
				.on('jcarouselcontrol:inactive', function() {
					$(this).addClass('inactive');
				})
				.jcarouselControl({
					target: '-=1'
				});

    	    	$personalizelichar.closest('.jcarousel-wrapper').find('.jcarousel-control-next')
				.on('jcarouselcontrol:active', function() {
					$(this).removeClass('inactive');
				})
				.on('jcarouselcontrol:inactive', function() {
					$(this).addClass('inactive');
				})
				.jcarouselControl({
					target: '+=1'
				});
    	    }
    	    else {
    	    	$personalizelichar.closest('.jcarousel-wrapper').addClass('no-slide');
    	    }
    	});

    	$('.personalize-close .value li:first-child').addClass('selected-val').addClass("selected");
    	$('.personalize-close .value li:first-child input[type=radio]').prop( "checked", true );
    	$(".personalize-close").find(".personalize-color .personalize-selected-clr").html($('input[type=radio]',$('.personalize-close .value li:first-child')).data("embroiderycolor"));

    	$(document).on("click",'.product-options .option-dropdown .value li',function() {
    		var $selectionli = $(this).closest('.product-options').siblings('.product-add-to-cart').find('#add-to-cart');
    		var $selectedColor = $('input[type=radio]',$(this)).data("embroiderycolor");
    		if($(this).find('input[type=radio]').is(":checked")) {
    			$(this).addClass('selected-val').addClass("selected");
    			$(this).siblings().removeClass('selected-val').removeClass('selected');
    			$(".personalize-close").find(".personalize-color .personalize-selected-clr").html($selectedColor);
    		}
    		if(!$selectionli.hasClass('disabled-size') && $(this).closest('.option-dropdown').hasClass('personalize-char')){
    			$selectionli.removeClass('font-check-disabled');
        		$selectionli.prop('disabled', false);
    			$selectionli.closest('.product-add-to-cart').find('.pdp-messaging .pdp-error-msg').text('');
    		}
    	});
    	$(".product-options .personalize-close ul.value li").on('hover',
  			  function () {
  			    $(this).addClass("selected-val");
  			  },
  			  function () {
  				  if(!$(this).hasClass("selected")){
  					  $(this).removeClass("selected-val");
  				  }
  			  }
  		);
    	$('.personalize-initials input.input-text').on('input', function (event) {
    		var thsInput = $(this);
    		var regE = thsInput.val().replace(/[^a-zA-Z]/g, '');
    		thsInput.val(regE);
    		var thsval = thsInput.val();
    		var selectedFont = $(".personalize-close").find(".personalize-char ul.value li.selected-val").attr("date-casetype");
    		var inputLength = parseInt($(".personalize-close").find(".personalize-char ul.value li.selected-val").attr("data-limitval"));
    	    if(selectedFont=="lower") {
    	    	thsInput.val(thsval.toLowerCase());
    	    } else if(selectedFont=="upper") {
    	    	thsInput.val(thsval.toUpperCase());
    	    }
    	    if (thsval.length > inputLength) {
    	        this.value = thsval.slice(0,inputLength);
    	    }
    	});
    	$(".personalize-close").find(".personalize-char ul.value li").on("click",function(){
			var parentDIV = $(".personalize-open");
			var placeholder = "";
			var setIntialLen = parseInt($(this).data("limitval"));
            var setMinLen = $(this).data("minlimitvalu") ? parseInt($(this).data("minlimitvalu")) : setIntialLen;
			var selLetterType = $(this).attr("date-casetype");
			var selCharVal = $(this).attr("date-charval").toUpperCase();
			var InitilDefault = Resources.MONOGRAM_INITIAL_PLACEHOLDER;
			var intialInput = $(".personalize-initials input.input-text",parentDIV);
            var initialLabel = $(".personalize-initials label",parentDIV);
			var letterCase = "";
            var maxLen = 3;
            if($(".personalize-form-caption").length == 0){
                $('<label class="personalize-form-caption"></label>').insertAfter(intialInput);
            }
            var formCaption = intialInput.next();
			if (selCharVal.toLowerCase() == 'text') {
				InitilDefault = Resources.MONOGRAM_ENTER_TEXT;
			}
			intialInput.attr("placeholder", InitilDefault);
            initialLabel.html(InitilDefault);
            formCaption.html(InitilDefault);
			$(".your-initials-block .enter-upto-3l .letter-length",parentDIV).text(Resources['MONOGRAM_INITIAL_' + selCharVal]);
			intialInput.val("");
			
			if(setIntialLen == 1) {
				placeholder = selLetterType == "upper" ? selCharVal.toUpperCase() : (selLetterType == "lower" ? selCharVal.toLowerCase() : InitilDefault);
				maxLen = 1;
			} else if(setIntialLen == 2){
				placeholder = selLetterType == "upper" ? selCharVal.toUpperCase() : (selLetterType == "lower" ? selCharVal.toLowerCase() : InitilDefault);
				maxLen = 2;
			} else if(setIntialLen == 3){
				placeholder = selLetterType == "upper" ? selCharVal.toUpperCase() : (selLetterType == "lower" ? selCharVal.toLowerCase() : InitilDefault);
				maxLen = 3;
			} else {
				placeholder = InitilDefault;
				maxLen = setIntialLen;
			}
			var letterCase = selLetterType == "upper" ? "uppercase" : (selLetterType == "lower" ? "lowercase" : "initial");
			intialInput.attr("maxlength",maxLen).attr("minlength",setMinLen).css("text-transform",letterCase);
	    });
    },
    collectionPDPthumbnailSlider: function() {
    	var mobileSwiperProductSet = new Swiper('.s7viewer-productset', {
        	direction: 'horizontal',
        	pagination: {
                el: '.s7viewer-productset .swiper-pagination',
                type: 'bullets',
            },
            centeredSlides: true,
            allowTouchMove: false,
            spaceBetween: 10,
    	    breakpoints: {
    	    	992: {
    		    	allowTouchMove: true,
    	    	},
    	    }
        });
        var thumbnailSwiperInstances = {};
        $('.alt-image-slider-wrapper').each(function(index, element) {
            var $this = $(".product-thumbnails",$(this));
            $this.addClass('slider-target-instance-' + index);
            $this.siblings('.horizontal-carousel-control-prev').addClass('thumbnail-btn-prev-' + index);
            $this.siblings('.horizontal-carousel-control-next').addClass('thumbnail-btn-next-' + index);
            thumbnailSwiperInstances[index] = new Swiper('.slider-target-instance-' + index, {
                slidesPerView: 6,
                spaceBetween: 9,
                loop: false,
                watchOverflow : true,
                navigation: {
                    nextEl: '.thumbnail-btn-next-' + index,
                    prevEl: '.thumbnail-btn-prev-' + index,
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 3
                    }
                }
            });
        });
    },
    collectionPDPSlider: function() {
        var collectionSwiperInstances = {};
        $('.productset-image-slider-wrapper').each(function(index, element) {
            var $this = $(".product-thumbnails",$(this));
            $this.addClass('slider-target-instance-' + index);
            $this.closest('.product-primary-image').siblings('.setswiper-pagination').addClass('setswiper-pagination-' + index);
            $this.siblings('.horizontal-carousel-control-prev').addClass('thumbnail-btn-prev-' + index);
            $this.siblings('.horizontal-carousel-control-next').addClass('thumbnail-btn-next-' + index);
            collectionSwiperInstances[index] = new Swiper('.slider-target-instance-' + index, {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                watchOverflow : true,
                navigation: {
                    nextEl: '.thumbnail-btn-next-' + index,
                    prevEl: '.thumbnail-btn-prev-' + index,
                },
                pagination: {
                    el: '.setswiper-pagination-' + index,
                    type: 'bullets',
                    clickable: true,
                    renderBullet: function(index, className) {
                        var total = this.slides.length - this.loopedSlides * 2;
                        var customClass = 'pagination-reg';
                        return '<div class="swiper-pagination-bar ' + customClass + ' ' + className + '"></div>';
                    },
                },
                centeredSlides: true,
                allowTouchMove: true,
            });
        });
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).trigger('change');
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on('click', '.delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation) {
        $('html, body').animate({
            scrollTop: xLocation
        }, 500);
    },
    phoneValidation: function() {
    },
    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile  || ('ontouchstart' in window);
    },
    isTablet: function() {
        var isTablet = false;
        var userAgent = navigator.userAgent;
        var platform = navigator.platform;

        if(/tablet|Tablet|iPad/.test(userAgent) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1) || platform == 'iPad' || (/Android/.test(userAgent) && userAgent.indexOf('Mobile') === -1)) {
            isTablet = true;
        }

        return isTablet;
    },
    isMobileSize: function() {
        if (window.innerWidth <= max_mobile_breakpoint - 1) {
            return true;
        } else {
            return false;
        }
    },
    isDesktopSize: function () {
        return window.innerWidth >= minDesktopBreakpoint;
    },
    /**
     * Get the value of a querystring
     * @param  {String} field The field to get the value of
     * @param  {String} url   The URL to get the value from (optional)
     * @return {String}       The field value
     */
    getQueryStringValue: function (field, url) {
        var href = url ? url : window.location.href;
        var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
        var string = reg.exec(href);

        return string ? string[1] : null;
    },
    expand: function ($el) {
        return $el.attr('aria-hidden', false).attr('aria-expanded', true);
    },
    contract: function ($el) {
        return $el.attr('aria-hidden', true).attr('aria-expanded', false);
    },
    formatPrice: function(val,addFraction){
    	if( currentLocale != '' && Intl && Intl.NumberFormat){
    		return new Intl.NumberFormat(currentLocale, {minimumFractionDigits: addFraction ? 2 : 0,  maximumFractionDigits: addFraction ? 2 : 0, style: 'currency', currency: Resources.CURRENCY_CODE }).format(val);
    	} else {
    		return Resources.CURRENCY_SYMBOL == '€' ? val + Resources.CURRENCY_SYMBOL : Resources.CURRENCY_SYMBOL + val;
    	}
	},
	intlPriceToCleanUS:function(s) {
		s = unescape(s);
		if (EU_regex.test(s)){
			s = s.replace('.','').replace(',','.');
		} else {
			s = s.replace(',','');
		}
		return s;
    },
	setCookie: function(cname,cvalue,exdays) {
		  var d = new Date();
		  d.setTime(d.getTime() + (exdays*24*60*60*1000));
		  var expires = "expires=" + d.toGMTString();
		  document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";domain=."+window.location.hostname+";path=/";
	},
	getCookie: function(cname) {
		  var name = cname + "=";
		  var decodedCookie = decodeURIComponent(document.cookie);
		  var ca = decodedCookie.split(';');
		  for(var i = 0; i < ca.length; i++) {
		    var c = ca[i];
		    while (c.charAt(0) == ' ') {
		      c = c.substring(1);
		    }
		    if (c.indexOf(name) == 0) {
		      return c.substring(name.length, c.length);
		    }
		  }
		  return "";
	},
    /**
     * Execute callback function when the user has stopped resizing the screen.
     * @param callback {Function} The callback function to execute.
     */
    smartResize: function (callback) {
        var timeout;

        window.addEventListener('resize', function () {
            clearTimeout(timeout);
            timeout = setTimeout(callback, 100);
        });

        return callback;
    },
    cleanupID: function(s) {
        return (typeof s === 'string') ? s.replace(new RegExp('[^a-z0-9_\-]', 'gi'), '_').toLowerCase() : s;
    },
    padNumber: function(val,size){
        var v = parseInt(val).toString();
        return (v.length > size) ? v : Array(size - v.length + 1).join("0") + v;
    },
    getBrowser: function () {
        function calcBrowser() {
            var ua = window.navigator.userAgent.toLowerCase();
            function isSafari() {
                return ua.indexOf('safari') >= 0 && ua.indexOf('chrome') < 0 && ua.indexOf('android') < 0;
            }
            return {
                isEdge: !!window.navigator.userAgent.match(/Edge/g),
                isSafari: isSafari(),
                isWebView: /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(window.navigator.userAgent),
            };
        }
        if (!browser) {
            browser = calcBrowser();
        }
        return browser;
    },
    getDevice: function (overrides) {
        function calcDevice(userAgent) {
            var support = util.getSupport();
            var platform = window.navigator.platform;
            var ua = userAgent || window.navigator.userAgent;

            var device = {
                ios: false,
                android: false,
            };

            var screenWidth = window.screen.width;
            var screenHeight = window.screen.height;

            var android = ua.match(/(Android);?[\s\/]+([\d.]+)?/); // eslint-disable-line
            var ipad = ua.match(/(iPad).*OS\s([\d_]+)/);

            var ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/);
            var iphone = !ipad && ua.match(/(iPhone\sOS|iOS)\s([\d_]+)/);
            var windows = platform === 'Win32';
            var macos = platform === 'MacIntel';

            // iPadOs 13 fix
            const iPadScreens = [
                '1024x1366',
                '1366x1024',
                '834x1194',
                '1194x834',
                '834x1112',
                '1112x834',
                '768x1024',
                '1024x768',
            ];
            if (
                !ipad &&
                macos &&
                support.touch &&
                iPadScreens.indexOf(screenWidth + "x" + screenHeight) >= 0
            ) {
                ipad = ua.match(/(Version)\/([\d.]+)/);
                if (!ipad) ipad = [0, 1, '13_0_0'];
                macos = false;
            }
            // ipadOs another trick!!
            if(
                !ipad &&
                macos &&
                support.touch
            ){
                if(ua.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints && navigator.maxTouchPoints > 2){
                    ipad = ua.match(/(Version)\/([\d.]+)/);
                    macos = false;
                }
            }

            // Android
            if (android && !windows) {
                device.os = 'android';
                device.android = true;
            }
            if (ipad || iphone || ipod) {
                device.os = 'ios';
                device.ios = true;
            }

            // Export object
            return device;
        }
        if (!device) {
            device = calcDevice(overrides);
        }
        return device;
    },
    getSupport: function () {
        function calcSupport() {

            return {
                touch: !!(
                    'ontouchstart' in window ||
                    (window.DocumentTouch && document instanceof window.DocumentTouch)
                ),

                pointerEvents:
                    !!window.PointerEvent &&
                    'maxTouchPoints' in window.navigator &&
                    window.navigator.maxTouchPoints >= 0,

                observer: (function checkObserver() {
                    return 'MutationObserver' in window || 'WebkitMutationObserver' in window;
                })(),

                passiveListener: (function checkPassiveListener() {
                    var supportsPassive = false;
                    try {
                        var opts = Object.defineProperty({}, 'passive', {
                            // eslint-disable-next-line
                            get: function get() {
                                supportsPassive = true;
                            }
                        });
                        window.addEventListener('testPassiveListener', null, opts);
                    } catch (e) {
                        // No support
                    }
                    return supportsPassive;
                })(),
                gestures: (function checkGestures() {
                    return 'ongesturestart' in window;
                })(),
            };
        }
        if (!support) {
            support = calcSupport();
        }
        return support;
    },
    /**
     * @function
     * @description Forces the keyboard to open on mobile
     * @param {Element} searchInputField The element to which the function will be bind
     * @param {Number} timeout The amount of time to set for the timeout
     */
    focusAndOpenKeyboard: function(searchInputField, timeout) {
        if (searchInputField) {
            if (!timeout) {
                timeout = 100;
            }

            // Align temp input element approximately where the input element is
            // so the cursor doesn't jump around
            var __tempEl__ = document.createElement('input');
            __tempEl__.style.position = 'absolute';
            __tempEl__.style.top = searchInputField.offsetTop + 'px';
            __tempEl__.style.left = searchInputField.offsetLeft + 'px';
            __tempEl__.style.height = 0;
            __tempEl__.style.opacity = 0;
            // Put this temp element as a child of the page <body> and focus on it
            document.body.appendChild(__tempEl__);
            __tempEl__.focus();

            // The keyboard is open. Now do a delayed focus on the target element
            setTimeout(function() {
                searchInputField.focus();
                searchInputField.click();
                // Remove the temp element
                document.body.removeChild(__tempEl__);
            }, timeout);
        }
    },
    /**
     * @function
     * @description remove all attributes which are undefined, null or '' (empty string) in an object
     * @param {Object} obj The object to clean
     * @return {Object}    The updated object
     */
    cleanObj: function(obj) {
        try {
            for (var propName in obj) {
                if (obj[propName] === null || obj[propName] === 'null' || obj[propName] === undefined || obj[propName] === '') {
                    delete obj[propName];
                }
            }

            return obj;
        } catch(error) {
            // error happened. return original object
            return obj;
        }
    },
    /**
     * @function
     * @description check if the value is undefined, null or '' (empty string)
     * @param {string} val the element value
     * @return {boolean} returns true if empty
     */
    isInvalidValue: function(val) {
        return val === undefined || val === 'undefined' || val === null || val === 'null' || val === '';
    },
    formatMoney: function(number, decPlaces, decSep, thouSep) {
        decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
        decSep = typeof decSep === "undefined" ? "." : decSep;
        thouSep = typeof thouSep === "undefined" ? "," : thouSep;
        var sign = number < 0 ? "-" : "";
        var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
        var j = (j = i.length) > 3 ? j % 3 : 0;
    
        return sign +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    },
    /**
     * @function
     * @description prevent double click on elements
     * @param {Object} fn function
     */
    preventDoubleClick(fn) {
        const threshold = 300;
        let lastClick = 0;
        return function (e) {
            const temp = lastClick;
            lastClick = e.timeStamp;
            if ((e.timeStamp - temp) > threshold) {
                fn.call(this, e);
            }
            return false;
        };
    },
};

module.exports = util;