'use strict';

/**
 * Callback with promise.
 *
 * @callback cachefetchCallback
 * @param {Promise} promise - fetch response promise
 */

/**
* Retrieves fetch response by URL either from cache or server
 *
 * @param {string} url - URL for fetch
 * @param {boolean} swEnabled - Flag that indicated if Service Worker for Navigation is enabled
 * @param {string|undefined} cuid - Customer Unique ID
 * @param {cachefetchCallback} callback - A callback to run
 */
function cachefetch(url, swEnabled = false, cuid, postData, callback = () => { }) {
    // eslint-disable-next-line no-undef
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller && swEnabled) {
        // --service worker is registered--
        const messageChannel = new MessageChannel();

        // send message to service worker
        navigator.serviceWorker.controller.postMessage({
            message: {
                action: 'navCachefetch',
                url: url,
                cid: cuid || '',
                postData
            }
        }, [messageChannel.port2]);

        // listen answer from service worker
        messageChannel.port1.onmessage = function (event) {
            let promise = new Promise((resolve, reject) => {
                switch (event.data.message.action) {
                    case 'success':
                        resolve(event.data.message.body);
                        break;
                    default:
                        reject(new Error('Message is not supported'));
                        break;
                }
            });

            callback(promise);
        };
    } else {
        // --service worker not registered. nav link from server--
        let promise;
        if (postData) {
            var options = {
                method: 'POST',
                body: JSON.stringify(postData),

            };
            promise = fetch(url, options)
                .then((response) => response.text())
                .then((html) => html);
        } else {
            promise = fetch(url).then((response) => {
                if (response.ok) {
                    return response.text();
                }
                throw new Error('Fetch error');
            });
        }
        callback(promise);
    }
}

exports.cachefetch = cachefetch;
