'use strict';

var eventNameVal = new Map();

var util = {

    analyticsEnabled: SitePreferences.ANALYTICS_ENABLED && typeof digitalData !== 'undefined',
    qsAnalyticsCat: '',

    coreCall: function() {
        const args = [...arguments];
        require.ensure([], function (require) {
            var module = require('./aepdatacollection');
            const fn = args.shift() || 'init';
            if (module[fn]) {
                module[fn].apply(null, args);
            }
        }, function(error) {
            console.log('error!', error);
        }, 'aepdatacollection');
    },

    searchCall: function() {
        const args = [...arguments];
        require.ensure([], function (require) {
            var module = require('./aepdatacollection-search');
            const fn = args.shift() || 'init';
            if (module[fn]) {
                module[fn].apply(null, args);
            }
        }, function(error) {
            console.log('error!', error);
        }, 'aepdatacollection-search');
    },

    productCall: function() {
        const args = [...arguments];
        require.ensure([], function (require) {
            var module = require('./aepdatacollection-product');
            const fn = args.shift() || 'init';
            if (module[fn]) {
                module[fn].apply(null, args);
            }
        }, function(error) {
            console.log('error!', error);
        }, 'aepdatacollection-product');
    },

    checkoutCall: function() {
        const args = [...arguments];
        require.ensure([], function (require) {
            var module = require('./aepdatacollection-checkout');
            const fn = args.shift() || 'init';
            if (module[fn]) {
                module[fn].apply(null, args);
            }
        }, function(error) {
            console.log('error!', error);
        }, 'aepdatacollection-checkout');
    },

    formatString: function(str) {
        return (str && str !== '') ? str.replace(/&|;|,|\+|%|@|#|!|\(|\)|\[|]|{|}|=|\*|\^|-|\$|'|"/g, ' ') : '';
    },

    eventNameValue: function(eventName) {
        var val = eventNameVal.get(eventName);
        if (val == undefined) {
            eventNameVal.set(eventName, new Number(1));
        } else {
            eventNameVal.set(eventName, new Number(val + 1));
        }
        return eventName + eventNameVal.get(eventName);
    },

    getParameterByName: function(name, url) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    findProductFit: function(button) {
        if (button) {
            return button.text().trim();
        } else if ($('.rl-quickShop-dialog').length > 0) {
            return $('.rl-quickShop-dialog .polo-fit-container-list').length > 0 ? $('.rl-quickShop-dialog .polo-fit-container-list').children('.selected').eq(0).text().trim() : '';
        } else if ($('.polo-fit-container-list').children().length > 0) {
            return $($('.polo-fit-container-list').children('.selected')[0]).text().trim();
        } else {
            return '';
        }
    },

    getAnalyticsCategory: function() {
        var breadcrumbs = [];

        $('.plp-info-breadcrumb-path .breadcrumb-element').each(function() {
            if (!breadcrumbs.includes($(this).text().trim())) {
                breadcrumbs.push($(this).text().trim());
            }
        });
    
        var currentDisplayName = $('.plp-info-breadcrumb-category-name:last').text().trim();
        currentDisplayName = currentDisplayName.replace(/Men's |Women's |Boy's |Girl's /,'');
        breadcrumbs.push(currentDisplayName);
    
        return breadcrumbs.join('/').toLowerCase();        
    },

    getFindingMethod: function() {
        var searchParams = new URLSearchParams(document.location.search);
        var abTag = searchParams.get('ab');
        var fq = searchParams.get('fq');
        var isSearchSuggestion = fq ? true : false;
        var isSearch = digitalData.search.searchTerm ? true : false;
        var isContent = abTag ? true : false;
        var isBreadcrumb = window.isBreadcrumb || false;
        var analyticsMethod = isContent ? 'content' :
            isSearchSuggestion ? 'search suggestion' :
            isSearch ? 'search' :
            isBreadcrumb ? 'breadcrumb' : 
            'browse';
            
        return analyticsMethod;
    },

    getPriceOrder: function() {
        var priceType = 'FP';
        if ($('.colorname .price-type-heading.sale').length > 0) {
          let swatchRows = $('.colorname .s7viewer-swatches-cont:visible');
          let firstSwatchRow = swatchRows.eq(0);
          priceType = $('.colorname .price-type-heading.sale').eq(0).index() < firstSwatchRow.index() ? 'MD>FP' : 'FP>MD';
        } else if ($('.pdp-infos .product-price .price-sales').length > 0){
          priceType = 'MD';
        }
        return priceType;        
    }
};

module.exports = util;