/* eslint-disable new-cap */
'use strict';
/**
 * the legacy files add a SwiperLegacy global handle instead of Swiper
 * global Swiper handle is now a proxy to the versioned SwiperJS code
 * new CSS use :scope to avoid style collisions
 */

const legacyHandle = 'SwiperLegacy';
const staticPath = Urls.staticPath;

const legacy = {
    js: 'lib/swiper/swiper-bundle.6.5.8.min.js',
    css: 'lib/swiper/swiper-bundle.6.5.8.min.css'
};

const rejectListenersMap = new Map();
const resolveListenersMap = new Map();

const addToMap = function (map, key, fn) {
    const item = map.get(key);
    if (item) {
        item.push(fn);
    } else {
        map.set(key, [fn]);
    }
};

const flushMap = function (map, key) {
    const item = map.get(key);
    if (item) {
        while (item.length) {
            item.shift()();
        }
    } else {
        console.log('should not go there!');
    }
};

const addListeners = function (element, resolve, reject) {
    addToMap(rejectListenersMap, element, reject);
    addToMap(resolveListenersMap, element, resolve);
    element.onError = () => {
        element.setAttribute('status', 'failed');
        flushMap(rejectListenersMap, element);
    };
    element.onload = () => {
        element.setAttribute('status', 'loaded');
        flushMap(resolveListenersMap, element);
    };
};

const testStatus = function (element, resolve, reject) {
    const status = element.getAttribute('status');
    switch (status) {
        case 'loaded': resolve();
            break;
        case 'failed': reject();
            break;
        default:
            addListeners(element, resolve, reject);
    }
};

const loadStyles = function (path) {
    return new Promise(function (resolve, reject) {
        const node = document.querySelector(`link[href="${path}"]`);
        if (!node) {
            var element = document.createElement('link');
            addListeners(element, resolve, reject);
            element.type = 'text/css';
            element.rel = 'stylesheet';
            element.href = path;
            document.body.appendChild(element);
        } else {
            testStatus(node, resolve, reject);
        }
    });
};

const loadJS = function (path) {
    return new Promise(function (resolve, reject) {
        const node = document.querySelector(`script[src="${path}"]`);
        if (!node) {
            var element = document.createElement('script');
            addListeners(element, resolve, reject);
            element.src = path;
            document.body.appendChild(element);
        } else {
            testStatus(node, resolve, reject);
        }
    });
};

function loadAssetandExecute(handle, css, js, selector, options) {
    return new Promise(function (resolve, reject) {
        // load current files
        const assetsPpromises = [loadJS(js)];
        if (css) {
            assetsPpromises.push(loadStyles(css));
        }
        Promise.all(assetsPpromises)
            .then(function () {
                const swiper = new window[handle](selector, options);
                resolve(swiper);
            })
            .catch((error) => {
                console.log('error', error.message);
                reject();
            })
            .finally(() => {
                //
            });
    });
}

/**
 *
 * Stored in the global scope as Swiper
 * intercepts calls to Swiper. check the version
 * loads JS and scoped CSS for the version
 * while fetching store any actions on Swiper in a Proxy
 * when loaded reapply those actions
 *
 * When the code already exist for the requested version, simply return the versioned instance
 */

class SwiperProxy {
    constructor(selector, options = {}) {
        let swiperInstance;

        // store method calls and property assignments until versioned files are loaded
        const setProperties = [];
        const methodCalls = [];
        const methodCallProxy = (name) => (...args) => {
            methodCalls.push({
                name,
                args
            });
        };
        // rerun calls and assignments
        const setSwiper = (swiper) => {
            swiperInstance = swiper;
            while (methodCalls.length) {
                const call = methodCalls.shift();
                swiper[call.name](...call.args);
            }
            while (setProperties.length) {
                const prop = setProperties.shift();
                swiper[prop.name] = prop.value;
            }
        };

        const version = options.version;
        switch (version) {
            // eslint-disable-next-line no-undefined
            case undefined:
            case '4':
            case '6':
                // current state
                if (window[legacyHandle]) {
                    return new window[legacyHandle](selector, options);
                } else {
                    // CSS is already in the page
                    loadAssetandExecute(legacyHandle, null, staticPath + legacy.js, selector, options)
                        .then(setSwiper);
                }
                break;
            default:
                // versionning
                if (window[`Swiper_${version}`]) {
                    return new window[`Swiper_${version}`](selector, options);
                } else {
                    loadAssetandExecute(`Swiper_${version}`, staticPath + `lib/swiper/swiper-bundle.rl.${version}.min.css`, staticPath + `lib/swiper/swiper-bundle.rl.${version}.min.js`, selector, options)
                        .then(setSwiper);
                }
        }

        // Proxy to store calls and assignements during fetch
        // after simply forward to fetched object
        return new Proxy({}, {
            get(target, key) {
                if (swiperInstance) {
                    if (typeof (swiperInstance[key]) === 'function') {
                        return swiperInstance[key].bind(swiperInstance);
                    } else {
                        return swiperInstance[key];
                    }
                } else {
                    return methodCallProxy(key);
                }
            },
            set(target, name, value) {
                if (swiperInstance) {
                    swiperInstance[name] = value;
                } else {
                    setProperties.push({
                        name,
                        value
                    });
                }
                return true;
            },
        });
    }
}
window.Swiper = SwiperProxy;
