'use strict';

/**
 * This file is created to support utility functions for the main `toaster.js` file.
 * The idea is to clean the `toaster.js` file & make it more readable. In future more smaller functions can be created from `toaster.js` and moved here for cleaner code, if required.
 */

function loadDfStyles(cssUrlStaticpath) {
    if (cssUrlStaticpath) {
        if (!$('link[href="' + Urls.staticPath + cssUrlStaticpath + '"]').length) {
            $('<link href="' + Urls.staticPath + cssUrlStaticpath + '" rel="stylesheet">').appendTo('body');
        }
    }
}

function onDialogEvent(e, type) {
    //in case a dialog is triggered from the toaster
    // var mobileDesignWatcher = window.matchMedia('(max-width:959px)');
    /*
    if (type === 'open' || type === 'replace') {
        $('body').removeClass('toaster-prevent-scroll');
        if (mobileDesignWatcher.matches) {
            $('#wrapper').css({ 'height': '', 'margin-top': '' });
        }
    } else {
        $('body').addClass('qs-prevent-scroll');
        if (mobileDesignWatcher.matches) {
            $('#wrapper').css({
                'height': '100wh',
                'margin-top': (0 - $(window).scrollTop())
            });
        }
    }
    */
}

/**
 * @description Returns the default opening direction for a toaster as per screen size.
 * @returns {'bottom' | 'right'} default direction for a toaster to open from
 */
function getDefaultToasterOpenDirection() {
    return $(window).width() <= 767
        ? 'bottom'
        : 'right';
}

/**
 * @description Returns the inverted direction for RTL locales
 * @returns {string}
 */
function getRTLDirection(direction) {
    if ($(window).width() > 767) {
        if (window.SitePreferences.RTL_LOCALE) {
            if (direction === 'right') {
                direction = 'left';
            } else if (direction === 'left') {
                direction = 'right';
            }
        }
    }
    return direction;
}

/**
 * @description Fetches static HTML content closest to the clicked `next level toaster link`.
 * This `static content/HTML container` sibling should have a class name `js-modal-next-level-content`.
 * @param {JQuery<HTMLElement>} $nextLevelLink
 * @return {JQuery<HTMLElement>} static content container closest sibling to the clicked link
 */
function getNextLevelStaticContent($nextLevelLink) {
    return $nextLevelLink
        .siblings('.js-modal-next-level-content')
        .first();
}

/**
 * @description Placeholder function to handle front end (or any other post toaster open) changes required after next level toaster is opened.
 * @param {JQuery<HTMLElement>} $toaster
*/
function postNextLevelOpen($toaster) {
}

/**
 * @description Checks if passed toaster content has a title portion with class `js-toaster-title`
 * @param {JQuery<HTMLElement>} $toasterContent
 */
function isTitleInContent($toasterContent) {
    return $toasterContent
        .children('.js-toaster-title')
        .first()
        .length > 0;
}

/**
 * @description Adds title html to the next level toaster.
 * - By Default, the next level toaster title is the link/CTA text wrapped in a < h2 >
 * - This default can be overridden & will not be added if there is already a title < h2 > with class `js-toaster-title` in markup. (Example in comment of `toaster.js`)
 * @param {JQuery<HTMLElement>} $nextLevelStaticContent the static markup of next level toaster content
 * @param {JQuery<HTMLElement>} $nextLevelLink the link/CTA clicked to open next level modal
 */
function addTitleToToaster($nextLevelStaticContent, $nextLevelLink) {
    if (!isTitleInContent($nextLevelStaticContent)) {
        const title = $nextLevelLink.data('flyoutTitle') || $nextLevelLink.html();
        var $content = $(
        `<div><div class="flyout-header">
            <h2 class="monogram-modal-title flyout-title">${title}</h2>
        </div>
        <div class="flyout-body">
            <div class="flyout-body-inner"></div>
        </div></div>`
        );
        $content.find('.flyout-body-inner').append($nextLevelStaticContent);
        return $content;
    } else {
        return $nextLevelStaticContent;
    }
}
/**
 * @description Registers event to trigger render of next level toaster/modal content.
 * - If the `link/button` to open next level has a sibling with class `.js-modal-next-level-content`, then it renders the static content from this class HTML element.
 * - If no such class HTML element exists with static conten, then an AJAX call is made to URL set in attribute `data-url` or `href` attribute of the clicked link.
 *
 * These 2 conditions are determined by variable `isStaticContent`.
 * @param {'right' | 'bottom'} currentOpeningDirection
 * @param {string} toasterClass
 * @param {boolean} hideClose
 */
function registerNextLevelToasterEvent(currentOpeningDirection, toasterClass, hideClose, teleport, loadToaster) {
    const $allVisibleNextLevelLinks = $('.js-modal-next-level-link').not('.hide');

    $allVisibleNextLevelLinks.off('click').on('click', function (ev) {
        ev.preventDefault();

        const $nextLevelLink = $(ev.target);
        const $nextLevelStaticContent = getNextLevelStaticContent($nextLevelLink);
        const isStaticContent = $nextLevelStaticContent && $nextLevelStaticContent.length;

        if (isStaticContent) {
            addTitleToToaster($nextLevelStaticContent, $nextLevelLink);

            teleport($nextLevelStaticContent, currentOpeningDirection, null, toasterClass, hideClose, true)
                .then(postNextLevelOpen);
        } else {
            const url = $nextLevelLink.data('url') || $nextLevelLink.attr('href');
            const addTitle = $nextLevelLink.hasClass('js-add-title');
            const params = { format: 'ajax' };
            loadToaster(require('./util').appendParamsToUrl(url, params), currentOpeningDirection, null, toasterClass, true, addTitle ? $nextLevelLink : null)
                .then(postNextLevelOpen);
        }
    });
}

module.exports = {
    loadDfStyles,
    onDialogEvent,
    getDefaultToasterOpenDirection,
    getRTLDirection,
    postNextLevelOpen,
    addTitleToToaster,
    registerNextLevelToasterEvent,
};
