'use strict';

var util = require('../../util');
var progress = require('../../progress');
var navigationUtils = require('./utils');
var viewtype = require('../../viewtype');
const FADE_IN_CLASS = 'fadein';

var swEnabled = false;
var cuid;
var isDevOrStaging = typeof window.instanceType != 'undefined' && window.instanceType < 2;

function isLoadLocked(item, forceLoad = false) {
    const $item = $(item);
    // check if flyout was loaded before or is currently loading
    if ($item.data('loadState') === 'loading' || ($item.data('loadState') === 'loaded' && !forceLoad)) {
        return true;
    }

    return false;
}

function checkNextLevelContent($item, $nextLevelWrapper) {
    const NO_SUB_CATEGORIES_CLASS = 'no-sub-categories';
    const SHOW_DEFAULT_FLYOUT_CONTENT_CLASS = 'show-default-flyout-content';

    if (!$nextLevelWrapper.data('noCategories')) {
        $item.removeClass(NO_SUB_CATEGORIES_CLASS);
    } else if ($item.hasClass('js-nav-item-default')) {
        // show default L3 content slot on mobile under L2 items list
        $item.closest('.js-nav-level-2').addClass(SHOW_DEFAULT_FLYOUT_CONTENT_CLASS);
    }
}

function removeEmptyFullSlot($flyout) {
    const $fullSlot = $flyout.find(' .flyout-full-slot-content');
    if (!$fullSlot.children().length) {
        $fullSlot.remove();
    } else {
        $flyout.find('.js-nav-level-3-wrapper').remove();
    }
}

function replaceL2Html($item, html, showLoader) {
    const flyoutSelector = '.' + $item.data('flyout');
    const $flyout = $item.find(flyoutSelector);
    const $level3ItemsContainer = $item.closest('.js-nav-level-2').find('.js-level3-items-container');
    $item.data('loadState', 'loaded');

    // insert flyout html
    if (html) {
        $flyout.html(html);
    }

    removeEmptyFullSlot($flyout);

    const $level3Wrapper = $flyout.find('.js-nav-level-3-wrapper');
    const L3ColumnBreakCount = $level3Wrapper.data('l3BreakCount') || 8;
    const maxL3CountForHighlights = $level3Wrapper.data('maxL3CountForHighlights') || 9;
    var $level3ItemLength = $level3Wrapper.find('.js-nav-level-item').length
    var $level3MobileOnlyLength = $level3Wrapper.find('.js-nav-level-item.device-specific-mobileOnly').length;
    var  hasHighlightContent = $level3Wrapper.find('.js-nav-asset-with-highlights').length;
    // update the column break
    if (($level3ItemLength - $level3MobileOnlyLength < parseInt(L3ColumnBreakCount)) || hasHighlightContent > 0) {
        $level3Wrapper.find('ul').removeClass('l3-column-break');
    }
    $level3Wrapper.find('.js-nav-level-item:not(.device-specific-mobileOnly)').each(function(index, ele) {
        if (hasHighlightContent && (index > (parseInt(maxL3CountForHighlights) - 1))) {
            $(ele).addClass('hide-l3-for-desktop');
        }
    });
    if (viewtype.isLargeViewAndUp()) {
        $level3Wrapper.css({ opacity: 0 });
    }
    let clonedDiv = $flyout.clone(true);
    clonedDiv.hide();
    $level3ItemsContainer.append(clonedDiv);

    if (showLoader && viewtype.isLargeViewAndUp()) {
        clonedDiv.attr('aria-hidden', false).attr('aria-expanded', true).show();
    }

    checkNextLevelContent($item, $level3Wrapper);

    if (viewtype.isMediumViewAndDown() && $flyout.find('.js-nav-asset-with-highlights').length) {
        // move content slot with content to top
        $flyout.find('.js-flyout-slot-content').prependTo($level3Wrapper);
    }
    if ($flyout.closest('.js-nav-level-2-item.active').length) {
        document.dispatchEvent(new CustomEvent('contentChanged', { detail: { action: 'flyout_in', cont: $flyout.length && $flyout[0] } }));
    }
}
/**
 * Loads nav item flyout data and inserts it into flyout container
 *
 * @param {JQuery} item - nav item
 * @param {boolean} showLoader - flag to show progress loader
 * @param {boolean} forced - flag to execute request even if flyout was loaded before
 * @returns {promise}
 */
function loadFlyout(item, showLoader = false, forced = false) {
    if (Boolean($('.js-top-banner').data('load-complete-nav'))) {
        if (!isLoadLocked(item)) {
            replaceL2Html($(item));
        }
        return;
    }

    return new Promise((resolve) => {
        const $item = $(item);
        const flyoutSelector = '.' + $item.data('flyout');
        const url = $item.data('flyoutUrl');
        const $level3ItemsContainer = $item.closest('.js-nav-level-2').find('.js-level3-items-container');

        if (isLoadLocked(item, forced)) {
            return;
        }

        $item.data('loadState', 'loading');

        if (showLoader) {
            progress.show($level3ItemsContainer);
            progress.show(flyoutSelector);
        }

        navigationUtils.cachefetch(url, swEnabled, cuid, null, function (promise) {
            promise.then((html) => {
                // update nav item
                replaceL2Html($item, html, showLoader);
            }).catch(() => {
                // cachefetch failed
            }).finally(() => {
                //console.log('Finally!!!!!!!!')
                if (showLoader) {
                    progress.hide($level3ItemsContainer);
                    progress.hide(flyoutSelector);
                }
                resolve();
            });
        });
    })
}

/**
 *
 * @param {array} items
 * @param {object} debounceConfig - debounce wait, limit and delay
 * @returns
 */

async function subLoad(items, debounceConfig) {
    if (debounceConfig.wait) {
        return Promise.all(items.map(async item => await loadFlyout(item)))
    } else {
        items.forEach(item => loadFlyout(item));
        if (debounceConfig.delay) {
            await new Promise(resolve => setTimeout(resolve, debounceConfig.delay)); // eslint-disable-line no-await-in-loop
        }
    }
}
/**
 *
 * @param {JQuery} items - Array of nav items with flyouts
 * @param {object} debounceConfig - debounce wait, limit and delay
 */

async function lazyLoadFlyouts(items, debounceConfig = {}) {
    return false;

    items = [].filter.call(items, item => !isLoadLocked(item));
    for (let i = 0; i < items.length; i += debounceConfig.limit) {
        await subLoad(items.slice(i, i + debounceConfig.limit), debounceConfig)
    }
}

async function lazyLoadDefaultFlyouts() {
    const $defaultNavItems = $('#navigation').find('.js-nav-item-default');
    await lazyLoadFlyouts($defaultNavItems, { limit: 10, delay: 500 });
}

function showDefaultFlyout() {
    const $defaultNavItems = $('#navigation').find('.js-nav-item-default');
    $defaultNavItems.each((index, item) => {
        if (!isLoadLocked(item)) {
            replaceL2Html($(item));
        }
    })
}

/**
 *
 * @param {jqueryObject} $navItems - level 2 items we need to fetch
 * @returns
 */
async function loadFlyouts(endPoint, $navItems) {
    swEnabled = Boolean($('.js-top-banner').data('nav-serviceworker'));
    return new Promise((resolve) => {
        const prefix = 'js-flyout-';
        // filter unloaded, set state
        $navItems.filter((i, item) => !isLoadLocked(item)).data('loadState', 'loading');

        navigationUtils.cachefetch(endPoint, swEnabled, cuid, null, function (promise) {
            promise.then((html) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                const partials = doc.querySelectorAll('partial');
                partials.forEach(partial => {
                    const $item = $('.js-nav-level-2-item[data-flyout="' + prefix + partial.dataset.cgid + '"]')
                    replaceL2Html($item, partial.innerHTML);
                })
            }).catch((e) => {
                // cachefetch failed
                console.log('loadFlyouts error', e)
            }).finally(() => {
                // reset the state for any that were not replaced
                $navItems.each(function () {
                    if ($(this).data('loadState') === 'loading') {
                        $(this).removeData('loadState');
                    }
                });
                resolve();
            });
        });
    });
}

function highlightItem($item) {
    const HIGHLIGHTED_CLASS = 'highlighted';
    const $level2Wrapper = $item.closest('.js-nav-level-2-wrapper');

    $level2Wrapper.find('.js-nav-level-2-item.' + HIGHLIGHTED_CLASS).removeClass(HIGHLIGHTED_CLASS);

    $item.addClass(HIGHLIGHTED_CLASS);
    $item.addClass('hover');
}

function highlightDefaultItem($navContainer) {
    highlightItem($navContainer.find('.js-nav-item-default'));
}

function initializeEventsForDesktop() {
    const HOVER_CLASS = 'hover';
    const OUT_CLASS = 'out';
    const $navigation = $('#navigation');
    let contentChangedTriggerTimeout;

    function onMouseOver() {
        const $item = $(this); // li.js-nav-level-2-item
        if (util.isDesktopSize()) { // >= 960px
            // load flyout in case it wasn't lazy-loaded
            loadFlyout(this, true);

            $item.removeClass(OUT_CLASS).addClass(HOVER_CLASS);

            const $l3Container = $item.closest('.js-nav-level-2').find('.js-level3-items-container');
            const $allNextLevelNavSiblings = $l3Container.find('.js-nav-level-3');

            $l3Container.find('.js-nav-level-3-wrapper').css({ opacity: 0 });
            highlightItem($item);

            // hide previously active flyouts
            $allNextLevelNavSiblings.attr('aria-hidden', true).attr('aria-expanded', false).hide();

            // show current flyout
            const $currentFlyout = $l3Container.find(`.${$item.data('flyout')}`);
            removeEmptyFullSlot($currentFlyout);

            $currentFlyout.attr('aria-hidden', false).attr('aria-expanded', true).show();
            $currentFlyout.find('.js-nav-level-3-wrapper').css({ opacity: 1 });
            if ($('.highlights-alert-message').length) {
                $('.highlights-alert-message').remove();
            }
            /* This alert message will only be visible on Development and Staging when the Highlight Assets are used and there are more than 9L3s */
            if (isDevOrStaging && $currentFlyout.find('.js-nav-level-3-wrapper .js-nav-level-3-item.hide-l3-for-desktop').length) {
                $currentFlyout.find('.js-nav-level-3-wrapper .l3-nav-items ul').prepend('<p class="highlights-alert-message">Only the first 9 L3s are visible</p>')
            }
            $navigation.find('ul > li a[href$="' + window.location.href + '"]').each(function () {
                if ($(this).closest('.js-nav-level-3').is(':visible')) {
                    $(this).attr('aria-describedby', 'currentpage');
                }
            });

            contentChangedTriggerTimeout = setTimeout(() => {
                document.dispatchEvent(new CustomEvent('contentChanged', { detail: { action: 'flyout_in', cont: $currentFlyout.length && $currentFlyout[0] } }));
            }, 300)
        } else {
            removeEmptyFullSlot($item);
        }
    }

    function onMouseOut() {
        if (contentChangedTriggerTimeout) {
            clearTimeout(contentChangedTriggerTimeout);
        }

        if (util.isDesktopSize()) { // >= 960px
            const $item = $(this); // li.js-nav-level-2-item

            $item.removeClass(HOVER_CLASS).addClass(OUT_CLASS);
        }
    }

    $navigation.find('.js-nav-level-2-item').on('mouseenter', onMouseOver).on('mouseleave', onMouseOut);

    // tablet clicks
    if (util.isTablet() || util.isMobile()) {
        $navigation.find('.js-nav-level-2-link').off('click touch').on('click touch', function (e) {
            const $item = $(this).closest('.js-nav-level-item'); // li.js-nav-level-2-item

            if (!util.isDesktopSize() || $(this).hasClass('js-direct-link')) {
                return true;
            } else if (!$item.hasClass(HOVER_CLASS)) {
                e.preventDefault();
            } else {
                return true;
            }

            $item.siblings('.js-nav-level-item.hover').each(function () {
                onMouseOut.call($item);
            });

            onMouseOver.call($item);

            $navigation.find('ul > li a[href$="' + window.location.href + '"]').each(function () {
                if ($(this).closest('.js-nav-level-3').is(':visible')) {
                    $(this).attr('aria-describedby', 'currentpage');
                }
            });

            return false;
        });
    }
}

function initializeEventsForMobile() {
    const ACTIVE_CLASS = 'active';
    const $topBanner = $('#top-banner');
    const $navigation = $('#navigation');
    const $level2Wrapper = $navigation.find('.js-nav-level-2-wrapper');

    $level2Wrapper.find('.js-nav-level-3-wrapper').each(function () {
        const $level2Item = $(this).closest('.js-nav-level-2-item');

        checkNextLevelContent($level2Item, $(this));
    });

    $navigation.find('.js-nav-level-2-item').children('.js-mobile-open-sub-menu').off('click touch').on('click touch', function (e) {
        e.preventDefault();

        const $button = $(this);
        const $item = $button.closest('.js-nav-level-item'); // li.js-nav-level-2-item

        util.setTopPosition($item.find('.js-nav-level-3'));

        // load flyout in case it wasn't lazy-loaded
        loadFlyout($item, true);

        $button.attr('aria-expanded', true);


        // open next level nav panel
        const $nextLevelNavPanel = $item.addClass(ACTIVE_CLASS).children('.js-nav-level-wrapper').first();

        // copy flyout title value
        const $flyoutTitleWrapper = $topBanner.find('.js-flyout-title-wrapper');
        // $item.find('.js-nav-level-3').css({'margin-left': 0});

        $flyoutTitleWrapper.fadeOut(250, function () {
            $flyoutTitleWrapper.html($nextLevelNavPanel.find('> .js-menu-wrap > .js-nav-flyout-top .js-nav-flyout-title-content').html() || '');
            $flyoutTitleWrapper.fadeIn(250);
        }
        );

        $nextLevelNavPanel.show('slide', {
            direction: 'right'
        }, 500, function () {
            document.dispatchEvent(new CustomEvent('contentChanged', { detail: { action: 'flyout_in', cont: $item.length && $item[0] } }));
            $item.closest('.js-nav-level-2-wrapper').scrollTop(0);
        });

        $item.closest('.js-nav-level-2-wrapper').find('.js-nav-level-2-item > .js-nav-fade-in-element').removeClass(FADE_IN_CLASS);

        setTimeout(function fadeNav() {
            $item.closest('.js-nav-level-2-item').find('.js-nav-level-3-wrapper  .js-flyout-slot-content.js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
            $item.find('.js-nav-level-3-item > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
            $item.find('.js-nav-level-3 .js-flyout-slot-content.js-nav-fade-in-element').addClass(FADE_IN_CLASS);
        }, 350); // delay for nav to finish slide in

        $topBanner.find('.js-nav-mobile-back').data('scrollUnlock', false);
    });

    $level2Wrapper.find('.js-default-flyout-content-mobile').each(function () {
        if ($(this).find('.js-nav-asset-with-highlights').length) {
            // move content slot with content to top
            $(this).prependTo($level2Wrapper);
        }
    });
}

function initializeEvents() {
    initializeEventsForDesktop();
    initializeEventsForMobile();
}

exports.init = function () {
    swEnabled = Boolean($('.js-top-banner').data('nav-serviceworker'));
    cuid = $('.js-header-acc').data('cuid');

    initializeEvents();

};

exports.lazyLoadFlyouts = lazyLoadFlyouts;
exports.highlightDefaultItem = highlightDefaultItem;
exports.lazyLoadDefaultFlyouts = lazyLoadDefaultFlyouts;
exports.loadFlyouts = loadFlyouts;
exports.showDefaultFlyout = showDefaultFlyout;
