
'use strict';
var util = require('./util'),
	validator = require('./validator');
var pdpscroll = {
    sticky_pdp: function(accordion) {
        $(document).trigger('pdpscroll:update',accordion);
    }
}

    function headerClick(){
            var $this = $(this);
            var $button = $this.find('button');
            var $target = $this.next();
            var $arrow = $(this).find('.toggle-arrow');
            var $parentGroup = $this.parent();
            if ($target.is(':visible')) {
                $target.slideUp(function(){
                    if (($('#buystack-sticky').length) && (!$('#QuickViewDialog').length)) {  
                        $('.product-top-content .product-col-2.product-detail').removeAttr('style');
                        var accordion = true;     
                        pdpscroll.sticky_pdp(accordion);   
                    }
                });
                $arrow.removeClass('toggle-arrow-up');
                
                $button.attr('aria-expanded', false);
                $target.attr('aria-hidden', true);
                $parentGroup.removeClass('accordion-opened');
            } else {
                // Hide the other open accordions in SAME group
                
                if ($parentGroup.hasClass('accordion-group')) {
                    var $siblings = $parentGroup.siblings('.accordion-group');
                    $siblings.find('.accordion-content').slideUp(function(){
                        if (($('#buystack-sticky').length) && (!$('#QuickViewDialog').length)) {      
                            var accordion = true;     
                            pdpscroll.sticky_pdp(accordion);   
                        }
                        $(this).attr('aria-hidden', 'true');
                    });
                    $siblings.find('.toggle-arrow').removeClass('toggle-arrow-up');
                    $siblings.find('button').attr('aria-expanded', false);
                    $siblings.removeClass('accordion-opened');
                    $parentGroup.addClass('accordion-opened');
                }
                if (($('#buystack-sticky').length) && (!$('#QuickViewDialog').length) && $('#buystack-sticky').hasClass('buy-stack-stop')) {
                    $('#buystack-sticky').addClass('keep-at-position');
                    $('#buystack-sticky').css("padding-top", ($('.product-top-content .product-col-2.product-detail').outerHeight() - $('#buystack-sticky').outerHeight() + 20));
                    $('#buystack-sticky').removeClass('buy-stack-stop');

                    $target.slideDown(function(){
                        if (($('#buystack-sticky').length) && (!$('#QuickViewDialog').length)) {      
                            var accordion = false;     
                            pdpscroll.sticky_pdp(accordion);    
                        }
                        $target.attr('aria-hidden', 'false');
                    });
                } else {
                    $target.slideDown(function(){
                        if (($(window).width() < 960) && ($this.offset().top < $(window).scrollTop())){
                            $('html, body').animate({
                                scrollTop: $this.offset().top
                            }, 500);
                        }
                        if (($('#buystack-sticky').length) && (!$('#QuickViewDialog').length)) {      
                            var accordion = true;     
                            pdpscroll.sticky_pdp(accordion);    
                        }
                        $target.attr('aria-hidden', 'false');
                    });
                }
                // Flip arrow down when other accordions are opened
                $('.toggle-arrow').removeClass('toggle-arrow-up');
                
                // Flip arrow up on active accordion
                $arrow.addClass('toggle-arrow-up');
                $button.attr('aria-expanded', true);
                if (SitePreferences.ANALYTICS_ENABLED != undefined && SitePreferences.ANALYTICS_ENABLED && $(this).closest('.product-detailed-information').length > 0) {
                    if (digitalData != null) {
                        if (digitalData.attributes != null && digitalData.attributes != undefined) {
                            digitalData.attributes.pdpTabClicked = $(this).find('h3').text().trim();
                        } else {
                            digitalData.attributes = {
                                pdpTabClicked: $(this).find('h3').text().trim()
                            };
                        }
                    }
                    if(typeof _satellite != 'undefined'){
                    	_satellite.track("PDPTabClick");
                    }
                }
                
            }   
            
          
            
            return false;
    }
    function initializeEvents(reset) {
        validator.init();
        $(document).off('click','.accordion > .accordion-header', headerClick).on('click','.accordion > .accordion-header', headerClick); 
        //Accordion Attempt
        if(reset){
            $('.accordion-content:visible').hide();
            $('.accordion-content.open').show();
        } else {
        	// we should really only close in the group where one is set to open
            if($('.accordion-content.open').length > 0){
                $('.accordion-content:visible').hide();       
                $('.accordion-content.open').removeClass('open').closest('.accordion-group').find('.accordion-header').trigger('click');
            } 
        }    
        //HTN-1346
        if($(".no-add-tocart-btn .product-detailed-information .accordion-group").length == 1) {
        	$(".product-detailed-information .accordion-group .accordion-header").not( ".look-product-content.instore-only-product-block .accordion-group .accordion-header" ).trigger('click');
        }
        if ($(window).width() > 959) {
        	$('.order-details-accordion.accordion-wrapper').insertAfter('.order-details-info.message.cart-left-col .confirmation-message');
        }
        var mobileNoBlock = $('.confirmation-message .mobile-no-block');
        mobileNoBlock.find('.change-number').on('click', function(e){
        	e.preventDefault();
        	$(this).closest('.conf-msg').addClass('hide');
        	mobileNoBlock.find('.order-notifyprefernce').removeClass('hide');
        });
        mobileNoBlock.find('#order-optin').on('click', function(e){
        	e.preventDefault();
        	var $curForm = $(this).closest("form");
            $curForm.validate();
            if (!$curForm.valid()) {
                return false;
            }
            var phno = $(this).closest('.confirmation-message').find('input[name*="_customer_phoneMobile"]').val();
            var url = $(this).data('href');
            var ordernum = $(this).closest('.confirmation-message').find('.order-number span.value').text();        	
            $.ajax({
                type: 'GET',
                dataType: 'json',
                contentType: 'application/json',
                url: util.appendParamsToUrl(url, {
                	phoneData : phno,
            		orderNo : ordernum
                }),
                success: function(response) {
                    mobileNoBlock.find('.conf-msg span.value').text(phno);
                	mobileNoBlock.find('.order-notifyprefernce').addClass('hide');
                	mobileNoBlock.find('.conf-msg').removeClass('hide');
                }
            });
        });
    };   

exports.init = function (skipReset) {
    initializeEvents(!skipReset);
};
