'use strict';

var util = require('./util');

var currentQuery = null,
    lastQuery = null,
    runningQuery = null,
    listTotal = -1,
    listCurrent = -1,
    delay = 30,
    $resultsContainer,
    $staticSuggestions;
/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
function handleArrowKeys(keyCode) {
    switch (keyCode) {
        case 38:
            // keyUp
            listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
            break;
        case 40:
            // keyDown
            listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
            break;
        default:
            // reset
            listCurrent = -1;
            return false;
    }

    $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
    $('input[name="q"]').val($resultsContainer.find('.selected .suggestionterm').first().text());
    return true;
}

var errorsearchsuggest = {
    /**
     * @function
     * @description Configures parameters and required object instances
     */
    init: function (container, defaultValue) {
        var $searchContainer = $(container);
        var $searchForm = $searchContainer.find('form[name="simpleSearch"]');
        $staticSuggestions = $('.productsearch-result-divisions', $searchContainer.parent());
        var $searchField = $searchForm.find('input[name="q"]');
        var $searchClearButton = $searchForm.find('.clear-search');
        // disable browser auto complete
        $searchField.attr('autocomplete', 'off');

        // on focus listener (clear default value)
        $searchField.on('focus',function () {
            if (!$resultsContainer) {
                // create results container if needed
                $resultsContainer = $('<div/>').attr('id', 'search-suggestions').appendTo($searchContainer);
                $resultsContainer = $searchForm.closest('div').find('#search-suggestions');
            }
            if ($searchField.val() === defaultValue) {
                $searchField.val('');
            }
        });

        $(document).on('click', function (e) {
            if (!$searchContainer.is(e.target)) {
                setTimeout(this.clearResults, 200);
            }
        }.bind(this));
        // on key up listener
        $searchField.on('keyup',function (e) {

            // get keyCode (window.event is for IE)
            var keyCode = e.keyCode || window.event.keyCode;

            // check and treat up and down arrows
            if (handleArrowKeys(keyCode)) {
                return;
            }
            // check for an ENTER or ESC
            if (keyCode === 13 || keyCode === 27) {
                this.clearResults();
                return;
            }

            currentQuery = $searchField.val().trim();

            // no query currently running, init an update
            if (!runningQuery) {
                runningQuery = currentQuery;
                setTimeout(this.suggest.bind(this), delay);
            }
        }.bind(this));

        $searchClearButton.on('click', () => {
            $searchField.val('');
            $searchField.trigger('focus');
            this.clearResults();
        });
    },

    /**
     * @function
     * @description trigger suggest action
     */
    suggest: function () {
        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)
        if (runningQuery !== currentQuery) {
            // update running query to the most recent search phrase
            runningQuery = currentQuery;
        }

        // if it's empty clear the results box and return
        if (runningQuery.length === 0) {
            this.clearResults();
            runningQuery = null;
            return;
        }

        // if the current search phrase is the same as for the last suggestion call, just return
        if (lastQuery === runningQuery) {
            runningQuery = null;
            return;
        }

        // Call bloomreach API if the customer is under bloomreach customer group
        if (SitePreferences.BLOOMREACH_ENABLED) {
            if (runningQuery.length <= 2 ) {
	        	this.clearResults();
	        } else {
	            var dataIDs = {
	                noResult: 'no-results-no-hits',
	                querySuggest: 'query-suggest-no-result',
	                featureProduct: 'feature-products-no-result',
	                searchViewAll: 'search-view-all-no-result',
	                noHits: true
	            };
            	this.getSuggestions(runningQuery, currentQuery, lastQuery, dataIDs);

            }


            // record the query that has been executed
            lastQuery = runningQuery;

            // reset currently running query
            runningQuery = null;

            // check for another required update (if current search phrase is different from just executed call)
            if (currentQuery !== lastQuery) {
                // ... and execute immediately if search has changed while this server call was in transit
                runningQuery = currentQuery;
                setTimeout(this.suggest, delay);
            }
            this.hideLeftPanel();
        } else {
            // build the request url
            var reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', runningQuery);

            // execute server call
            $.get(reqUrl, function (data) {
                var suggestionHTML = data,
                ansLength = suggestionHTML.trim().length;
                // if there are results populate the results div
                if (ansLength === 0) {
                    this.clearResults();
                } else {
                    // update the results div
                    $staticSuggestions.hide();
                    $resultsContainer.html(suggestionHTML).fadeIn(200);
                    $('.trending-search').addClass('hide');
                }

                // record the query that has been executed
                lastQuery = runningQuery;
                // reset currently running query
                runningQuery = null;

                // check for another required update (if current search phrase is different from just executed call)
                if (currentQuery !== lastQuery) {
                    // ... and execute immediately if search has changed while this server call was in transit
                    runningQuery = currentQuery;
                    setTimeout(this.suggest.bind(this), delay);
                }
                this.hideLeftPanel();
            }.bind(this));
        }
    },

    /**
     * @function
     * @description trigger suggest action with Bloomreach API
     */
    getSuggestions: function (runningQuery, currentQuery, lastQuery, dataIDs) {
            var brSuggestUrl = Urls.bloomreachSuggestUrl;

            // build the bloomreach request url
            var reqUrl = util.appendParamsToUrl(brSuggestUrl, {
            	q: runningQuery
            });

            // execute bloomreach service call
            $.get(reqUrl, function(data, status) {
                if (status == 'success') {
                    $('.search-suggestions').css('display','none');
                    if (dataIDs.noHits) {
                        $('#search-suggestions').css('display','none');
                    } else {
                        $('.search-popular-categories').addClass('hide');
                    }

                    $('.trending-search').addClass('hide');
                    $('.br-suggestions').removeClass('hide');
                    if (!dataIDs.noHits) {
                        $('#trending-search-no-result').removeClass('hide');
                        $('#br-suggestions-no-result').addClass('hide');
                    }

                    $('.br-suggestions').find('.phrase-suggestions').removeClass('hide');
                    $('.br-suggestions').find('.product-suggestions').removeClass('hide');
                    $('.br-suggestions').find('.search-all-wrapper').removeClass('hide');
                    $('.br-suggestions').find('#'+dataIDs.noResult).addClass('hide');

                    var suggestionGrp = data['suggestionGroups'][0];
                    if (suggestionGrp.querySuggestions.length > 0) {
                        var categorySuggestArr = new Array();
                        var featureProdArr = new Array();
                        var queryText = suggestionGrp.querySuggestions[0].displayText;
                        queryText = queryText.charAt(0).toUpperCase() + queryText.slice(1);
                        var numRegex = new RegExp('^[0-9]');
                        var hasNumber = numRegex.test(queryText);

                        if (suggestionGrp.attributeSuggestions != undefined && suggestionGrp.attributeSuggestions.length > 0) {
                        	var attrSuggestLength = suggestionGrp.attributeSuggestions.length > 5 ? 5 : suggestionGrp.attributeSuggestions.length;
                            for (var i = 0; i < attrSuggestLength; i++) {
                                categorySuggestArr.push(suggestionGrp.attributeSuggestions[i].name);
                            }
	                        var autoSuggestSection = '';
                            if (!hasNumber) {
                                categorySuggestArr.map(function(attrName) {
                                    var hrefURL = util.appendParamsToUrl(Urls.searchshow, {
                                        'q': queryText,
                                        'fq': 'division:"' + attrName + '"',
                                        'br': 't'
                                    });
                                    autoSuggestSection += '<a class="hit" href="' + hrefURL + '"><span class="icon-search"></span>';
                                    autoSuggestSection += '<span class="division-search-term">' + ' ' + queryText + '</span>' + ' ' + Resources.SEARCH_IN + ' ' + '<span class="division-search-category">' + ' ' + attrName + '</span></a>';
                                });
                            } else {
                                var hrefURL = util.appendParamsToUrl(Urls.searchshow, {
                                    'q': queryText,
                                    'br': 't'
                                });
                                autoSuggestSection += '<a class="hit" href="' + hrefURL + '"><span class="icon-search"></span>';
                                autoSuggestSection += '<span class="division-search-term">' + ' ' + queryText + '</span>' + '</a>';
                            }
	                        document.getElementById(dataIDs.querySuggest).innerHTML = autoSuggestSection;

                        } else {
                        	$('.br-suggestions').find('.phrase-suggestions').addClass('hide');
                        }

                        if (suggestionGrp.searchSuggestions != undefined && suggestionGrp.searchSuggestions.length > 0) {
                            $('.br-suggestions').find('.suggestions-wrapper').removeClass('hide');
                        	$('.br-suggestions').find('.header-serif').removeClass('hide');
                        	var searchSuggestLength = suggestionGrp.searchSuggestions.length > 3 ? 3 : suggestionGrp.searchSuggestions.length;
                            for (var j = 0; j < searchSuggestLength; j++) {
                                var prodArr = {};
                                 var attrValue = suggestionGrp.searchSuggestions[j];
                                 prodArr.saleprice = attrValue.sale_price || '';
                                 prodArr.pid = attrValue.pid || '';
                                 prodArr.url = attrValue.productUrl || '';
                                 prodArr.image = attrValue.thumb_image || '';
                                 prodArr.title = attrValue.title || '';
                                 prodArr.brand = attrValue.brand || '';
                                 prodArr.CatalogCode = attrValue.CatalogCode || '';
                                 prodArr.division = attrValue.division || '';
                                 prodArr.listPrice = attrValue.listPrice || '';
                                 prodArr.salePrice = attrValue.salePrice || '';
                                 featureProdArr.push(prodArr);
                            }

	                        var featureProdSection = '';
	                        featureProdArr.map(function(product) {
	                            var lifestyleImage = "";
	                            if (product.image) {
	                            	var altImage = product.image.split('_').pop().split('?$')[0];
	                            	lifestyleImage = product.image.replace(altImage, 'lifestyle');
	                            }

                                var produrl = product.url;
                                var prodcat = product.CatalogCode;
                                if (prodcat != undefined && prodcat != '' && prodcat.indexOf('swatch') >= 0) {
                                    produrl = util.appendParamsToUrl(Urls.searchshow, {
                                        'cgid': prodcat,
                                        'swatchpid': product.pid
                                    });
                                }

	                            featureProdSection += '<div class="product-suggestion" data-masterid="' + product.pid + '">';
	                            featureProdSection += '<a class="product-link" href="' + produrl + '">';
	                            featureProdSection += '<div class="product-image"><img src="' + product.image + '" onerror="this.onerror=null;this.src=\'' + lifestyleImage + '\'" alt="' + product.title + '" title="' + product.title + '"/></div>';
	                            featureProdSection += '<div class="product-details"><div class="cat">' + product.division + '</div><div class="brand">' + product.brand + '</div><div class="product-name">' + product.title + '</div>';
	                            featureProdSection += '<div class="product-pricing"><div class="product-price-range">' + product.listPrice + '</div>';
	                            if (product.salePrice !== product.listPrice) {
	                            	featureProdSection += '<span class="product-sales-price range" title="Price Range"><span class="lowred">' + Resources.PRODUCT_SELECT_ITEMS + ' ' + product.salePrice + '</span></span>';
	                            }
	                            featureProdSection += '</div></div></a></div>';
	                        });
	                        document.getElementById(dataIDs.featureProduct).innerHTML = featureProdSection;

                        } else {
                        	$('.br-suggestions').find('.suggestions-wrapper').addClass('hide');
                        	$('.br-suggestions').find('.header-serif').addClass('hide');
                        }

                        var viewallURL = util.appendParamsToUrl(Urls.searchshow, {
                            'q': runningQuery,
                            'br': 't',
                            'viewall': true
                        });
                        document.getElementById(dataIDs.searchViewAll).innerHTML = '<a class="hit" href="' + viewallURL + '"><button class="secondary button">' + Resources.SEARCH_VIEW_ALL + '</button></a>';

                    } else {
                        $('.trending-search').removeClass('hide');
                        $('.br-suggestions').find('#'+dataIDs.noResult).removeClass('hide');
                        $('.br-suggestions').find('.phrase-suggestions').addClass('hide');
                        $('.br-suggestions').find('.product-suggestions').addClass('hide');
                        $('.br-suggestions').find('.search-all-wrapper').addClass('hide');
                        document.getElementById(dataIDs.noResult).innerHTML = Resources.BLOOMREACH_NO_RESULTS + ' ' + runningQuery;
                    }
                }

            });
    },
    /**
     * @function
     * @description
     */
    clearResults: function () {
        if (!$resultsContainer) { return; }
        $staticSuggestions.show();
        $resultsContainer.fadeOut(200, function () {$resultsContainer.empty(); });

        if (SitePreferences.BLOOMREACH_ENABLED && !$('.br-suggestions').hasClass('hide')) {
            $('.search-suggestions').css('display','block');
            $('#search-suggestions').css('display','block');
            $('.br-suggestions').addClass('hide');
            $('.br-suggestions').find('.phrase-suggestions').addClass('hide');
            $('.br-suggestions').find('.product-suggestions').addClass('hide');
            $('.br-suggestions').find('.search-all-wrapper').addClass('hide');
            $('.br-suggestions').find('#no-results-no-hits').removeClass('hide');
        }

        $('.trending-search').removeClass('hide');
    },
    /**
     * @function
     * @description
     */
    hideLeftPanel: function () {
        //hide left panel if there is only a matching suggested custom phrase
        if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
            $('.search-suggestion-left-panel').css('display', 'none');
            $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
            $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
        }
    }
};

module.exports = errorsearchsuggest;
