'use strict';
var dialog = require('../dialog');
var util = require('../util');
var progress = require('../progress');
var validator = require('../validator');
var appointment = require('../appointment');
var aep = require('@foundation/js/aepdatacollection-util');

exports.init = function(isFavoriteStore = false) {
	
	var storeLocatorShowAll = false;
	var showMoreMsg = $('#show-more-msg').text();
	var showLessMsg = $('#show-less-msg').text();
	var outerMarkers;
	var currentMarker, oldMarker;
	var mobileInitialZoom = true;
	var searchParams;
	var preventScroll = false;
	
	$('.pt_store-locator .store-description .tablecontent span.title').wrapInner('<h2 class="store-locator-header-font"></h2>');
	
    if(util.getQueryString(window.location.href)) {
        searchParams = util.getQueryString(window.location.href).split('&');
    }
	
	var URLParams = new URLSearchParams(window.location.search);
	var maxDistance = URLParams.get('dwfrm_storelocator_maxdistance');
	if (maxDistance !== null) {
		$('#dwfrm_storelocator_maxdistance').val(maxDistance);
	}

	//Reorientation causes too many problems on tablet, reload
	if (window.navigator.userAgent.indexOf('iPad') > -1 && pageContext.title == 'Store Locator Result') {
		window.addEventListener("orientationchange", function() {
				document.location.reload();	
		});
	}
	
    // If geolocation is available, try to get the visitor's position
    if(navigator.geolocation){
    	var title = Resources.FIS_CURRENT_LOCATION;
    	var $searchKeyWrapper = $('#dwfrm_storelocator').find('.dwfrm_storelocator_searchKey');
    	$searchKeyWrapper.append('<a href="javascript:void(0)" title="'+title+'"><i class="fa fa-location-arrow"></i></a>');
    	$searchKeyWrapper.off('click touchstart', '.fa-location-arrow').on('click', '.fa-location-arrow', function(e) {
    	    progress.show($('.dwfrm_storelocator_searchKey'));
    	    $('.storesFind').prop('disabled', true);
         	e.preventDefault();
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);         		
            $('.searchKey').val(title);
       	});
    }
	
	if(!$('.your-store-module').length) {
		// Define callback function for successful attempt
		var successCallback = function(position) {
			progress.hide();
			$('.storesFind').prop('disabled', false);  
			if (CurrentLocale == 'en_GB') {
				$('#currentLatitude').val(position.coords.latitude);
				$('#currentLongitude').val(position.coords.longitude);    
				
			} else {
				$('#currentLatitude').val(position.coords.latitude.toLocaleString('es-ES', {minimumFractionDigits: 5}));
				$('#currentLongitude').val(position.coords.longitude.toLocaleString('es-ES', {minimumFractionDigits: 5}));   		
			}
			$('#storelocator-error').empty();
		};

		// Define callback function for failed attempt
		var errorCallback = function(error) {
			progress.hide();
			$('.storesFind').prop('disabled', false);
			$('.searchKey').val('');
			if ($('#storelocator-error').children().length == 0) {
				$('#storelocator-error').append('<div class="error">'+Resources.FIS_ERROR_CURRENT_LOCATION+'</div>');        	
			}
		};
	}
    // Add jQuery datepicker to date input and set date default and minimum values
    var prepareAppointmentDate = function() {
    	
    	var today = new Date();
    	var dateOptional = $("#dwfrm_appointment_appointmentDateTextOptional").is(":visible");
    	var disabledDates = $("#disabledDates").data("dates");
    	var currentLocale = document.querySelector("meta[http-equiv='content-language']").getAttribute("content");
    	var dateFormat = 'mm/dd/yy';
    	var monthNames = $("#monthNames").data("monthnames");
    	var daysOfWeek = $("#daysOfWeek").data("daysofweek");
    	
    	if (currentLocale == 'de-DE' || currentLocale == 'it-IT') {
    		dateFormat = 'dd/mm/yy';
    	}
    	
    	$('#dwfrm_appointment_appointmentDateText, #dwfrm_appointment_appointmentDateTextOptional').datepicker({ 
    		dateFormat: dateFormat,
    		minDate: 1,
    		defaultDate: 1,
    		prevText: '',
    		nextText: '',
    		monthNames: monthNames,
    		dayNamesMin: daysOfWeek,
    		beforeShow: function(input, inst) {
    			$('#ui-datepicker-div').addClass('dwfrm_appointment_appointmentDatePicker');
    			setTimeout(function() {
        			$('.ui-icon-circle-triangle-w').addClass('fa fa-angle-left');
        			$('.ui-icon-circle-triangle-e').addClass('fa fa-angle-right');
    			},200);
    		},
            beforeShowDay: function(date) {
            	var dateStr = date.toLocaleDateString('en-US');
            	return [ disabledDates.indexOf(dateStr) == -1, '', '' ];
            },
            onSelect: function(date) {
    			$(this).focus();
            }
    	});
    	
        //If date is not required, the input is hidden so set behind the scenes
        if (dateOptional) {
        	$('#dwfrm_appointment_appointmentDateText').datepicker('setDate', today.getDate() + 1);
        }
        $('#dwfrm_appointment_appointmentDateText, #dwfrm_appointment_appointmentDateTextOptional').prop('readonly', true).removeClass('empty'); // IE workaround - removing empty class after setting value
 
    	$(document).on('click', '.ui-datepicker-next', function () {
			$('.ui-icon-circle-triangle-w').addClass('fa fa-angle-left');
			$('.ui-icon-circle-triangle-e').addClass('fa fa-angle-right');   
    	});

		$(document).on('click', '.ui-datepicker-prev', function () {
			$('.ui-icon-circle-triangle-w').addClass('fa fa-angle-left');
			$('.ui-icon-circle-triangle-e').addClass('fa fa-angle-right');   
		});
    }

	// Move Elements on Store Details Page
	if ($('.store-details-top').length > 0) {
		$(window).on('load resize', function() {
			if ($(window).width() < 768 && $('.store-info-container .store-links').length > 0) {
				$('.store-links, .store-ctas').insertBefore('.store-details-bottom').removeClass('move-mobile');
			} else if ($(window).width() > 768 && $('.store-info-container .store-links').length <= 0) {
				$('.store-links, .store-ctas').appendTo('.store-info-container');
			}
		});
	}
	
	//Launch modal on appointment request click
	$('.store-requestappt, .store-listing-requestappt').on('click', function(e) {
		
		e.preventDefault();
		
		var apptDialogClass = $('#appt-dialog-class').attr('data');
		
		progress.show($('.store-locator'));
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				dialogClass: apptDialogClass,
	            title: Resources.BOOK_AN_APPOINTMENT_TITLE,
	            width: 775,
				open: function(e) {
                    validator.init();
                    $('body').addClass('prevent-scroll');
                    appointment.initializeJRNI();
					if (document.getElementById('timetrade')) {
                        $('#dialog-container').addClass('timetrade');
                    }
				},
		        beforeClose: function () {
		        	$('body').removeClass('prevent-scroll');
		        },
			},
			callback: function (e) {
				progress.hide($('#pdpMain'));
				prepareAppointmentDate();
				$('select[name$="_appointment_appointmentType"]').on('change', function() {
					if ($('select[name$="_appointment_appointmentType"]').val() == 'Returns') {
						$('.return-policy').removeClass('hide');
					} else {
						$('.return-policy').addClass('hide');
					}					
				});
			}
		});

		let searchParams = new URLSearchParams($(this).attr('href').split('?')[1]);
		aep.coreCall('storeLocatorInteraction', 'bapclick', searchParams.get('StoreID'));
	
	});
	
	//Open automatically if BAP search param is present
	if (searchParams) {
	    var bookAppt = searchParams.filter(function(param) {
	        return param.indexOf('bookanappt') != -1;
	    }).length;
	    
	    if (bookAppt > 0) {
	    	$('.store-requestappt').click();
	    }
	}
		
	//Just close modal on cancel
    $(document).on('click', '#apptRequestCancel, #pdpmain-appointment-keepshopping', function (e) {
    	 e.preventDefault();
    	 dialog.close();   	 
	});
   
	
	$('#showMoreBtn').on('click', function(e) {
		
		if (storeLocatorShowAll == false) {
			$('#showMoreBtn').html(showLessMsg);
			storeLocatorShowAll = true;		
		} else {
			$('#showMoreBtn').html(showMoreMsg);
			storeLocatorShowAll = false;				
		}
		
		$('.storediv-mobile-hidden').toggle();
		
	});

	$('#store-listing-listheader, #store-listing-mapheader').on('click', function(e) {
		if ($(this).prop('id') == 'store-listing-mapheader') {
			// Display Map
			$('#store-listing-listheader, #store-listing-mapheader').toggleClass('store-listing-inactiveheader, store-listing-activeheader');
			$('.store-listing-map').removeClass('hide-map');
			$('.store-listing-storediv, .store-locator-subheader:not(.hide-store-heading)').css('display','none');
			$('.store-listing-storediv').first().css('display','block');
			
			outerMarkers.forEach(function(marker) {
				marker.setIcon($('#white-icon').text());
			});
			outerMarkers[0].setIcon($('#blue-icon').text());
			currentMarker = outerMarkers[0];
			
			$('#showMoreBtn').hide();

		} else {
			// Display List
			$('#store-listing-listheader, #store-listing-mapheader').toggleClass('store-listing-inactiveheader, store-listing-activeheader');
			$('.store-listing-map').addClass('hide-map');
			$('.store-listing-storediv, .my-store-block').css('display','block');
			$('.store-locator-subheader:not(.hide-store-heading)').css('display','flex');

			if (!storeLocatorShowAll) {
				$('.storediv-mobile-hidden').css('display','none');
			}

			$('#showMoreBtn').show();
		}
	});

	//On listing page, submit form on radius select change
	$(document).on('change', '#store-listing-header #dwfrm_storelocator .dwfrm_storelocator_maxdistance .maxdistance', function (e) {
		$("#results-submit").trigger('click');
	});
 
    $('input[name$=_storelocator_searchKey]').on('change', function(e) {
        $('input[name=usePlaceDetailsAddress]').val('false');
    });

    $('.see-all').on('click', function(e) {
        if ($('table[id=store-location-results] tbody tr.visually-hidden')) {
            $('table[id=store-location-results] tbody tr.visually-hidden').removeClass('visually-hidden');
        }
        $(this).addClass('visually-hidden');
    });

    $('#store-listing-resultscontainer').on('scroll',function() {
    	var currentScroll;
    	var currentDiv;
		if (preventScroll) {
			preventScroll = false;
			return;
		}
    	
    	currentScroll = $('#store-listing-resultscontainer').scrollTop();
    	
    	$('#store-listing-results').children().each(function( storeIndex, storeElement ) {
    		currentDiv = $(storeElement);

    		if (currentDiv[0].offsetTop >= currentScroll - 40) {
    			
    			currentMarker = outerMarkers[storeIndex];
    			
    			if (currentMarker != oldMarker) {
        			outerMarkers.forEach(function(marker) {
        				marker.setIcon($('#white-icon').text());
        			});
        			outerMarkers[storeIndex].setIcon($('#blue-icon').text());
        			oldMarker = currentMarker;
    			}

    			return false;
    		}

    	});
    });
    
    $('.store-listing-storehoursheader').off('click').on('click', function() {
    	
    	if (!$(this).hasClass('opened'))
		{
        	$(this).addClass('opened');		
		} else {
        	$(this).removeClass('opened');			
		}

    	$(this).parent().find('.store-listing-storehourstable').toggle();
    });

	//Store search
	$('button.storesFind').off('click').on('click', function() {

		var $this = $(this);

		var $searchKey = $this.closest('fieldset').find('input.searchKey');
		var searchRegex = new RegExp('[\/|\\\\]');
		var showPatternError = $searchKey.val() && searchRegex.test($searchKey.val());
		
		if (showPatternError && !$searchKey.siblings('span.error.regex').length) {
			$("<span id='" + $searchKey.attr('name') + "-error'" + " class='error regex'>" + window.Resources.STORE_PATTERN_ERROR + "</span>").insertAfter($searchKey);
			return;
		} else if (showPatternError) {
			$searchKey.siblings('span.error.regex').text(window.Resources.STORE_PATTERN_ERROR);
			$searchKey.siblings('span.error.regex').show();
			return;
		} else {
			$searchKey.siblings('span.error.regex').remove();
		}
		
		if($('.input-text.searchKey').val().length > 0){
			var searchKey = $('.input-text.searchKey').val().toLowerCase();
			var countryname = '';
			var splitContent = '';
			if((searchKey.split(' ')).length > 1){
				for(var i=0;i<(searchKey.split(' ')).length;i++){
					splitContent = (searchKey.split(' '))[i];
					if(i>0){
						countryname = countryname+' ';
					}
					countryname = countryname+ splitContent.charAt(0).toUpperCase() + splitContent.slice(1);
				}
				searchKey = countryname;
			}
			else{
				searchKey = searchKey.charAt(0).toUpperCase() + searchKey.slice(1);
			}

			if($('.store-locator').find('.input-select.country option[label="'+searchKey+'"]').length > 0){
				searchKey = $('.store-locator').find('.input-select.country option[label="'+searchKey+'"]').val();
				$(this).closest('form').find('input[name=countryCode]').val(searchKey);
			}
		}

		$this.closest('form').find('button[type="submit"]').trigger('click');

	});
    
    if (util.isMobile() && $('#dwfrm_storelocator').hasClass('mobilesubheader')) {
    	var formwidth = $('#dwfrm_storelocator').outerWidth() + $('.store-locator-searchstring').outerWidth();
    	var shift = ($('.store-locator-subheaderform').outerWidth() - formwidth) / 2;
    	var shiftstr = (shift - 10) + "px";
    	
    	$('.dwfrm_storelocator_maxdistance').css('left', shiftstr);
    	$('.store-locator-searchstring').css('position', 'relative');    	
    	$('.store-locator-searchstring').css('left', shiftstr);
    }

    function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    //PREVAIL - Added below block for Google maps integration.
    if (isStoreLocatorEnabled && !isFavoriteStore) {
        /* "storeMap" is a global function that will be passed as callback function for google maps API. See below
         * <script src="//maps.googleapis.com/maps/api/js?callback=storeMap" async defer></script> 
         */
        window.storeMap = function() {

            //Create map
            var mapCanvas = document.getElementById('map');

	        var mapOptions = {
	            center: new google.maps.LatLng(37.09024, -95.712891),
				zoom: 15,
				zoomControl: false,
	            gestureHandling: 'none',
	            clickableIcons: false,
	            mapTypeId: google.maps.MapTypeId.ROADMAP,
	            mapTypeControl: false,
	            fullscreenControl: false,
	            streetViewControl: false,
	            styles: [
	                {
	                    "elementType": "geometry",
	                    "stylers": [
	                        {
	                            "color": "#f5f5f5"
	                        }
	                    ]
	                },
	                {
	                    "elementType": "labels.icon",
	                    "stylers": [
	                        {
	                            "visibility": "on"
	                        }
	                    ]
	                },
	                {
	                    "elementType": "labels.text.fill",
	                    "stylers": [
	                        {
	                            "color": "#616161"
	                        }
	                    ]
	                },
	                {
	                    "elementType": "labels.text.stroke",
	                    "stylers": [
	                        {
	                            "color": "#f5f5f5"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "administrative.land_parcel",
	                    "elementType": "labels.text.fill",
	                    "stylers": [
	                        {
	                            "color": "#bdbdbd"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "poi",
	                    "elementType": "geometry",
	                    "stylers": [
	                        {
	                            "color": "#eeeeee"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "poi",
	                    "elementType": "labels.text.fill",
	                    "stylers": [
	                        {
	                            "color": "#757575"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "poi.park",
	                    "elementType": "geometry",
	                    "stylers": [
	                        {
	                            "color": "#e5e5e5"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "poi.park",
	                    "elementType": "labels.text.fill",
	                    "stylers": [
	                        {
	                            "color": "#9e9e9e"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "road",
	                    "elementType": "geometry",
	                    "stylers": [
	                        {
	                            "color": "#ffffff"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "road.arterial",
	                    "elementType": "labels.text.fill",
	                    "stylers": [
	                        {
	                            "color": "#757575"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "road.highway",
	                    "elementType": "geometry",
	                    "stylers": [
	                        {
	                            "color": "#dadada"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "road.highway",
	                    "elementType": "labels",
	                    "stylers": [
	                        {
	                            "saturation": -100
	                        }
	                    ]
	                },
	                {
	                    "featureType": "road.highway",
	                    "elementType": "labels.text.fill",
	                    "stylers": [
	                        {
	                            "saturation": -100
	                        }
	                    ]
	                },
	                {
	                    "featureType": "road.local",
	                    "elementType": "labels.text.fill",
	                    "stylers": [
	                        {
	                            "color": "#9e9e9e"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "transit.line",
	                    "elementType": "geometry",
	                    "stylers": [
	                        {
	                            "color": "#e5e5e5"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "transit.station",
	                    "elementType": "geometry",
	                    "stylers": [
	                        {
	                            "color": "#eeeeee"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "water",
	                    "elementType": "geometry",
	                    "stylers": [
	                        {
	                            "color": "#c9c9c9"
	                        }
	                    ]
	                },
	                {
	                    "featureType": "water",
	                    "elementType": "labels.text.fill",
	                    "stylers": [
	                        {
	                            "color": "#9e9e9e"
	                        }
	                    ]
	                }
	            ]
	        };

            var map = new google.maps.Map(mapCanvas, mapOptions);
            var storeIcon = $('#blue-icon').text();

            var stores = $('.storeJSON').data('storejson'),
                markers = [],
                bounds = new google.maps.LatLngBounds(),
                ctr = 0,
                store, marker, storeCenter, markerContent, mapLink,
                storeName, storeID, storePhone, storAddress1, storeCity, storeStateCode, storePostalCode, storeEmail, storeCountryCode;

            //Create markers and info window and add them to map.
            if (stores.length > 0) {
                for (var index in stores) {
                    store = stores[index];
                    if (!store.latitude || !store.longitude) {
                        continue;
                    }
                    storeCenter = new google.maps.LatLng(store.latitude.toFixed(6), store.longitude.toFixed(6));
                    storeName = b64DecodeUnicode(store.name) ? b64DecodeUnicode(store.name): '';
					storeID = store.id ? store.id : '';
                    storePhone = store.phone ? store.phone : '';
                    storAddress1 = store.address1 ? store.address1 : '';
                    storeCity = store.city ? store.city : '';
                    storeStateCode = store.stateCode ? store.stateCode : '';
                    storePostalCode = store.postalCode ? store.postalCode : '';
                    storeEmail = store.email ? store.email : '';
                    storeCountryCode = store.countryCode ? store.countryCode : '';
                    
                    mapLink = 'http://maps.google.com/maps?hl=en&f=q&q=' +
                        storAddress1 +
                        ',' + storeCity +
                        ',' + storeStateCode +
                        ',' + storePostalCode +
                        ',' + storeCountryCode;
                    
                    markerContent = '<div class="store-infowindow">' +
                        '<h2>' + storeName + '</h2>' +
                        '<p>' + storAddress1 + '<br/>' + storeCity + ',' + storeStateCode + storePostalCode + '</p>' +
                        '<div class="storephone">' + storePhone + '<br/>' + storeEmail + '</div>' +
                        '<div class="storemap"><a class="googlemap" href="' + mapLink + '" target="_blank">View on larger map</a></div>' + '</div>';
                    
                    marker = new google.maps.Marker({
                        position: storeCenter,
                        title: b64DecodeUnicode(store.name),
                        icon: storeIcon,
                        index: ctr,
                        content: markerContent,
						id: 'store-' + storeID + '-div'
                    });

                    bounds.extend(storeCenter);
                    marker.setMap(map);
                    markers.push(marker);
                    
                    storeIcon = $('#white-icon').text();
                    
                    ctr++;
                }

                var infowindow = new google.maps.InfoWindow({
                    content: ''
                });

                outerMarkers = markers;
                currentMarker = markers[0];

                for (var i = 0; i < markers.length; i++) {
                    marker = markers[i];
					google.maps.event.addListener(marker, 'click', function() {
						currentMarker.setIcon($('#white-icon').text());
						this.setIcon($('#blue-icon').text());
						if (util.isMobile()) {
							$('.store-listing-storediv').css('display','none');
							$('#store-listing-results').find('#' + this.id).css('display','block');
						} else {
							var ele = $('#' + this.id);
							var container = ele.closest('#store-listing-resultscontainer');
							if (ele.length && container.length) {
								preventScroll = true;
								container.scrollTop(
									ele.offset().top - container.offset().top + container.scrollTop()
								);
							}
						}
						currentMarker = this;
					});
                }

            }

            map.initialZoom = true;
            map.fitBounds(bounds);
            
            var mobileMapTop = $('.top-banner-sticky').height() + $('#store-listing-header').height() + $('#store-listing-listheader').height() + 20;
 
            var zoomChangeBoundsListener = 
                google.maps.event.addListener(map, 'bounds_changed', function (event) {
                    if (this.getZoom() > 15 && this.initialZoom == true) {
                        // Change max/min zoom here
                        this.setZoom(15);
                        this.initialZoom = false;
                    }
                    google.maps.event.removeListener(window.zoomChangeBoundsListener);
                    if (util.isMobile() && mobileInitialZoom == true && $('#store-listing-mapheader').is(':visible')) {
                        $('.store-locator').css('width','100%');
                        $('.store-locator').css('padding',0);
                        mobileInitialZoom = false;
                    }
                });

            $('.store-click').each(function(i) {
                jQuery(this).on('click', function(e) {
                    e.preventDefault();
                    google.maps.event.trigger(markers[i], 'click');
                });
            });
            
        }

        //Append script here as there is a race condition with callback availability when appending from the template
        //DIGITAL-19913 Added a check to avoid the google maps api warning on console
        if((pageContext.ns == 'storefront' || pageContext.ns == 'storelocator' || pageContext.type == 'myRL-MyAccount-PPLP') && $('#googleApiKey').text() != ''){
        	$.getScript("//maps.googleapis.com/maps/api/js?key=" + $('#googleApiKey').text() + "&callback=storeMap");
        }

        //This function doesn't appear to be getting hit
        window.initAutocomplete = function () {
            var input = $('[id$=_storelocator_searchKey]').get(0);
            var options = {types: ['establishment']};
            var autocomplete = new google.maps.places.Autocomplete(input, options);
            autocomplete.setFields(['address_components', 'geometry']);
            autocomplete.addListener('place_changed', function() {
                var place = autocomplete.getPlace();
                var address = '';
                if (place.address_components) {
                    for (var i = 0; i < place.address_components.length; i++) {
                        var addressComponent = place.address_components[i];
                        var $usePlaceDetailsAddress = $('input[name=usePlaceDetailsAddress]');
                        if (addressComponent.types && addressComponent.types.indexOf("postal_code") > -1) {
                            $('input[name=postalCode]').val(addressComponent.short_name);
                            $usePlaceDetailsAddress.val('true');
                        }
                        if (addressComponent.types && addressComponent.types.indexOf("country") > -1) {
                            $('input[name=countryCode]').val(addressComponent.short_name);
                            $usePlaceDetailsAddress.val('true');
                        }
                    }
                }
            });
        };
    }

    //Code for Appointment Modal
    window.apptRequest = function (e) {

        e.preventDefault();
        
        var preference;
        
        if ($("input[id='store-appointment-phone']:checked").val() == 'on') {
        	preference = 'Phone';
        } else {
        	preference = "Email";
        }
        
    	var dateOptional = $("#dwfrm_appointment_appointmentDateTextOptional").is(":visible");
    	var timeOptional = $("#dwfrm_appointment_appointmentTimeOptional").is(":visible");

        var firstName = $('#dwfrm_appointment_firstName').val();
        var lastName = $('#dwfrm_appointment_lastName').val();
        var email = $('#dwfrm_appointment_email').val();
        var phone = $('#dwfrm_appointment_phone').val();
        var postalCode = $('#dwfrm_appointment_postalCode').val();
        var appointmentDate = dateOptional ? $('#dwfrm_appointment_appointmentDateTextOptional').val() : $('#dwfrm_appointment_appointmentDateText').val();
        var appointmentTime = timeOptional ? $('#dwfrm_appointment_appointmentTimeOptional option:selected').text() : $('#dwfrm_appointment_appointmentTime option:selected').text();
        var location = $('#dwfrm_appointment_location option:selected').text();
        var department = $('#dwfrm_appointment_departmentType option:selected').text();
        var appointmentType =$('#dwfrm_appointment_appointmentType option:selected').text();
        var comment = $('#dwfrm_appointment_comment').val();
        var storeName = $('#dwfrm_appointment_storeName').val();
        var storeAddress1 = $('#dwfrm_appointment_storeAddress1').val();
        var storeAddress2 = $('#dwfrm_appointment_storeAddress2').val();
        var storePostalCode = $('#dwfrm_appointment_storePostalCode').val();
        var storeCity = $('#dwfrm_appointment_storeCity').val();
        var storeState = $('#dwfrm_appointment_storeState').val();
        var storePhone = $('#dwfrm_appointment_storePhone').val();
        var storeEmail = $('#dwfrm_appointment_storeEmail').val();
        var storeEmailCC = $('#dwfrm_appointment_storeEmailCC').val();
        var location = $('#dwfrm_appointment_location').val();
        var storeId = $('#storeId').val();
        var storeCountryCode = $('#countryCode').val();

        var modalForm = $('#AppointmentForm');     
        modalForm.validate();
        
        if ($('#dwfrm_appointment_postalCode').length == 0) {
        	postalCode = '';
        }
        
        if ($('#dwfrm_appointment_location').length == 0 || $('#dwfrm_appointment_location')[0].selectedIndex == 0) {
        	location = '';
        }
        
        if ($('#dwfrm_appointment_departmentType').length == 0 || $('#dwfrm_appointment_departmentType')[0].selectedIndex == 0) {
        	department = '';
        }
        
        if ($('#dwfrm_appointment_appointmentType').length == 0 || $('#dwfrm_appointment_appointmentType')[0].selectedIndex == 0) {
        	appointmentType = '';
        }
        
        if (timeOptional && $('#dwfrm_appointment_appointmentTimeOptional')[0].selectedIndex == 0) {
        	appointmentTime = '';
        }
        
        if (modalForm.valid()) {
            $.ajax({
                type: 'POST',
                url: util.appendParamsToUrl(Urls.storeAppointmentAjax, {
                	firstName: firstName,
                	lastName: lastName,
                	email: email,
                	phone: phone,
                	postalCode: postalCode,
                	preference: preference,
                	appointmentDate: appointmentDate,
                	appointmentTime: appointmentTime,
                	location: location,
                	department: department,
                	appointmentType: appointmentType,
                	comment: comment,
                	storeName: storeName,
                	storeAddress1: storeAddress1,
                	storeAddress2: storeAddress2,
                	storePostalCode: storePostalCode,
                	storeCity: storeCity,
                	storeState: storeState,
                	storePhone: storePhone,
                	storeEmail: storeEmail,
                	storeEmailCC: storeEmailCC,
                	masterProductID: null,
                	sku: null,
                	colorName: null,
                	primarySize: null,
                	secondarySize: null,
                	storeId: storeId,
                	storeCountryCode: storeCountryCode
                }),
                success: function(data) {

                	if (data.success == true) {
                        $('#AppointmentForm').hide();
                        $('.pdp-appointment-header').hide();
                        $('.confirmation-message').show();
                        $('.pdp-appointment').css('position', 'fixed');
                        $('.pdp-appointment').css('top', '200px');
                	} else {
                		//Submission error message here
                	}
                	$('div.store-location-appointment').on('dialogclose', function(event) {
                		$('div.store-location-appointment').css('position','absolute');
                	});
                	
                }
            });
        }
    }

    window.apptCancel = function (e) {
    	e.preventDefault();
    	dialog.close();
    }
    
};