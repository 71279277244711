'use strict';

var util = require('../util'),
    producttile = require('../product-tile');

function displayMoodBanner() {
    var displayBannerTl = gsap.timeline();
    displayBannerTl
        .from(
            '.mood-background', {
                duration: 1,
                opacity: 0,
                onStart: function() {
                    $('.mood-background').css('visibility', 'visible');
                },
                onComplete: function() {
                    $('.mood-background').removeClass('zoom-in');
                    setTimeout(function() {
                        $('.mood-banner-content h1').removeClass('hide-title');
                    }, 750);
                }
            }
        )
        .to(
            ['.loading-bar-container',
            '.mood-banner-content h2'],
                {
                    opacity: 1,
                    duration: 1,
                    delay: 2.5,
                    onComplete: function() {
                        $('.loading-bar').animate({
                            width: '100%'
                        }, 3000, function() {
                            $('.loading-bar-container').css('visibility', 'hidden');
                            hideMoodBanner();
                        });
                    }
                }
        );
}

function hideMoodBanner() {
    var moodHeight = 462;
    if ($(window).width() < 960) {
        var moodHeight = 400;
    }
    $('.loading-bar-container').css('display', 'none');
    $('.mood-btns').removeClass('hide');
    var hideBannerTl = gsap.timeline();
    hideBannerTl
        .fromTo(
            '.mood-btns', {
                opacity: 0,
                y: -50
            },
            {
                opacity: 1,
                y: 0,
                duration: 1
            }
        )
        .to(
            ['.mood-banner-content','.mood-background'], {
                duration: 1,
                height: moodHeight,
                ease: 'power2.out'
            }, '-=2'
        )
        .from(
            '.guided-shopping-results', {
                duration: 2,
                y: '500vh',
                opacity: 0,
                ease: 'power2.out',
                onStart: function() {
                    $('.guided-shopping-results').css('visibility', 'visible');
                }
            }, '-=2'
        );
}

var guidedShopping = {
    init: function() {
        // Init PLP
        if ($('.guided-shopping-results').length > 0) {
            displayMoodBanner();
            // wishlist.init();
            require.ensure([], function (require) {
                var chunk = require('./wishlist');
                if (chunk.init) {
                    chunk.init();
                }
            }, function (error) {
                console.log('error!', error);
            }, 'wishlist');
            producttile.init();
        } else if ($('.guided-shopping-quiz').length > 0) {
            var updateUrl = util.removeParamFromURL(window.location.href, 'retake');
            window.history.pushState({ path: updateUrl }, '', updateUrl);
        }
    }
};

module.exports = guidedShopping;