'use strict';

var dropDownBtn = $('.has-drop-down');
var dropDownMenu = $('#drop-down');

module.exports = function () {
    $(dropDownBtn).on('click',function (e) {
        e.preventDefault();
        dropDownBtn.toggleClass('active');
        dropDownBtn.siblings(dropDownMenu).toggleClass('active');
    });

    $(document).on('click',function (e) {
        var hasDDClass = $(e.target).hasClass('has-drop-down');
        var hasDDSpan = $(e.target).is('span');

        if (hasDDClass !== true && hasDDSpan !== true) {
            dropDownBtn.removeClass('active');
            dropDownBtn.siblings(dropDownMenu).removeClass('active');
        }
    });
}
