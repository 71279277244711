'use strict';

var dialog = require('./dialog'),
    util = require('./util'),
    _ = require('lodash'),
    accordion = require('./accordion'),
    aep = require('./aepdatacollection-util');

var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, 'source', source);
    }
    if (productListID) {
        url = util.appendParamToURL(url, 'productlistid', productListID);
    }
    return url;
};

var removeParam = function (url) {
    if (url.indexOf('?') !== -1) {
        return url.substring(0, url.indexOf('?'));
    } else {
        return url;
    }
};

var quickview = {
    init: function () {
        if (!this.exists()) {
            this.$container = $('<div/>').attr('id', 'refresh-QVDialog').appendTo(document.body);
        }
        this.productLinks = $('#search-result-items .thumb-link').map(function (index, thumbLink) {
            return $(thumbLink).attr('href');
        });
    },

    setup: function (qvUrl) {
        var $btnNext = $('.quickview-next'),
            $btnPrev = $('.quickview-prev');

        // product.initializeEvents();

        require.ensure([], function (require) {
            var chunk = require('./pages/productRefresh');
            if (chunk.initializeEvents) {
                chunk.initializeEvents();
            }
        }, function (error) {
            console.log('error!', error);
        }, 'productRefresh');

        this.productLinkIndex = _(this.productLinks).findIndex(function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });

        // hide the buttons on the compare page or when there are no other products
        if (this.productLinks.length <= 1 || $('.compareremovecell').length > 0) {
            $btnNext.hide();
            $btnPrev.hide();
            return;
        } else {
            /*  Start JIRA PREV-50: Next and Previous links will not be displayed on PDP if user navigate from Quick View.
              Added current URL parameters and index to viewfulldetails link
            */
            var a = $('#view-full-details');
            var wl = window.location;
            var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
            var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};
            var params = $.extend(hashParams, qsParams);
            params.start = parseInt(this.productLinkIndex, 10) + 1;
            var tile = $('#search-result-items .product-tile').first();
            if (!params.cgid && tile.data('cgid') !== null && tile.data('cgid') !== '') {
                params.cgid = tile.data('cgid');
            }
            a.attr('href', a.attr('href') + '#' + $.param(params));
            /*End JIRA PREV-50*/
        }

        if (this.productLinkIndex === this.productLinks.length - 1) {
            $btnNext.prop('disabled', true);
        }
        if (this.productLinkIndex === 0) {
            $btnPrev.prop('disabled', true);
        }

        $btnNext.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(1);
        }.bind(this));
        $btnPrev.on('click', function (e) {
            e.preventDefault();
            this.navigateQuickview(-1);
        }.bind(this));
    },

    /**
     * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
     */
    navigateQuickview: function (step) {
        // default step to 0
        this.productLinkIndex += (step ? step : 0);
        var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
        dialog.replace({
            url: url,
            callback: this.setup.bind(this, url)
        });
    },

    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url;
        if (!this.exists()) {
            this.init();
        }
        var source = options.source;
        url = makeUrl(options.url, source == 'quickshop' ? 'quickview' : source, options.productlistid);
        var position=options.position;
        dialog.open({
            target: this.$container,
            
            url: url,
            options: {
                width: 'auto',
                title: Resources.QUICK_VIEW_POPUP,
                dialogClass: 'df-refresh-dialog ' + (source ? 'dialog-source-' + source : ''),
                open: function () {
                    this.setup(url);
                    $('body').css('overflow','hidden');
                    if (typeof options.callback === 'function') {
                        options.callback();
                    }
                    if ((source=='quickview' || source=='quickshop') && SitePreferences.ANALYTICS_ENABLED != undefined && SitePreferences.ANALYTICS_ENABLED) {
                        aep.coreCall('quickViewOpen', position);
                    }
                    accordion.init();
                }.bind(this),
                close:function (){
                	$('body').css('overflow','auto');
                }
            }
        });
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

module.exports = quickview;
