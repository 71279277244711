'use strict';

var errorIcon = {

    init: function () {
        var $this = this;
        var inputWrapper = $('.input-wrapper');

        if (inputWrapper.next('.error-form').length > 0) {
            $this.addErrorAlertIcon(inputWrapper);
        }

    },

    /**
     * Adds small red alert icon to input field
     *
     * @param selector $(this) Element being interacted with by user.
     */
    addErrorAlertIcon: function (inputWrapper) {
        var toolTip = $('<div class="error-field-tooltip"><span class="alert-icon"></span></div>');

        if (inputWrapper.next('.error-form').length > 0) {
            $('.error-form').prev('.input-wrapper').children('.field-wrapper').after(toolTip);
        }
    }
};

module.exports = errorIcon;
