'use strict';
var util = require('./util');

const injectSchemas = function (schemaArrayObject, pid) {
    var schemaURL = Urls.productSchemaUrl;
    var cgid = util.getQueryVariable(window.location.href,'cgid');
    schemaURL = util.appendParamsToUrl(schemaURL, {
        'pid': pid,
        'cgid': cgid,
        'schemaArrayObject': schemaArrayObject
    });
    fetch(schemaURL)
        .then(response => response.json())
        .then(response => {
            response.schemas.forEach(function (schemaObject) {
                if (schemaObject) {
                    const script = document.createElement('script');
                    script.setAttribute('type', 'application/ld+json');
                    script.textContent = schemaObject;
                    document.head.appendChild(script);
                }
            });
        });
};

var schemas = {
    // This will fetch product schema's from server and append it in html head
    generateProductDetailSchemas: function () {
        var schemas = ['videobject', 'breadcrumblist', 'productgroup'];
        var pdpSchemaObjectsDiv = document.querySelector('#pdp-schema-objects');
        var pid = $('.pt_product-details').attr('data-schema-pid');
        var schemaArrayObject = [];
        if (pdpSchemaObjectsDiv) {
            for (var i = 0; i < schemas.length; i++) {
                var schemaContent = pdpSchemaObjectsDiv.getAttribute('schema-' + schemas[i]);
                if (schemaContent) {
                    var script = document.createElement('script');
                    script.setAttribute('type', 'application/ld+json');
                    script.textContent = schemaContent;
                    document.head.appendChild(script);
                } else {
                    schemaArrayObject.push(schemas[i]);
                }
            }
        } else {
            schemaArrayObject = schemas;
        }

        if (pid && schemaArrayObject.length > 0) {
            injectSchemas(schemaArrayObject, pid);
        }
    }
};
module.exports = schemas;