'use strict';

function Suggestion($input) {
    var self = this;
    var placeholder = '';
    var suggestion = '';
    var $hint;
    var $refinementsPill;
    var refinementsPillSelectedVal = '';

    if (SitePreferences.MONETATE_SEARCH_WITHIN_DEPARTMENT) {
        refinementsPillSelectedVal = $('.js-search-refinements-pill').find('li.selected a').first().text().trim();
    }

    (function init() {
        placeholder = $input.attr('placeholder');

        $hint = $input.clone()
            .addClass('hint js-hint')
            .attr('id', '')
            .attr('name', '')
            .attr('placeholder', '')
            .attr('aria-hidden', 'false') // False causes the element to be visually hidden by CSS.
            .attr('tabindex', '-1') // Remove from keyboard traversal
            .css('position', 'absolute')
            .css('left', 0)
            .css('min-width', '100%') // Fill the wrapper element.
            .val(placeholder);

        $input.css('position', 'relative')
            .css('vertical-align', 'top')
            .css('background-color', 'transparent')
            .attr('placeholder', '')
            .on('keyup', update)
            .on('focus', update)
            .on('blur', update);

        var $wrapper = $('<span class="wrappper">')
            .css('position', 'relative');

        $input.parent().append($wrapper.append($hint).append($input));
        $refinementsPill = $('.js-search-refinements-pill');
    })();

    this.suggest = function(s) {
        suggestion = s;

        if (isCompletion(suggestion)) {
            suggestion = matchCase(suggestion);

            refinementsPillSelectedVal = $refinementsPill.find('li.selected a').first().text().trim(); // get update text

            if (SitePreferences.MONETATE_SEARCH_WITHIN_DEPARTMENT) {
                if (refinementsPillSelectedVal !== Resources.SEARCH_ALL) {
                    $hint.val(Resources.SEARCH_IN + ' ' + refinementsPillSelectedVal);
                } else {
                    $hint.val(suggestion);
                }
            } else {
                $hint.val(suggestion);
            }
        } else {
            self.clear();
        }
    };

    this.hasSuggestion = function() {
        return $hint.val() != '';
    };

    this.getSuggestion = function() {
        return suggestion;
    };

    this.clear = function() {
        refinementsPillSelectedVal = $refinementsPill.find('li.selected a').first().text().trim(); // get update text

        if (SitePreferences.MONETATE_SEARCH_WITHIN_DEPARTMENT) {
            if ($input.val() === '' && refinementsPillSelectedVal !== Resources.SEARCH_ALL) {
                $hint.val(Resources.SEARCH_IN + ' ' + refinementsPillSelectedVal);
            } else {
                $hint.val('');
            }
        } else {
            $hint.val('');
        }
    };

    function isCompletion(suggestion) {
        var userInput = $input.val().toLowerCase();
        var comp = suggestion.toLowerCase().startsWith(userInput);
        return comp;
    }

    function matchCase(suggestion) {
        var userInput = $input.val();
        var s = userInput + suggestion.substring(userInput.length);
        return s;
    }

    function isInputEmpty() {
        var input = $input.val();
        var isEmpty = input == '' || input == placeholder;
        return isEmpty;
    }

    function update() {
        if (isInputEmpty()) {
            var rootLevelCat = ($('.js-rootLevelCat').val().length > 0) ? $('.js-rootLevelCat').val() : null;

            refinementsPillSelectedVal = $refinementsPill.find('li.selected a').first().text().trim(); // get update text

            if (SitePreferences.MONETATE_SEARCH_WITHIN_DEPARTMENT) {
                if (refinementsPillSelectedVal !== Resources.SEARCH_ALL) {
                    $hint.val(Resources.SEARCH_IN + ' ' + refinementsPillSelectedVal);

                    if (rootLevelCat && (rootLevelCat !== null && rootLevelCat !== 'null')) {
                        $hint.val(Resources.SEARCH_IN + ' ' + rootLevelCat);
                    } else if ((rootLevelCat === null || rootLevelCat === 'null') && refinementsPillSelectedVal.length === 0) {
                        $hint.val(placeholder);
                    }
                } else {
                    $hint.val(placeholder);
                }
            } else {
                $hint.val(placeholder);
            }
        } else if (isCompletion(suggestion)) {
            self.suggest(suggestion);
        } else {
            self.clear();
        }
    }
}

module.exports = Suggestion;