'use strict';
var districtList;
var addressList = JSON.parse(SitePreferences.ADDRESS_TAIWAN);

var cityIdentify = $('body').find('[name$="_city"]').attr('name');
var stateIdentify = $('body').find('[name$="_states_state"]').attr('name');
var cityTW_HTML;
var cityNormalCountryInput;
var stateTW_HTML;

function getInputHTML () {
    cityTW_HTML = '<select ' +
    'class="input-select city latincheck specialcharcheck countryValid emptycheck required" ' +
    'id="' + cityIdentify + '" ' +
    'aria-label="'+ window.Resources.LABEL_CITY +'" ' +
    'name="' + cityIdentify + '" ' +
    'minlength="0" ' +
    'maxlength="2147483647" ' +
    'value="" ' +
    'htmlvalue="" ' +
    'aria-describedby="' + cityIdentify + '-error" ' +
    'aria-invalid="true">';

    cityTW_HTML += '<option class="select-option" label="'+ window.Resources.SELECT_CITY +'" value="">'+ window.Resources.SELECT_CITY +'</option>';

    Object.keys(addressList).map(function (i) {
        cityTW_HTML += '<option class="select-option" label="' + addressList[i].name + '" value="' + addressList[i].name + '">' + addressList[i].name + '</option>';
    });

    cityTW_HTML += '</select>';

    cityNormalCountryInput = '<input class="input-text city latincheck specialcharcheck countryValid emptycheck required valid" aria-describedby="' + cityIdentify + '-caption" placeholder="City" aria-required="true" type="text" id="' + cityIdentify + '" name="' + cityIdentify + '" value="" minlength="2" maxlength="35" htmlvalue="" aria-invalid="false">'
    stateTW_HTML = '<select class="input-select countryValid emptycheck required" id="' + stateIdentify + '" aria-label="行政區" name="' + stateIdentify + '" minlength="0" maxlength="2147483647" value="" htmlvalue="" autocomplete="address-level1" aria-invalid="false"></select>';
}

function loadDistrict(cityId) {
    var selectDistrict = window.Resources.SELECT_DISTRICT;
    var data = '<option class="select-option" value="">' + selectDistrict + '</option>';
    for (var i = 0; i < addressList.length; i++) {
        if (addressList[i].name.trim() === cityId) {
            districtList = addressList[i].children;
            break;
        }
    }
    if (districtList) {
        for (var i = 0; i < districtList.length; i++) {
            var name = districtList[i].name.trim();
            var value = districtList[i].name.trim();
            data += '<option class="select-option" value="' + value + '">' + name + '</option>';
        }
    }
    $('[name$="_states_state"] option').remove();
    $('[name$="_states_state"]').append(data);

    if ($('body').find('select[name$="_city"]').hasClass('error')) {
        $('body').find('select[name$="_city"]').trigger('click');
    }
}

function loadPostal(cityId, districtId) {
    var postal = '';
    for (var i = 0; i < addressList.length; i++) {
        if (addressList[i].name.trim() === cityId) {
            districtList = addressList[i].children;
            break;
        }
    }
    if (districtList) {
        for (var i = 0; i < districtList.length; i++) {
            if (districtList[i].name.trim() === districtId) {
                postal = districtList[i].postal;
                break;
            }
        }
    }
    $('input.postal').val(postal);
    if ($('input.postal').hasClass('error')) {
        $('input.postal').next('span.error').remove();
        $('input.postal').removeClass('error')
    }

    if ($('body').find('select[name$="_state"]').hasClass('error')) {
        $('body').find('select[name$="_state"]').trigger('click');
    }
}

function initAddressEvents() {
    if ($('.checkout-shipping').length > 0 || $('.checkout-billing').length > 0 || $('.returns-pick-up-page').length > 0) {
        $('[name$="_addressFields_city"]').off('change').on('change', function () {
            var cityId = $(this).val();
            if (cityId !== window.Resources.SELECT_CITY) {
                loadDistrict(cityId);
                var districtId = $('[name$="_states_state"]').val();
                loadPostal(cityId, districtId);
            }
        });

        $('[name$="_addressFields_states_state"]').off('change').on('change', function () {
            var addressForm = $(this).closest('form');
            var cityId = addressForm.find('[name$="_addressFields_city"]').val();
            var districtId = addressForm.find('[name$="_states_state"]').val();
            if (districtId !== window.Resources.SELECT_DISTRICT) {
                loadPostal(cityId, districtId);
            }
        });
    } else {
        $('body').off('change', 'select.city').on('change', 'select.city', function () {
            var cityId = $(this).val();
            if (cityId !== window.Resources.SELECT_CITY) {
                loadDistrict(cityId);
                var districtId = $('select[name$="_state"]').val();
                loadPostal(cityId, districtId);
            }
        });

        $('body').off('change', 'select[name$="_state"]').on('change', 'select[name$="_state"]', function () {
            var addressForm = $(this).closest('form');
            var cityId = addressForm.find('select.city').val();
            var districtId = addressForm.find('select[name$="_state"]').val();
            if (districtId !== window.Resources.SELECT_DISTRICT) {
                loadPostal(cityId, districtId);
            }
        });
    }

}

function handleCountryChange () {
    var $country = $('body').find('select[id$="_country"]');
    var countryVal = $country.val();

    if (countryVal === 'TW') {
        var cityVal = $('body').find('select[id$="_city"]').val();
        var stateVal = $('body').find('select[id$="_states_state"]').val();
        $('body').find('[id$="_city"]')[0].outerHTML = cityTW_HTML;
        $('body').find('[id$="_states_state"]')[0].outerHTML = stateTW_HTML;
        $('body').find('select[name$="_state"]').closest('.form-row').addClass('required').find('label').first().html('<span class="required-indicator"> * </span> ' + window.Resources.SELECT_DISTRICT);

        initAddressEvents();

        if (cityVal && stateVal) {
            $('body').find('[id$="_city"]').val(cityVal).trigger('change').trigger('click');
            $('body').find('[id$="_states_state"]').val(stateVal).trigger('change').trigger('click');
        }
    } else {
        $('body').find('[id$="_city"]')[0].outerHTML = cityNormalCountryInput;
    }

    $('body').find('[id$="_city"]').closest('.field-wrapper').find('.valid-icon').remove();
    $('#' + cityIdentify + '-error').remove();
}

module.exports = function () {
    getInputHTML();
    initAddressEvents();

    $('.checkout-billing').find('select.country').on('change', function (e) {
        handleCountryChange();
        var $target = $(e.target);
        if ($target.val() === 'TW') {
            $('body').find('[id$="_states_state"]').removeClass('state');
        } else {
            $('body').find('[id$="_states_state"]').addClass('state');
        }
    });

    if (($('.checkout-shipping').length > 0 || $('#addresses').length > 0)) {
        $('body').on('change', 'select.country', function () {
            handleCountryChange();
        });
    }

    $(document).on('checkout:afterClickEditAddress', function (e, data) {
        var address = data.address;
        $('[name$="_addressFields_city"]').val(address.city).trigger('change');
        $('[name$=_addressFields_states_state]').val(address.stateCode).trigger('change');
    });

    $(document).on('accountAddress:initCityAndStateIdentify', function (e, data) {
        cityIdentify = $('body').find('[name$="_city"]').attr('name');
        stateIdentify = $('body').find('[name$="_states_state"]').attr('name');
        getInputHTML();

        $('select.country').trigger('change');

        if (!$('body').find('[name$="_city"]').val() || !$('body').find('[name$="_city"]').val()) {
            var cityVal = data.cityVal;
            var stateCodeVal = data.stateCodeVal;

            $('body').find('[name$="_city"]').val(cityVal);
            $('body').find('[name$="_states_state"]').val(stateCodeVal);
        }
    });

    $('.show-add-address').on('click', function () {
        $('#dwfrm_singleshipping_shippingAddress_addressFields_city').val('');
    });
};
