'use strict';

var $cache = {};
var ajax = require('./ajax'),
    util = require('./util');

function initializeCache() {
    $cache.main = $('#main');
    $cache.bisnButton = $('.backInStockNotification .bisnButton');
}

function initializeEvents() {
    $cache.bisnButton.on('click', function (e) {
        e.preventDefault();
        var $this = $(this).closest('form');
        var invalidEmail = Resources.EMAILNOTIFICATIONFAIL;
        var pid = $this.find('#pid').val();
        var email = $this.find('.bisnemail').val();
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(email) === false || email === null) {
            $this.find('#bisnemailnstatus').text('');
            $this.find('.email').addClass('error');
            $this.find('.error').text(invalidEmail);
        } else {
            $this.find('.email').removeClass('error');
            var url = util.appendParamsToUrl(Urls.emailNotification, {
                bisnpid: pid,
                bisnemail: email,
                format: 'ajax'
            });
            ajax.getJson({
                url: url,
                callback: function (data) {
                    if (data.success) {
                        $this.find('.bisnemail').removeClass('error');
                        $this.find('#bisnemailnstatus').text('');
                        $this.find('#bisnemailnstatus').text(Resources.EMAILNOTIFICATIONSUCCESS).css('color', 'BLUE');
                    } else {
                        $this.find('#bisnemailnstatus').text(Resources.EMAILNOTIFICATIONFAIL).css('color', 'RED');
                    }
                }
            });
        }

    });
    $('.bisnemail').on('keypress', function () {
        var $this = $(this).closest('form');
        $this.find('#bisnemailnstatus').text('');
    });
}


exports.init = function() {
    initializeCache();
    initializeEvents();
}
