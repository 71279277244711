'use strict';
require('@apac/js/app');
var efapiao = require('./efapiao');
var validator = require('./validator');
var billing = require('./billing');
var address = require('./address');
var wishlist = require('./pages/wishlist');
var validatorAU = require('../../../app_rlau_core/cartridge/js/validator');
var eform = require("./eform");

jQuery(function () {
    efapiao();
    validator();
    billing();
    address();
    wishlist();
    eform();
    validatorAU();
});
