'use strict';

const _debounce = require('lodash/debounce');
const util = require('../util');
const { reveal } = require('../animationsUtils');
const DEFAULT_GRID_BREAK = 'default';

const productTile = require('../product-tile');

let productTileHeightListener = false;
let productTileHeight = 200;

function watchNewRlcBlocks() {
    try {
        window._rlc.inView(); // adds all .rlc-block elements to the "inView" watcher
        window._rlc.lazyLoader(); // adds "inView" listener to lazy load images
    } catch (error) {
        // continue regardless of error
    }
}

function emptyProduct() {
    $('ul.search-result-items li.plp-custom-image-section').each(function () {
        var $this = $(this);

        if ($this.children().length === 0) {
            $this.remove();
        }
    });
}

function filterControlSwitch() {
    var filterLabelHide = $('.filter-control .filter-hide');
    var filterLabelShow = $('.filter-control .filter-show');
    var secondaryNav = $('#secondary');

    if (secondaryNav.hasClass('hide')) {
        filterLabelHide.hide();
        filterLabelShow.show();
    } else {
        filterLabelHide.show();
        filterLabelShow.hide();
    }
}

//Expand the first refinement if mobile. Remember to hide the collapsed lists as well so animation works on the first click.
function resetRefinement() {
    var $refinementBar = $('#refinments-bar');
    var $filterElement = $('.left-search-options');

    if ($refinementBar.length > 0 && $filterElement.length > 0) {
        var $sectionHeading = $('.secondary-refinements .section-heading');

        $sectionHeading.removeClass('clicked');
        $sectionHeading.removeClass('clicked-no-animation');
        $sectionHeading.addClass('closed');
        $sectionHeading.parent().find('ul').css('display','none');
    }
}

function priceLabelPosition(create) {
    var min = $('.ui-slider-handle').first().css('left');
    var minWidth = ($('.min-price-lbl').width() || $('.min-price-lbl').text().length * 9) + 10;
    var max = $('.ui-slider-handle').last().css('left');
    var space = 0;

    if (typeof max === 'undefined') {
        return false;
    }

    // convert percentage values into px
    if (max.indexOf('%') !== -1) {
        var slidereWidth = $('.price-refinments').width() - 40;

        if (!$('.price-refinments').width() && !$('#secondary').is(':visible')) {
            slidereWidth = $('.price-refinments').width() - 32;
        }

        min = Number(min.replace('%', '')) * slidereWidth / 100;
        max = Number(max.replace('%', '')) * slidereWidth / 100;
        space = max - min;
    } else {
        min = Number(min.replace('px', ''));
        max = Number(max.replace('px', ''));
        space = max - min;
    }

    // check if the two labels are too close to each other
    if (space > minWidth) {
        $('.min-price-lbl').css({'left': min + 'px'});
        $('.max-price-lbl').css({'left': max + 'px'});
    } else if (create) {
        if (space < minWidth) {
            min -= minWidth;
        }

        $('.min-price-lbl').css({'left': min + 'px'});
        $('.max-price-lbl').css({'left': max + 'px'});
    }

    // Handling the negative scenario when the min and max values gives negative
    if (min < 0 || max < 0) {
        $('.min-price-lbl').css({'left': '0'});
        $('.max-price-lbl').css({'left': '100%'});
    }
}

function findTileInList(url, $pid) {
    // this code is NEVER executed as the calling line is never reached in above updateProductWindowback
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');
    var lastPlpScrollPosition = history && history.state  && 'plpScrollPosition' in history.state ? history.state.plpScrollPosition : null;
    var productImgHeight = sessionStorage['plp-product-img-height'];

    if (typeof sessionStorage['scroll-cache_' + gridUrl] === 'undefined' || loadingPlaceHolder.length === 0) { return false; }

    loadingPlaceHolder.removeClass('infinite-scroll-loading');
    loadingPlaceHolder.attr('data-loading-state', 'loaded');

    $('#primary div.search-result-content').append(sessionStorage['scroll-cache_' + gridUrl]);

    document.dispatchEvent(new Event('productTilesUpdated'));

    $('.product-tile .product-image').css('min-height', productImgHeight); // giving height from session value, Now it will consider this new height if product images not loaded also

    $('html, body').animate({
        scrollTop: lastPlpScrollPosition // It will scroll to exact product row
    }, 100);
}

//back-button PDPtoPLP
function storePlpScrollPosition($target, windowScrollTop) {
    // var a = $(this);

    // get current page refinement values
    var wl = window.location;

    var qsParams = (wl.search.length > 1) ? util.getQueryStringParams(wl.search.substr(1)) : {};
    var hashParams = (wl.hash.length > 1) ? util.getQueryStringParams(wl.hash.substr(1)) : {};

    // merge hash params with querystring params
    var params = $.extend(hashParams, qsParams);

    if (!params.start) {
        params.start = 0;
    }

    var $tile = $target.closest('.product-tile');

    // get the index of the selected item and save as start parameter
    //var tile = $target.closest('.product-tile');
    var idx = $tile.data('idx') ? +$tile.data('idx') : 0;

    /*Start JIRA PREV-50 : Next and Previous links will not be displayed on PDP if user navigate from Quick View.Added cgid to hash*/
    if (!params.cgid && $tile.data('cgid') !== null && $tile.data('cgid') !== '') {
        params.cgid = $tile.data('cgid');
    }
    /*End JIRA PREV-50*/

    /*Start JIRA PREV-50 : Next and Previous links will not be displayed on PDP if user navigate from Quick View.Added cgid to hash*/
    if (!params.cgid && tile.data('cgid') !== null && tile.data('cgid') !== '') {
        params.cgid = tile.data('cgid');
    }
    /*End JIRA PREV-50*/

    // convert params.start to integer and add index
    params.start = (+params.start) + (idx + 1);

    // set the hash and allow normal action to continue
    $target[0].hash = $.param(params);

    // save scroll position
    const historyStateData = {
        plpScrollPosition: windowScrollTop || $(window).scrollTop()
    };
    
        
    if ($tile.length && $tile.attr('id')) {
        historyStateData.pid = $tile.attr('id');
        
    }

    if (history.scrollRestoration && SitePreferences.LISTING_INFINITE_SCROLL) {
        history.scrollRestoration = 'manual';
        const $breakContainer = $tile.closest('.js-paginated-container');
        historyStateData.break = $breakContainer.data('container-uuid');
        if ($breakContainer.data('view-all')) {
            historyStateData.viewAllActive = $breakContainer.data('container-uuid');
        }
    }

    history.replaceState(historyStateData, '', window.location.href);

    sessionStorage.setItem('lastvisitedpage', $('.infinite-scroll-placeholder').first().data('grid-url'));
    sessionStorage.setItem('lastvisitedproduct', $target.closest('.product-tile').data('itemid'));
    sessionStorage.setItem('plp-product-img-height', $('.product-tile .product-image').height());
}

function initBackButtonforQS(e) {
    var scrollTop;
    var $target = $(e.target);
    function storePLPForBackButton() {
        storePlpScrollPosition($target, scrollTop);
    }
    function closeOrNavigateFromQS() {
        document.removeEventListener('onFullDetailsClick', storePLPForBackButton);
    }
    document.addEventListener('toaster:opened', function (e) {
        scrollTop = e.detail.marginTop;
    }, {
        once: true
    });
    document.addEventListener('toaster:close', closeOrNavigateFromQS, {
        once: true
    });
    document.addEventListener('onFullDetailsClick', storePLPForBackButton);
}

function initQS() {
    $('.js-qs-cta:not(.bb-init)').on('click', function (e) {
        initBackButtonforQS(e);
    }).addClass('bb-init');
}

function updateProductWindowBack() {
    var $url = sessionStorage['lastvisitedpage'];
    var $pid = sessionStorage['lastvisitedproduct'];
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
    var gridUrl = loadingPlaceHolder.attr('data-grid-url');

    if (($('.infinite-scroll-placeholder[data-grid-url="' + $url + '"]').length === 0) && $url !== '' && $pid !== '') { return false; }

    // this code is NEVER executed as .infinite-scroll-placeholder isn't in any NA or EU template
    if ($('.product-tile[data-itemid="' + $pid + '"]').length === 0) {
        findTileInList(gridUrl, $pid);
    }
}

function prefetch() {
    if (navigator.serviceWorker && SitePreferences.SERVICE_WORKER_ENABLED) {
        if (!navigator.serviceWorker.controller) {
            // no controller!
        } else {
            var $paginationBtn = $('.pagination a.more-button[data-loading-state="unloaded"]');
            var urls = [];

            $paginationBtn.each(function(index, element) {
                var $element = $(element);
                var url = $element.attr('href');

                if (!url || url === window.location.href) {
                    return;
                }

                urls.push(url + '&format=ajax');
            });

            var message = {
                action: 'prefetch',
                urls: urls
            };

            navigator.serviceWorker.controller.postMessage({message: message});
        }
    }
}

function getTotalProductCount() {
    var count = ($('input[name=totalProductsCount]').length > 0)
        ? parseInt($('input[name=totalProductsCount]').val())
        : parseInt($('input[name=productsCount]').last().val());
    return count;
}

function appendPage(page) {
    var pages = [];

    if (pages.filter(function (p) { return p.count === page.count; }).length > 0) {
        return pages;
    } else {
        pages.push(page);
        return pages;
    }
}

function cleanUpPagingJSONAndStorage(pagingJSON) {
    var gridKeys = Object.keys(pagingJSON);

    for (var i = 0; i < gridKeys.length; i++) {
        var gridKey = gridKeys[i];

        if (gridKey !== 'url') {
            var loadMoreKeys = Object.keys(pagingJSON[gridKey]);

            for (var j = 0; j < loadMoreKeys.length; j++) {
                var loadMoreKey = loadMoreKeys[j];

                sessionStorage.removeItem(pagingJSON[gridKey][loadMoreKey]);
                delete pagingJSON[gridKey][loadMoreKey];
            }
        }

        delete pagingJSON[gridKey];
    }

    return pagingJSON;
}

function savePageState(response, grid, ajaxUrl) {
    var isgridFilterInvalid = util.isInvalidValue(grid);
    if (isgridFilterInvalid) {
        grid = DEFAULT_GRID_BREAK;
    }

    try {
        var pagingString = sessionStorage.getItem('pagination');
        var pagingJSON;

        if (pagingString !== null) {
            pagingJSON = JSON.parse(pagingString);

            if (!('url' in pagingJSON)) {
                pagingJSON.url = window.location.href;
            } else if (pagingJSON.url !== window.location.href) {
                pagingJSON = cleanUpPagingJSONAndStorage(pagingJSON);
                pagingJSON.url = window.location.href;
            }

            if (!(grid in pagingJSON)) {
                pagingJSON[grid] = [];
            }
        } else {
            pagingJSON = {};
            pagingJSON.url = window.location.href;
            pagingJSON[grid] = [];
        }

        var pagePreLoaded = false;

        for (var i = 0; i < pagingJSON[grid].length; i++) {
            if (pagingJSON[grid][i] === ajaxUrl) {
                pagePreLoaded = true;
            }
        }

        if (!pagePreLoaded) {
            pagingJSON[grid].push(ajaxUrl);
        }

        sessionStorage.setItem('pagination', JSON.stringify(pagingJSON));

        var productCount = getTotalProductCount();
        var pages = appendPage({count: productCount, response: response, scrollTop: $(window).scrollTop()});

        sessionStorage.setItem(ajaxUrl, JSON.stringify(pages));
    } catch (error) {
        // continue regardless of error
    }
}

/**
 * remove all load more pages that is stored in the session if the current window url doesn't match the url in the storage.
 * @returns
 */
function clearSessionStorage() {
    var pagingString = sessionStorage.getItem('pagination');

    if (pagingString === null) {
        return;
    }

    var pagingJSON = JSON.parse(pagingString);

    if ('url' in pagingJSON && pagingJSON.url !== window.location.href) {
        sessionStorage.setItem('pagination', JSON.stringify(cleanUpPagingJSONAndStorage(pagingJSON)));
    }

}

function getProductTileHeight() {
    var calculateProductTileHeight = function() {
        const productTile = document.querySelector('#primary .product-tile');

        if (productTile) {
            productTileHeight = productTile.offsetHeight;
        }

        return productTileHeight;
    };

    if (!productTileHeightListener) {
        productTileHeight = calculateProductTileHeight();

        window.addEventListener('resize', _debounce(() => {
            productTileHeight = calculateProductTileHeight();
        }, 300));

        productTileHeightListener = true;
    }

    return productTileHeight;
}

function addGhosting() {
    const $primaryContent = $('.primary-content');

    if ($primaryContent.find('.gridbreak-content').length) {
        $primaryContent.find('.gridbreak-content').hide();
    } else {
        $primaryContent.find('.search-result-content').hide();
    }

    $primaryContent.append($('.ghosting-container').clone(true));
}

function removeGhosting() {
    const $primaryContent = $('.primary-content');

    $primaryContent.find('.ghosting-container').remove();

    if ($primaryContent.find('.gridbreak-content').length) {
        $primaryContent.find('.gridbreak-content').show();
    } else {
        $primaryContent.find('.search-result-content').show();
    }
}

function wishlistInit() {
    require.ensure([], function (require) {
        var chunk = require('./wishlist');
        if (chunk.init) {
            chunk.init();
        }
    }, function (error) {
        console.log('error!', error);
    }, 'wishlist');
}

function wishlistSetFavorites() {
    require.ensure([], function (require) {
        var chunk = require('./wishlist');
        if (chunk.setFavourites) {
            chunk.setFavourites();
        }
    }, function (error) {
        console.log('error!', error);
    }, 'wishlist');
}


/*
 .d8888b.                    888      d8b                          d88P 8888888b.                   888
d88P  Y88b                   888      Y8P                         d88P  888   Y88b                  888
888    888                   888                                 d88P   888    888                  888
888         8888b.   .d8888b 88888b.  888 88888b.   .d88b.      d88P    888   d88P .d88b.  .d8888b  888888 .d88b.  888d888 .d88b.
888            "88b d88P"    888 "88b 888 888 "88b d88P"88b    d88P     8888888P" d8P  Y8b 88K      888   d88""88b 888P"  d8P  Y8b
888    888 .d888888 888      888  888 888 888  888 888  888   d88P      888 T88b  88888888 "Y8888b. 888   888  888 888    88888888
Y88b  d88P 888  888 Y88b.    888  888 888 888  888 Y88b 888  d88P       888  T88b Y8b.          X88 Y88b. Y88..88P 888    Y8b.
 "Y8888P"  "Y888888  "Y8888P 888  888 888 888  888  "Y88888 d88P        888   T88b "Y8888   88888P'  "Y888 "Y88P"  888     "Y8888
                                                        888
                                                   Y8b d88P
                                                    "Y88P"
*/
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

function getElementBounds(el) {
    const bds = el && el.getBoundingClientRect()
    return {
        top: bds ? bds.top + window.scrollY : 0,
        height: bds ? bds.height : 0
    }
}

function getAdjustedScrollTopPosition(scrollTop, targetSelector, targetBreak, containerEl) {
    if (!targetSelector) {
        return scrollTop;
    }
    try {

        // targetSelector is always an id
        const target = containerEl.querySelector(`[id='${targetSelector}']`);
        if (target) {
            const breakCont = containerEl.closest('.productGrid.js-paginated-container');
            if (breakCont) {
                const breakUUID = breakCont.dataset.containerUuid;
                if (breakUUID === targetBreak) {
                    const targetBds = getElementBounds(target);
                    if ((targetBds.top + targetBds.height) > scrollTop && targetBds.top < (scrollTop + window.innerHeight)) {
                        return scrollTop
                    } else {
                        return targetBds.top - (window.innerHeight - targetBds.height) / 2;
                    }

                }
            } else {
                return scrollTop;
            }
        }

    } catch (e) {
        console.log('ERROR', e)
        return scrollTop;
    }
}

const scrollTargetThreshold = 0;
let restored = false;
const setScrollPosition = function () {
    if (history && history.state && !restored) {
        const bodyContainer = document.querySelector('body');
        const productSelector = history.state.pid;
        const plpScrollPosition = history.state.plpScrollPosition;
        // when container height is enough manually scroll to stored position as soon as possible
        if (plpScrollPosition && plpScrollPosition <= bodyContainer.scrollHeight) {
            if (history.scrollRestoration === 'manual') {
                const searchResultsContainers = Array.from(bodyContainer.querySelectorAll('#primary div.search-result-content .search-result-items'));
                searchResultsContainers.some((searchResultsContainer) => {
                    const adjustedScrollY = getAdjustedScrollTopPosition(plpScrollPosition, productSelector, history.state.break, searchResultsContainer);
                    if (adjustedScrollY && Math.abs(window.scrollY - adjustedScrollY) > scrollTargetThreshold) {
                        window.scrollTo({ top: adjustedScrollY });
                        // only restore once
                        restored = true;
                        return true;
                    }
                });
            }
        }
    }

}

function initResizeObserver() {
    if (history && history.scrollRestoration === 'manual') {
        // prevent manual scroll restoration on user interaction
        ['wheel', 'touchmove'].forEach((evt) => {
            document.addEventListener(evt, () => {
                history.scrollRestoration = 'auto';
                ScrollTrigger.clearScrollMemory('auto');
            }, { once: true });
        });
        if (history.state) {
            // check if view all link state is saved in history
            if (history.state.viewAllActive) {
                const viewAllGridContainer = document.querySelector('.js-paginated-container[data-container-uuid="' + history.state.viewAllActive + '"]');
                if (viewAllGridContainer) {
                    // restore view all data attribute
                    viewAllGridContainer.setAttribute('data-view-all', true);
                }
            }
        }
    }
}

function updateSearchPageUI(response, grid) {
    // named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
    var fillEndlessScrollChunk = function (html, grid) {
        var $data = $('<div>').append($.parseHTML(html));
        var $newResult = $data.find('.search-result-items > *');

        if (grid === DEFAULT_GRID_BREAK) {
            $('#primary div.search-result-content .search-result-items').append($newResult);
            $('#bottom-pagination').html($data.find('#bottom-pagination').html());
        } else {
            $('#primary div.search-result-content[grid="' + grid + '"] .search-result-items').append($newResult);

            if ($data.find('#bottom-pagination .pagination').length > 0) {
                $('#bottom-pagination[grid="' + grid + '"]').html($data.find('#bottom-pagination').html());
            } else {
                $('#bottom-pagination[grid="' + grid + '"]').remove();
            }
        }
    };

    // update UI
    fillEndlessScrollChunk(response, grid);
    productTile.init();
    emptyProduct();

    var productCount = getTotalProductCount();

    $('span.productsCountUpdate').text(productCount);

    if (!$('.search-result-swatches').length) {
        wishlistInit();
    }
}

function updatePageUI(response, gridFilter, url, ajaxUrl) {
    try {
        savePageState(response, gridFilter, ajaxUrl);
    } catch (e) {
        // nothing to catch in case of out of memory of session storage
        // it will fall back to load via ajax
    }

    // update UI
    var $data = $('<div>').append($.parseHTML(response));
    var $newCont = $data.find('.search-result-items');
    var $newResult = $newCont.children();
    var isLastElement = false;
    var isgridFilterValid = !util.isInvalidValue(gridFilter);
    var $currentCont;
    if (isgridFilterValid) {
        $currentCont = $('#primary div.search-result-content[grid="' + gridFilter + '"] .search-result-items');
        if ($newCont.data('cat') === $currentCont.data('cat')) {
            $currentCont.append($newResult);

            if ($data.find('#bottom-pagination .pagination').length > 0) {
                $('#bottom-pagination[grid="' + gridFilter + '"]').html($data.find('#bottom-pagination').html());
            } else {
                $('#bottom-pagination[grid="' + gridFilter + '"]').remove();
            }
        }
    } else {
        $currentCont = $('#primary div.search-result-content .search-result-items');
        if ($newCont.data('cat') === $currentCont.data('cat')) {
            $currentCont.append($newResult);
            $('#bottom-pagination').html($data.find('#bottom-pagination').html());
        }

    }

    $newResult.filter(function (i, el) {
        return el.classList.contains('grid-tile');
    });

    productTile.init();

    if (!$('.search-result-swatches').length) {
        wishlistSetFavorites();
    }

    emptyProduct();
    watchNewRlcBlocks();

    if (!isLastElement) {
        document.dispatchEvent(new CustomEvent('loadMoreLoaded', { detail: url }));
    }

    if (SitePreferences.QUICK_SHOP_ENABLED) {
        initQS();
        document.dispatchEvent(new CustomEvent('addQuickShop', { bubbles: true, detail: { checkProducts: false, type: 'link' } }));
    }
}



function updateProductGridPlaceholderHtml(placeholder, response) {
    var productsFromSessionStorage = placeholder.find('.placeholder-items-separator').nextAll();
    var paginationFromSessionStorage = placeholder.find('.bottom-pagination-plp');
    var $response = $('<div>').append($.parseHTML(response));

    if (productsFromSessionStorage.length) {
        $response.find('.search-result-items').append(productsFromSessionStorage);

        // update pagination
        if (paginationFromSessionStorage.length) {
            $response.find('.bottom-pagination-plp').html(paginationFromSessionStorage.html() || '');
        } else {
            $response.find('.bottom-pagination-plp').remove();
        }
    }

    placeholder.html($response.html()); // Notes current product count in URL.
}

function restoreViewAllLinkState() {
    const viewAllGridContainer = document.querySelector('.js-paginated-container[data-view-all]');

    const observeAndClickViewAllLink = function () {
        const paginationBlock = viewAllGridContainer.querySelector('.bottom-pagination-plp');

        if (paginationBlock) {
            const paginationBlockObserver = new IntersectionObserver(function (entries, observer) {
                if (entries[0].isIntersecting) {
                    const viewAllLink = entries[0].target.querySelector('.pagination a.view-all');

                    // check if link is visible and clickable
                    if (viewAllLink && !!(viewAllLink.offsetWidth || viewAllLink.offsetHeight || viewAllLink.getClientRects().length) && window.getComputedStyle(viewAllLink).visibility !== 'hidden' && window.getComputedStyle(viewAllLink).pointerEvents !== 'none') {
                        observer.disconnect();
                        viewAllLink.click();
                    }
                }
            });

            paginationBlockObserver.observe(paginationBlock);
        }
    };

    if (viewAllGridContainer) {
        if (!viewAllGridContainer.dataset.loadingState || viewAllGridContainer.dataset.loadingState === 'loaded') {
            observeAndClickViewAllLink();
        } else {
            const gridBreakLoadedHandler = function _gridBreakLoadedHandler(e) {
                if (e.detail === viewAllGridContainer.getAttribute('url')) {
                    observeAndClickViewAllLink();
                    document.removeEventListener('gridBreakLoaded', _gridBreakLoadedHandler);
                }
            };

            document.addEventListener('gridBreakLoaded', gridBreakLoadedHandler);
        }
    }
}

const _throttle = require('lodash/throttle');
let throttledInfiniteGridBreak;
function infiniteGridBreakScrollInit() {
    throttledInfiniteGridBreak = _throttle(() => {
        // eslint-disable-next-line no-use-before-define
        infiniteGridBreak();
    }, 300);

    $(window).off('scroll', throttledInfiniteGridBreak).on('scroll', throttledInfiniteGridBreak);
}

function infiniteGridBreak() {
    var loadedHeight = 0;

    $('.productGrid[data-loading-state^="load"]').each(function () {
        loadedHeight += $(this).height();
    });
    if ((loadedHeight - $(window).scrollTop() - (getProductTileHeight() * 2)) <= 0) {
        var loadingPlaceHolder = $('.productGrid[data-loading-state="unloaded"]:first');

        if (loadingPlaceHolder.length > 0) {
            loadingPlaceHolder.attr('data-loading-state', 'loading');

            var urlToFetch = loadingPlaceHolder.attr('url');
            $.ajax({
                type: 'GET',
                dataType: 'html',
                url: urlToFetch,
                success: function (response) {
                    var gridFilter = loadingPlaceHolder.attr('data');
                    updateProductGridPlaceholderHtml(loadingPlaceHolder, response);

                    watchNewRlcBlocks();
                    document.dispatchEvent(new Event('productTilesUpdated'));
                    document.dispatchEvent(new CustomEvent('gridBreakLoaded', { detail: urlToFetch }));
                    loadingPlaceHolder.attr('data-loading-state', 'loaded');

                    if (SitePreferences.QUICK_SHOP_ENABLED) {
                        initQS();
                        document.dispatchEvent(new CustomEvent('addQuickShop', { bubbles: true, detail: { checkProducts: false, type: 'link' } }));
                    }

                    // include Wishlist option
                    if (!$('.search-result-swatches').length) {
                        wishlistInit();
                    }

                    // build the key
                    var keyURL = new URL(document.location.href);
                    keyURL.searchParams.append('breakid', gridFilter);
                    savePageState({ url: urlToFetch, data: response }, gridFilter, keyURL.href);
                    prefetch();
                },
                error: function (){
                    loadingPlaceHolder.closest('.gridbreak-content').remove();
                }
            });
        } else {
            $(window).off('scroll', throttledInfiniteGridBreak ? throttledInfiniteGridBreak : infiniteGridBreak);
        }
    }
}

function disablePagination() {
    var $bottomPaginationPlp = $('.bottom-pagination-plp');
    $bottomPaginationPlp.addClass('no-pointer-events');

}
function enablePagination() {
    var $bottomPaginationPlp = $('.bottom-pagination-plp');
    $bottomPaginationPlp.removeClass('no-pointer-events');
}

let isRestoreStateAllowed = true;
// eslint-disable-next-line complexity
function restoreState(event, forced) {
    if (!isRestoreStateAllowed && !forced) {
        enablePagination();
        return;
    }

    if (window.location.href.indexOf('srule=') >= 0 || window.location.href.indexOf('prefv1') >= 0 || window.location.href.indexOf('bopisStoreID') >= 0 || window.location.href.indexOf('sameDayPostal') >= 0 || ($('#filter-value-n1').val() && $('#filter-value-n1').val().length > 0)) {
        $('#bottom-pagination').css('display', 'block');
    }

    try {
        clearSessionStorage();
        infiniteGridBreak();

        var pagingString = sessionStorage.getItem('pagination');

        if (pagingString !== null) {
            var pagingJSON = JSON.parse(pagingString);

            if ('url' in pagingJSON && pagingJSON.url === window.location.href) {
                var isUsingGridBreaks = $('.gridbreak-content').length;
                var gridKeys = Object.keys(pagingJSON);

                const partials = [];

                for (var i = 0; i < gridKeys.length; i++) {
                    var gridKey = gridKeys[i];
                    // eslint-disable-next-line max-depth
                    if (gridKey !== 'url' && ((isUsingGridBreaks && gridKey !== DEFAULT_GRID_BREAK) || (!isUsingGridBreaks && gridKey === DEFAULT_GRID_BREAK))) {
                        var partialUrls = pagingJSON[gridKey];
                        // separating the postload breaks from the loadMore requests
                        partialUrls.forEach(
                            // eslint-disable-next-line no-loop-func
                            (partialUrl) => {
                                const url = new URL(partialUrl)
                                var isSamePage = url.pathname === (document.location.pathname);
                                if (isSamePage) {
                                    try {
                                        const cachedItem = JSON.parse(sessionStorage.getItem(partialUrl));
                                        if (cachedItem.map) { // test if it's an array
                                            cachedItem.map((item) => {
                                                if (item.response.url) {
                                                    partials.push({ type: 'break', key: partialUrl, gridKey: gridKey, data: item.response.data });
                                                } else {
                                                    partials.push({ type: 'more', key: partialUrl, gridKey: gridKey, data: item.response });
                                                }
                                            });
                                        } else {
                                            sessionStorage.removeItem(partialUrl);
                                        }
                                    } catch (error) {
                                        console.log('ERROR', error);
                                        sessionStorage.removeItem(partialUrl);
                                    }
                                }
                            }
                        );
                    }
                }

                // reorder for restoring
                var gridIDs = $('.productGrid').toArray().map((grid) => grid.getAttribute('data'));
                if (!gridIDs.length) {
                    gridIDs = [DEFAULT_GRID_BREAK];
                }

                gridIDs.forEach((gridId) => {
                    partials.filter((partial) => partial.gridKey === gridId).forEach((partial) => {
                        switch (partial.type) {
                            case 'break': {
                                const loadingPlaceHolder = $(`.productGrid[data-loading-state="unloaded"][data="${partial.gridKey}"]`).attr('data-loading-state', 'loaded');
                                updateProductGridPlaceholderHtml(loadingPlaceHolder, partial.data);
                                break;
                            }
                            case 'more':
                                updateSearchPageUI(partial.data, partial.gridKey);
                                break;
                            default: //
                        }
                    });
                });

            }
        }
        setScrollPosition();
        enablePagination();
        document.dispatchEvent(new CustomEvent('productListingUpdated'));
        document.dispatchEvent(new Event('productTilesUpdated'));
        if (SitePreferences.QUICK_SHOP_ENABLED) {
            initQS();
            document.dispatchEvent(new CustomEvent('addQuickShop', { bubbles: true, detail: { checkProducts: false, type: 'link' } }));
        }

        if (!forced) {
            restoreViewAllLinkState();
        }

        watchNewRlcBlocks();
    } catch (e) {
        console.log('ERROR', e)
        enablePagination();

        sessionStorage.removeItem(window.location.href);
    }
}

function commonInit() {
    // why would we stop restoring on scroll?
    ['wheel', 'touchmove'].forEach((evt) => {
        document.addEventListener(evt, () => {
            // prevent state restoring onload/onpageshow is scroll was triggered and grid break loads
            isRestoreStateAllowed = false;
        }, { once: true });
    });
    initResizeObserver();
}


exports.commonInit = commonInit;
exports.watchNewRlcBlocks = watchNewRlcBlocks;
exports.emptyProduct = emptyProduct;
exports.filterControlSwitch = filterControlSwitch;
exports.resetRefinement = resetRefinement;
exports.priceLabelPosition = priceLabelPosition;
exports.updateProductWindowBack = updateProductWindowBack;
exports.initBackButtonforQS = initBackButtonforQS;
exports.initQS = initQS;
exports.storePlpScrollPosition = storePlpScrollPosition;
exports.prefetch = prefetch;
exports.getTotalProductCount = getTotalProductCount;
exports.savePageState = savePageState;
exports.clearSessionStorage = clearSessionStorage;
exports.getProductTileHeight = getProductTileHeight;
exports.reveal = reveal;
exports.addGhosting = addGhosting;
exports.removeGhosting = removeGhosting;
exports.wishlistInit = wishlistInit;
exports.wishlistSetFavorites = wishlistSetFavorites;

exports.updatePageUI = updatePageUI;
exports.infiniteGridBreakScrollInit = infiniteGridBreakScrollInit;
exports.disablePagination = disablePagination;
exports.restoreState = restoreState;

exports.DEFAULT_GRID_BREAK = DEFAULT_GRID_BREAK;
