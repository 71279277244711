'use strict';

var _ = require('lodash');

var $form, $continue, $requiredInputs, validator;

var hasEmptyRequired = function () {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.filter(':visible').map(function () {
        return $(this).val();
    });
    var hasEmpty = _(requiredValues).indexOf('') > -1;
    return hasEmpty;
};

var validateAllRequired = function () {
    $requiredInputs.filter(':visible').map(function () {
        validator.element(this);
    });
};

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }
    // When clicks on Edit address in shipping/billing, no need to disabled continue button - Since user editing the address
    if($(this).closest('form').hasClass('edit-address-details')) {
		return false;
	}
    if (!hasEmptyRequired()) {
        if (validator.form()) {
            if (($('#is-BML').length) && ($('input[name$=_selectedPaymentMethodID]:checked').val() === 'BML') && (!($('.dyn-form-billing').find('.input-select.country').find(':checked').val() === 'DE'))) {
                $('.is-BML .field-wrapper').append('<span id="is-BML-error" class="error">'+ Resources.OPENINVOICE_GERMANY +'</span>');
                $continue.prop('disabled', true);
                $continue.closest('#container-wrap').find('.mini-summary-btn').prop('disabled', true);
                return;
            }
        	if (!($(this).closest('.user-cred-details').find('.cc-error').length > 1 || $(this).closest('.user-cred-details').find('.cvn span').css('display') == 'block')) {
        		if ($('.checkout-billing').find('.adyen-checkout__field--error').length > 0 || $('#gc-error').length > 0) {
        			$continue.prop('disabled', true);
                    $continue.closest('#container-wrap').find('.mini-summary-btn').prop('disabled', true);
        		} else {
	        		$continue.prop('disabled', false);
		            $continue.closest('#container-wrap').find('.mini-summary-btn').prop('disabled', false);
        		}
        	}
            setTimeout(function () {
                $('.mini-summary-btn').text($('.button-fancy-large').val());
            },3000);
        }
    } else {
        $continue.prop('disabled', true);
        $continue.closest('#container-wrap').find('.mini-summary-btn').prop('disabled', true);
        setTimeout(function () {
            $('.mini-summary-btn').text($('.button-fancy-large').val());
        },3000);	
    }
    checkShippingMethod();
};

var validateEl = function () {
	// When clicks on Edit address in shipping/billing, no need to disabled continue button - Since user editing the address
	if($(this).closest('form').hasClass('edit-address-details')) {
		return false;
	}
    if ($(this).val() === '' || $('select.shipping-method-drop').find('option').hasClass('noshipping-method')) {
        $continue.prop('disabled', true);
        $continue.closest('#container-wrap').find('.mini-summary-btn').prop('disabled', true);
    } else {
        // enable continue button on last required field that is valid
        // only validate single field
        if (validator.element(this) && !hasEmptyRequired() && !$('input.error').length) {
        	if (!($(this).closest('.user-cred-details').find('.cc-error').length > 1 || $(this).closest('.user-cred-details').find('.cvn span').css('display') == 'block')) {
        		if ($('.checkout-billing').find('.adyen-checkout__field--error').length > 0 || $('#gc-error').length > 0) {
        			$continue.prop('disabled', true);
                    $continue.closest('#container-wrap').find('.mini-summary-btn').prop('disabled', true);
        		} else {
	        		$continue.prop('disabled', false);
		            $continue.closest('#container-wrap').find('.mini-summary-btn').prop('disabled', false);
        		}
        	}
        } else {
            $continue.prop('disabled', true);
            $continue.closest('#container-wrap').find('.mini-summary-btn').prop('disabled', true);
        }
    }
    checkShippingMethod();
};
var checkShippingMethod = function(){
    var shippingMethodTest = $('.dyn-form-shipping').length > 0 && ($('#shipping-method-drop').val() == '' || $('#shipping-method-drop').val() === undefined || $('#shipping-method-drop').val() == null);
    var monetateShippingMethodTest = $('.dyn-form-shipping').length > 0 && $('.monetate-shipping-method[selected]').length === 0;
	if (SitePreferences.MONETATE_SHIPPING_DELIVERY_DATE ? monetateShippingMethodTest : shippingMethodTest) {
		//continue button disabled if no shipping method available
    	$('button[name$="chkoutButton"]').prop("disabled", true);
		$('button[name$="_shippingAddress_save"]').prop("disabled", true);
	}
};
var dynFormDisplayStyle = function(currentForm){
	var windowWidth = $(window).width();
	if(windowWidth > 959){
		$(currentForm+':visible').css('display','flex');
	} else {
		$(currentForm+':visible').css('display','block');
	}
};
var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    validator = $form.validate();
    $requiredInputs = $('.required', $form).find(':input');
    validateForm();
    // start listening
    $requiredInputs.on('change', validateEl);
    $requiredInputs.on('blur change', validateForm);
    $requiredInputs.filter('input').on('keyup', _.debounce(validateEl, 2000));
    // remove 'year' and 'month' classes from open invoice select boxes to avoid triggering the credit card validation 
    if($('.year-mnth-day-op-inv').length > 0){
        $('.year-mnth-day-op-inv').find('select[id$="_paymentMethods_bml_year"]').removeClass('year'); 
        $('.year-mnth-day-op-inv').find('select[id$="_paymentMethods_bml_month"]').removeClass('month'); 
    }
};
var disableContinueButtons = function () {
    var $continue = $('button[name$="_billing_save"]');
    $continue.prop('disabled', true);
    $continue.closest('#container-wrap').find('.button-fancy-large, .mini-summary-btn').prop('disabled', true);
};

exports.init = init;
exports.validateForm = validateForm;
exports.validateEl = validateEl;
exports.checkShippingMethod = checkShippingMethod;
exports.dynFormDisplayStyle = dynFormDisplayStyle;
exports.validateAllRequired = validateAllRequired;
exports.hasEmptyRequired = hasEmptyRequired;
//PREVAIL - Added for Ingenico payments.
exports.formDebounce = _.debounce;
exports.disableContinueButtons = disableContinueButtons;