'use strict';

var util = require('./util');
var FlyoutSearch = (SitePreferences.MONETATE_SEARCH_WITHIN_DEPARTMENT === true) ? require('./monetate-flyoutsearch') : require('./flyoutsearch');
var SearchButton = require('./searchbutton');

function MenuSearch($searchContainer, $nav, $defaultValue) {
    this.init = function() {
        var $searchButton = $('#search > button');

        if (!$searchButton.length) {
            return; // If there's no search button, just skip initialization entirely.
        }

        var flyout = new FlyoutSearch($searchContainer, $nav, $defaultValue).init();
        var searchButton = new SearchButton($searchButton);

        if (!util.isMobile() && $(window).width() > 959 ) {
            searchButton.click(function() {
                if ($('#wrapper').hasClass('search-active')) {
                    closeSearch();
                } else {
                    openSearch();
                }
            });
        } else {
            searchButton.click(function() {
                setTimeout(function() {
                    if ($('#wrapper').hasClass('menu-active')) {
                        $('.menu-toggle').trigger('click');
                    }
                }, 500);

                if ($('#wrapper').hasClass('search-active')) {
                    closeSearch();
                } else {
                    openSearch();
                }
            });
        }

        flyout.gutterClick(closeSearch);
        flyout.onEnterEscKey(closeSearch);

        function openSearch() {
            $searchButton.attr('aria-expanded', true);
            searchButton.openSearch();
            flyout.show();

            setTimeout(function() {
                document.getElementById("q-search").focus();
                if (SitePreferences.BLOOMREACH_ENABLED) {
                	$('.flyout-results-container input[name="q"]').trigger('focus');
                } else {
                	$('input[name="q"]').trigger('focus');
                }
            }, 1000); //trigger focus after animation is finished
        }

        function closeSearch() {
            $searchButton.attr('aria-expanded', false);
            flyout.hide();
            searchButton.closeSearch();
        }
    };
}

module.exports = MenuSearch;
