'use strict';
var dateFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' }).format;
var isDevelopment = typeof window.instanceType != 'undefined' && window.instanceType < 1;

const basicIdFunc = function ($from, $to) {
    var html = $from.html();
    if ($to.html().trim() !== (html && html.trim())) {
        return html;
    }
};

var Replacer = function ($targetcontent, $data) {

    this.$data = $data;
    this.$targetcontent = $targetcontent;
    var timestamp = Date.now();
    this.replace = function (selectorFrom, selectorTo, force, useContainer, $target, idFunc) {
        var selector = selectorTo ? selectorTo : selectorFrom;
        $target = $target || this.$targetcontent;
        if ($target.length) {
            var $newContent = $data.find(selectorFrom);
            if ($newContent.length > 0) {
                var replaced = false;
                var $targets = $target.is(selector) ? $target : $target.find(selector);
                $targets.each(function (index) {
                    var $container = useContainer ? $($newContent[index]).wrap('<div></div>').parent() : $($newContent[index]);
                    var html = idFunc ? idFunc($container, $(this)) : basicIdFunc($container, $(this));
                    if (html) {
                        html = '<!-- rep: ' + (isDevelopment ? dateFormat(timestamp) : timestamp) + ' -->' + html;
                        if (useContainer) {
                            $(this).replaceWith(html);
                        } else {
                            $(this).html(html);
                        }
                        replaced = true;
                    }
                })
                return (replaced ? $newContent : false);

            } else {
                if (force) {
                    $target.find(selector).html('');
                }
            };
        }
        return false;
    };
    this.replaceAttr = function (selector, attributes) {
        var $newContent = $data.find(selector);
        if ($newContent.length > 0) {
            this.$targetcontent.find(selector).each(function (index) {
                if (Array.isArray(attributes)) {
                    attributes.forEach(function (attribute) {
                        $(this).attr(attribute, $($newContent[index]).attr(attribute));
                    })

                } else {
                    $(this).attr(attributes, $($newContent[index]).attr(attributes));
                }

            });
        }

    }
}


module.exports = Replacer;
