'use strict';
var formPrepare = require('../../../app_apac_foundation/cartridge/js/pages/checkout/formPrepare');

function getInvoiceTemplate(invoiceTypeEle, invoiceOptionEle) {
    if (invoiceTypeEle && invoiceTypeEle.length) {
        var $invoiceWrapper = $('.invoice-wrapper');
        var url = Urls.getInvoiceTemplate + '?format=ajax&' + invoiceTypeEle.prop('name') + '=' + encodeURIComponent(invoiceTypeEle.filter(':checked').val());
        if (invoiceOptionEle && invoiceOptionEle.length) {
            url += '&' + invoiceOptionEle.prop('name') + '=' + encodeURIComponent(invoiceOptionEle.val());
        }
        $invoiceWrapper.load(url, function () {
            formPrepare.init({
                formSelector: 'form[id$="billing"]',
                continueSelector: '[name$="billing_save"]'
            });
            $('.has-float-label-apac').has('label').has('input[type="text"]').addClass('transparent-placeholder');
        });
    }
}

function initInvoiceEvents() {
    var invoiceType = $('[name$="_billingInvoice_invoice_type"]');

    invoiceType.on('change', function () {
        getInvoiceTemplate($(this));
    })

    // personal type drop down event
    $(document).on('change', '[name$="_billingInvoice_invoice_personal"], [name$="_billingInvoice_invoice_donation"]', function () {
        getInvoiceTemplate(invoiceType, $(this));
    });

    $(document).on('click', '.popup-window-call', function (e) {
        e.preventDefault();
        window.open($(this).attr('href'), 'donationWindow', 'top=50,left=50,width=800,height=400');
    });

    var invoiceMobileLabel = $('input[name$="_invoice_mobilecode"]').closest('.form-row').find('label');
    var requireIndicator = $('input[name$="_invoice_mobilecode"]').closest('.form-row').find('label .required-indicator');
    if (requireIndicator.length) {
        var requireIndicatorHTML = requireIndicator[0].outerHTML;
        requireIndicator.remove();
        invoiceMobileLabel.append(requireIndicatorHTML);
    }

}

module.exports = function () {
    initInvoiceEvents();
};
