'use strict';

var util = require('./util'),
    progress = require('./progress'),
    imagesLoaded = require('imagesloaded'),
    Suggestion = require('./suggestion');

function FlyoutSearch($flyoutSearch, $nav, defaultValue) {
    var self = this,
        currentQuery = null,
        lastQuery = null,
        runningQuery = null,
        listTotal = -1,
        listCurrent = -1,
        delay = 30,
        $resultsContainer;
    var $gutter;
    var $searchForm;
    var $searchField;
    var $searchClearButton;
    var $searchSubmitButton;
    var $staticSuggestions;
    var $searchSuggestions;
    var $flyoutContainer;
    var suggestion;
    var $refinementsPill;
    var $refinementPill;
    var refinementsPillSelectedVal = '';
    var $hint;
    var $searchIcon;

    var delayTyping = (function() {
        var timer = 0;

        return function(callback, ms) {
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    })();

    /**
     * @function
     * @description Configures parameters and required object instances
     */
    this.init = function() {
        $searchForm = $flyoutSearch.find('form[name="simpleSearch"]');
        $searchField = $searchForm.find('input[name="q"]');
        $searchClearButton = $searchForm.find('.clear-search');
        $searchSubmitButton = $searchForm.find('button[type=submit]');
        $searchIcon = $searchForm.find('.search-icon-button');
        $staticSuggestions = $('.static-search-suggestions', $flyoutSearch);
        $flyoutContainer = $('.flyout-results-container');
        $gutter = $flyoutSearch.find('.flyout-shadow-overlay');
        $searchSuggestions = $flyoutSearch.find('.search-suggestions');
        suggestion = new Suggestion($searchField);
        $refinementsPill = $('.js-search-refinements-pill');
        $refinementPill = $refinementsPill.find('a');
        refinementsPillSelectedVal = $refinementsPill.find('li.selected a').first().text().trim();

        if (util.isMobile() && $('#navigation').length && $(window).width() < 960) {
            var overlayHeight = Math.max($(window).height(), $(document).height()) - $gutter.offset().top;
            $gutter.height(overlayHeight);
            setFlyoutMinHeight($flyoutContainer);
        }

        $searchClearButton.on('click', onClearButtonClicked);
        $searchField.attr('autocomplete', 'off'); // disable browser auto complete
        $searchField.on('focus', onSearchFieldFocus);
        $searchField.on('keyup', onKeyUp);
        $searchField.on('keydown', handleTabKey);
        $searchField.on('change', onKeyUp);
        $refinementPill.off('click');
        $(document).on('click', '.js-search-refinements-pill a', handleRefinementPillClick);

        $searchClearButton.on('keydown', function(e) {
            var key = e.which;

            if (key == 13 || key == 32)  {// the enter key code
                $(this).trigger('click');//Trigger click for keyboard accessibility
                return false;
            }
        });

        $('.menu-mask').on('click', function() {
            if ($(window).width() > 959) {
                hideDesktop();
            }
        });

        $('.search-close').on('click', function(e) {
            e.preventDefault();
            hideMobile();
        });

        return self;
    };

    this.show = function() {
        if (util.isMobile() && $(window).width() < 960) {
            showMobile();
        } else {
            showDesktop();
        }
    };

    function showMobile() {
        util.expand($flyoutSearch);
        $('.header-search').css('position', 'fixed');
        $flyoutContainer.css('height', '100vh');
        $gutter.css('height', '100vh');

        if ($('.search-popular-categories > div').length != 0) {
            $('.search-popular-categories').fadeIn(100);
        }

        $nav.find('.header-search input[name="q"]').trigger('focus').val('');
        onQueryChange();
        $flyoutContainer.removeClass('out');
        $flyoutContainer.addClass('hover');
        $searchClearButton.toggle(false); // hide

        $('body').css({
            'overflow': 'hidden'
        });

        $('#wrapper').addClass('search-active');
        $('.menu-mask').addClass('menu-mask-active');
        util.focusAndOpenKeyboard($searchField[0], 300); // or without the second argument
        self.clearResults();
    }

    function showDesktop() {
        util.expand($flyoutSearch);

        if ($('.search-popular-categories > div').length != 0) {
            $('.search-popular-categories').fadeIn(100);
        }

        $nav.find('.header-search input[name="q"]').trigger('focus').val('');
        onQueryChange();
        $searchClearButton.toggle(false); // hide
        $('#wrapper').addClass('search-active');
        $('.menu-mask').addClass('menu-mask-active');
        $searchField.trigger('focus');
        self.clearResults();
    }

    this.hide = function() {
        if (util.isMobile() && $(window).width() < 960) {
            hideMobile();
        } else {
            hideDesktop();
        }
    };

    function hideMobile() {
        $flyoutContainer.addClass('out');
        $flyoutContainer.removeClass('hover');
        util.contract($flyoutSearch);
        $gutter.css('height', ''); // Let it flow again.
        $('body').removeAttr( 'style' );
        $('.header-search').css('position', '');
        $('#wrapper').removeClass('search-active');
        $('.menu-mask').removeClass('menu-mask-active');
    }

    function hideDesktop() {
        util.contract($flyoutSearch);
        $('#wrapper').removeClass('search-active');
        $('.menu-mask').removeClass('menu-mask-active');
    }

    /**
     * @function
     * @description trigger suggest action
     */
    this.suggest = function() {
        progress.show($flyoutSearch);

        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)
        if (runningQuery !== currentQuery) {
            // update running query to the most recent search phrase
            runningQuery = currentQuery;
        }

        // if it's empty clear the results box and return
        if (runningQuery.length === 0) {
            self.clearResults();
            runningQuery = null;
            progress.hide();
            return;
        }

        // if the current search phrase is the same as for the last suggestion call, just return
        if (lastQuery === runningQuery) {
            runningQuery = null;
            progress.hide();
            return;
        }

        var params = {};

        params.q = runningQuery;
        params.format = 'ajax';

        var prevRefinementsPillSelectedVal = refinementsPillSelectedVal;
        refinementsPillSelectedVal = $refinementsPill.find('li.selected a').first().text().trim(); // get update text

        if (refinementsPillSelectedVal !== Resources.SEARCH_ALL) {
            params.prefn1 = 'PoloDivision';
            params.prefv1 = refinementsPillSelectedVal;
        }

        // build the request url
        var reqUrl = util.appendParamsToUrl(Urls.searchsuggest, params);

        // execute server call
        $.get(reqUrl, function(data) {
            var $data = $('<div>').append($.parseHTML(data)),
                suggestionHTML = data,
                searchSuggestionResult = $data.find('input[name="search-suggestion-results"]').val(),
                ansLength = suggestionHTML.trim().length;

            // if there are results populate the results div
            if (ansLength === 0 || searchSuggestionResult === 'false') {
                self.clearResults();

                // make second ajax call only if customer does not have All selected
                if (refinementsPillSelectedVal !== Resources.SEARCH_ALL) {
                    var params = {};
                    var allUrl = $refinementsPill.find('li a').first().attr('href');

                    if (allUrl.indexOf('?') === -1) {
                        params.q = runningQuery;
                    }

                    params.format = 'ajax';

                    allUrl = util.appendParamsToUrl(allUrl, params);

                    if (allUrl.indexOf('?') > -1) {
                        $.get(allUrl, function(data) {
                            var $data = $('<div>').append($.parseHTML(data)),
                                suggestionHTML = data,
                                searchSuggestionResult = $data.find('input[name="search-suggestion-results"]').val(),
                                ansLength = suggestionHTML.trim().length;

                            if (ansLength === 0 || searchSuggestionResult === 'false') {
                                self.clearResults();

                                // update the results div
                                $('.search-popular-categories').fadeOut(100);
                                $resultsContainer.html(suggestionHTML).fadeIn(200);
                                $refinementsPill.html($data.find('.js-search-refinements-pill > *')).fadeIn(200);
                            } else {
                                // Add scroll so all results can be seen in smaller viewports
                                var winHt = $(window).outerHeight();
                                var hdrHt = $('header').outerHeight();
                                var fullSearchHt = $('.full-search').outerHeight();
                                var $productSuggestionNoHits = $('.monetate-product-suggestion-no-hits');
                                var $productSuggestionNoHitsPElm = $productSuggestionNoHits.find('p');
                                var searchNoResultsInText = Resources.SEARCH_NO_RESULTS_IN.split('{0}').join('"' + currentQuery + '"').split('{1}').join(prevRefinementsPillSelectedVal);
                                var $simplesearch_prefn1 = $('.js-simplesearch-prefn1');
                                var $simplesearch_prefv1 = $('.js-simplesearch-prefv1');

                                // update the results div
                                $('.search-popular-categories').fadeOut(100);
                                $resultsContainer.html(suggestionHTML).fadeIn(200);
                                $refinementsPill.html($data.find('.js-search-refinements-pill > *')).fadeIn(200);
                                $refinementsPill.find("[data-topcat='" + refinementsPillSelectedVal.toLocaleLowerCase() + "']").addClass('disabled');
                                $productSuggestionNoHitsPElm.text(searchNoResultsInText);
                                $productSuggestionNoHits.show();

                                updateSuggestion();

                                var $suggestion = $('.product-suggestions .product-suggestion, .search-suggestion-wrapper.no-result');

                                if ($suggestion.length != 0) {
                                    // hide static suggestions
                                    $staticSuggestions.length && $staticSuggestions.hide();
                                    $staticSuggestions.hide();

                                    imagesLoaded('.product-suggestions').on('done', function() {
                                        if ((fullSearchHt + $resultsContainer.outerHeight() + hdrHt) > winHt) {
                                            $('.search-suggestions').addClass('scroll');
                                            $('.flyout-results-container').css('min-height', 0);
                                        }
                                    });
                                } else {
                                    $staticSuggestions.length && $staticSuggestions.show();
                                }

                                $simplesearch_prefn1.remove();
                                $simplesearch_prefv1.remove();

                                // record the query that has been executed
                                lastQuery = runningQuery;

                                // reset currently running query
                                runningQuery = null;

                                self.hideLeftPanel();

                                progress.hide();
                            }
                        });
                    }
                } else {
                    // Add scroll so all results can be seen in smaller viewports
                    var winHt = $(window).outerHeight();
                    var hdrHt = $('header').outerHeight();
                    var fullSearchHt = $('.full-search').outerHeight();

                    // update the results div
                    $('.search-popular-categories').fadeOut(100);
                    $resultsContainer.html(suggestionHTML).fadeIn(200);
                    $refinementsPill.html($data.find('.js-search-refinements-pill > *')).fadeIn(200);

                    // make sure to update the pill selection to the correct one
                    $refinementsPill.find('li').removeClass('selected');
                    $refinementsPill.find("[data-topcat='" + refinementsPillSelectedVal.toLocaleLowerCase() + "']").addClass('selected');

                    updateSuggestion();

                    var $suggestion = $('.product-suggestions .product-suggestion, .search-suggestion-wrapper.no-result');

                    if ($suggestion.length != 0) {
                        // hide static suggestions
                        $staticSuggestions.length && $staticSuggestions.hide();
                        $staticSuggestions.hide();

                        imagesLoaded('.product-suggestions').on('done', function() {
                            if ((fullSearchHt + $resultsContainer.outerHeight() + hdrHt) > winHt) {
                                $('.search-suggestions').addClass('scroll');
                                $('.flyout-results-container').css('min-height', 0);
                            }
                        });
                    } else {
                        $staticSuggestions.length && $staticSuggestions.show();
                    }
                }
            } else {
                // Add scroll so all results can be seen in smaller viewports
                var winHt = $(window).outerHeight();
                var hdrHt = $('header').outerHeight();
                var fullSearchHt = $('.full-search').outerHeight();

                // update the results div
                $('.search-popular-categories').fadeOut(100);
                $resultsContainer.html(suggestionHTML).fadeIn(200);
                $refinementsPill.html($data.find('.js-search-refinements-pill > *')).fadeIn(200);

                // make sure to update the pill selection to the correct one
                $refinementsPill.find('li').removeClass('selected');
                $refinementsPill.find("[data-topcat='" + refinementsPillSelectedVal.toLocaleLowerCase() + "']").addClass('selected');

                updateSuggestion();

                var $suggestion = $('.product-suggestions .product-suggestion, .search-suggestion-wrapper.no-result');

                if ($suggestion.length != 0) {
                    // hide static suggestions
                    $staticSuggestions.length && $staticSuggestions.hide();
                    $staticSuggestions.hide();

                    imagesLoaded('.product-suggestions').on('done', function() {
                        if ((fullSearchHt + $resultsContainer.outerHeight() + hdrHt) > winHt) {
                            $('.search-suggestions').addClass('scroll');
                            $('.flyout-results-container').css('min-height', 0);
                        }
                    });
                } else {
                    $staticSuggestions.length && $staticSuggestions.show();
                }
            }

            // record the query that has been executed
            lastQuery = runningQuery;

            // reset currently running query
            runningQuery = null;

            // check for another required update (if current search phrase is different from just executed call)
            if (currentQuery !== lastQuery) {
                // ... and execute immediately if search has changed while this server call was in transit
                runningQuery = currentQuery;
                setTimeout(self.suggest, delay);
            }

            self.hideLeftPanel();

            progress.hide();
        });
    };

    function updateSuggestion() {
        $hint = $searchForm.find('.js-hint');

        try {
            var $phraseContainer = $('.suggested-phrase', $resultsContainer);

            if ($phraseContainer && $phraseContainer.length) {
                var suggestedPhrase = $phraseContainer.text().replace(/\s+/g, ' ').trim();
                suggestion.suggest(suggestedPhrase);
            } else {
                suggestion.suggest('');
                $hint.val('');
            }
        } catch(e) {
            suggestion.suggest('');
            $hint.val('');
        }
    }

    /**
     * @function
     * @description
     */
    this.clearResults = function() {
        resetProductSuggestionNoHitsText();
        resetRefinementPill();

        $refinementPill.each(function(index, element) {
            var $el = $(element);
            var hrefVal = $el.attr('href');

            if (hrefVal !== 'javascript:void(0)' && currentQuery.length <= 0) {
                hrefVal = util.removeParamFromURL(hrefVal, 'q');
                $el.attr('href', hrefVal);
            }
        });

        lastQuery = null;

        if (!$resultsContainer) { return; }

        $resultsContainer.html('');
        $staticSuggestions.length && $staticSuggestions.show();

        if ($('.search-popular-categories > div').length != 0) {
            $('.search-popular-categories').fadeIn(100);
        }

        refinementsPillSelectedVal = $refinementsPill.find('li.selected a').first().text().trim(); // get update text
        if (refinementsPillSelectedVal.toLocaleLowerCase() === Resources.SEARCH_ALL.toLocaleLowerCase()) {
            $searchIcon.addClass('default');
        } else {
            $searchIcon.removeClass('default');
        }
    };

    /**
     * @function
     * @description
     */
    this.hideLeftPanel = function() {
        //hide left panel if there is only a matching suggested custom phrase
        if ($('.search-suggestion-left-panel-hit').length === 1 && $('.search-phrase-suggestion a').text().replace(/(^[\s]+|[\s]+$)/g, '').toUpperCase() === $('.search-suggestion-left-panel-hit a').text().toUpperCase()) {
            $('.search-suggestion-left-panel').css('display', 'none');
            $('.search-suggestion-wrapper-full').addClass('search-suggestion-wrapper');
            $('.search-suggestion-wrapper').removeClass('search-suggestion-wrapper-full');
        }
    };

    this.gutterClick = function(cb) {
        $gutter.on('click', function(e) {
            if ($gutter.is(e.target)) {
                e.preventDefault();
                cb();
            }
        });
    };

    function setFlyoutMinHeight($flyoutContainer) {
        $flyoutContainer.css({
            height: $(window).height()
        });
    }

    function onQueryChange() {
        currentQuery = $searchField.val() ? $searchField.val().trim() : '';
        var empty = currentQuery == '';
        var $simplesearch_nohits = $('.js-monetate-product-suggestion-no-hits');

        $searchSubmitButton.toggleClass('faded', empty);

        // clear out text if user selects another option
        if ($simplesearch_nohits.length > 0) {
            $simplesearch_nohits.find('p').empty();
        }

        if (util.isMobile()) {
            $searchClearButton.toggle(!empty);
        }

        if (empty && !util.isMobile() && $(window).width() >= 960) {
            $searchSubmitButton.show();
        } else if (!empty && !util.isMobile() && $(window).width() >= 960) {
            $searchSubmitButton.hide();
        }

        $hint = $searchForm.find('.js-hint');

        if (!empty) {
            $hint.val('');
        }
    }

    // on key up listener
    function onKeyUp(e) {
        onQueryChange();

        delayTyping(function() {
            // check and treat up and down arrows
            if (handleArrowKeys(e)) {
                return;
            } else {
                var $allPill = $('#all-pill');
                var allPillDefaultUrl = $allPill.attr('data-defaulturl');

                $allPill.attr('href', allPillDefaultUrl);

                $refinementPill.each(function(index, element) {
                    var $el = $(element);
                    var hrefVal = $el.attr('href');

                    if (hrefVal !== 'javascript:void(0)' && currentQuery.length > 0) {
                        hrefVal = util.removeParamFromURL(hrefVal, 'q');
                        hrefVal = util.appendParamToURL(hrefVal, 'q', currentQuery);
                        $el.attr('href', hrefVal);
                    }
                });

                // BUG: causes new input to be ignored while/after req is pending
                // SHOULD: Cancel previous req, queue new one.
                // no query currently running, init an update
                if (!runningQuery) {
                    runningQuery = currentQuery;
                    setTimeout(self.suggest, delay);
                }
            }
        }, 1000);
    }

    // on focus listener (clear default value)
    function onSearchFieldFocus() {
        var $searchSuggestions = $flyoutSearch.find('.search-suggestions');

        if (!$searchSuggestions.length) {
            // create results container if needed
            $resultsContainer = $('<div/>').attr('id', 'search-suggestions').appendTo($flyoutSearch);
            $resultsContainer = $('<div/>').attr('class', 'search-suggestions').appendTo($flyoutSearch);
        } else {
            $resultsContainer = $searchSuggestions;
        }

        if ($searchField.val() === defaultValue) {
            $searchField.val('');
        }

        if ($staticSuggestions.length && !$('.product-suggestions .product-suggestion').length) {
            $staticSuggestions.show();
        }
    }

    function onClearButtonClicked() {
        resetProductSuggestionNoHitsText();

        $searchField.val('');
        $staticSuggestions.show();
        $searchSuggestions.empty();
        resetRefinementPill();

        if ($('.search-popular-categories > div').length != 0) {
            $('.search-popular-categories').fadeIn(100);
        }

        onQueryChange();
        $searchField.trigger('focus');

        $refinementPill.each(function(index, element) {
            var $el = $(element);
            var hrefVal = $el.attr('href');

            if (hrefVal !== 'javascript:void(0)' && currentQuery.length <= 0) {
                hrefVal = util.removeParamFromURL(hrefVal, 'q');
                $el.attr('href', hrefVal);
            }
        });
    }

    function resetRefinementPill() {
        $('.js-search-refinements-pill').find('li.disabled a').each(function(index, element) {
            var $this = $(element);
            var defaultUrl = $this.attr('data-defaulturl');
            $this.attr('href', defaultUrl);
        });

        $('.js-search-refinements-pill').find('li.disabled').removeClass('disabled');
    }

    function resetProductSuggestionNoHitsText() {
        var $productSuggestionNoHits = $('.monetate-product-suggestion-no-hits');
        var $productSuggestionNoHitsPElm = $productSuggestionNoHits.find('p');
        $productSuggestionNoHitsPElm.text('');
        $productSuggestionNoHits.hide();
    }

    function handleTabKey(e) {
        var keyCode = e.keyCode || window.event.keyCode;

        if (keyCode == 9 && suggestion.hasSuggestion()) {
            e.preventDefault();
            $searchField.val(suggestion.getSuggestion());
        }
    }

    this.onEnterEscKey = function(cb) {
        $searchField.on('keydown', function(e) {
            try {
                var keyCode = e.keyCode || window.event.keyCode;

                if (keyCode === 13 || keyCode === 27) { // check for an ENTER or ESC
                    cb();
                }
            } catch(e) {
                // error happened.
            }
        });
    };

    /**
     * @function
     * @description Handles keyboard's arrow keys
     * @param keyCode Code of an arrow key to be handled
     */
    function handleArrowKeys(e) {
        var keyCode = e.keyCode;

        if (window.event && window.event.keyCode) {// get keyCode (window.event is for IE)
            keyCode = window.event.keyCode;
        }

        switch (keyCode) {
            case 38:
                // keyUp
                listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
                break;
            case 40:
                // keyDown
                listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
                break;
            case undefined:
                listCurrent = -1;
                return true;
            default:
                // reset
                listCurrent = -1;
                return false;
        }

        $resultsContainer.children().removeClass('selected').eq(listCurrent).addClass('selected');
        $('input[name="q"]').val($resultsContainer.find('.selected .suggestionterm').first().text());
        return true;
    }

    function handleRefinementPillClick(e) {
        e.preventDefault();

        var $this = $(this);
        var empty = (currentQuery == '');
        var $simplesearch_lang = $('.js-simplesearch-lang');
        var $simplesearch_prefn1 = $('.js-simplesearch-prefn1');
        var $simplesearch_prefv1 = $('.js-simplesearch-prefv1');
        var $simplesearch_nohits = $('.js-monetate-product-suggestion-no-hits');

        if ($this.closest('li').attr('data-topcat') === Resources.SEARCH_ALL.toLocaleLowerCase()) {
            $searchIcon.addClass('default');
        } else {
            $searchIcon.removeClass('default');
        }

        if ($this.closest('li').hasClass('disabled') || $this.closest('li').hasClass('selected')) {
            return;
        }

        lastQuery = null;

        $this.closest('ul').find('li').removeClass('selected');
        $this.closest('li').toggleClass('selected');

        $hint = $searchForm.find('.js-hint');
        refinementsPillSelectedVal = $refinementsPill.find('li.selected a').first().text().trim(); // get update text

        if (empty) {
            if (refinementsPillSelectedVal !== Resources.SEARCH_ALL) {
                $hint.val(Resources.SEARCH_IN + ' ' + refinementsPillSelectedVal);
            } else {
                $hint.val(Resources.SIMPLE_SEARCH);
            }
        }

        // check if hidden input fields are on page. If not on page create it and add it to form
        if ($simplesearch_prefn1.length <= 0 && $simplesearch_prefn1.length <= 0) {
            $simplesearch_prefn1 = $simplesearch_lang.clone()
                .addClass('js-simplesearch-prefn1')
                .attr('type','hidden')
                .attr('name', 'prefn1')
                .val('PoloDivision');

            $simplesearch_prefv1 = $simplesearch_lang.clone()
                .addClass('js-simplesearch-prefv1')
                .attr('type','hidden')
                .attr('name', 'prefv1')
                .val(decodeURIComponent(refinementsPillSelectedVal.trim()));

            $searchForm.append($simplesearch_prefn1).append($simplesearch_prefv1);
        } else {
            if (refinementsPillSelectedVal === Resources.SEARCH_ALL) {
                // delete input hidden filter fields, since they are not needed
                $simplesearch_prefn1.remove();
                $simplesearch_prefv1.remove();
            } else {
                $simplesearch_prefv1.val(decodeURIComponent(refinementsPillSelectedVal.trim()));
            }
        }

        // clear out text if user selects another option
        if ($simplesearch_nohits.length > 0) {
            $simplesearch_nohits.find('p').empty();
        }

        if (!empty) {
            // make ajax call after different selection
            setTimeout(self.suggest, delay);
        }
    }
}

module.exports = FlyoutSearch;