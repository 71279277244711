'use strict';

// PLP Redesign 24 - JS for Product Tiles
var util = require('../../util'),
    productTileLazyLoad = require('../../components/productTile/productTileLazyLoad');

function removeSlide(e) {
    e.stopPropagation();
    const elt = e.target;
    if (elt.src) {
        const swiper = elt.closest('.swiper');
        if (swiper && swiper.dataset.initialized === 'true') {
            const slide = elt.closest('.js-swiper-slide-container');
            if (slide && slide.dataset) {
                swiper.swiper.removeSlide(slide.dataset.swiperSlideIndex);
                if (swiper.swiper.slides.length === 3) {
                    swiper.swiper.destroy();
                    // need to clean up myself...
                    swiper.querySelectorAll('.js-product-tile-swiper-button').forEach((btn) => {
                        btn.remove();
                    });
                }
            }
        }
    }
}

function setInitialideSlide(swiper) {
    const hover = swiper.el.dataset.hover;
    let index = 0;
    if (util.isDesktopSize() && !util.isTablet()) {
        if (hover !== '') {
            const slides = swiper.el.querySelectorAll('.swiper-slide');
            index = Array.from(slides).findIndex((slide) => {
                const source = slide.querySelector('source[media="(min-width: 960px)"]');
                if (source) {
                    const slideImage = source.dataset.srcset;
                    return slideImage === hover;
                } else {
                    // video
                    const videoSource = slide.querySelector('source');
                    return videoSource && videoSource.dataset.src === hover;
                }
            });
        }
    }
    swiper.params.initialSlide = index > -1 ? index : 1;
}

function initProductTileSwiper(selector, $container) {
    var el;

    if ($container) {
        var $swiperContainer = $container.find(selector);

        if (!$swiperContainer.length) {
            return;
        }

        el = $swiperContainer[0];
    }
    if (el || document.querySelector(selector)) {
        // this initialize all visible swipers
        let initialized = new Swiper(el || selector, {
            version: '6',
            direction: 'horizontal',
            loop: true,
            lazy: {
                loadOnTransitionStart: true,
                preloaderClass: 'ghosting-main'
            },
            watchOverflow: true,
            navigation: {
                nextEl: '.js-product-tile-swiper-button.swiper-button-next',
                prevEl: '.js-product-tile-swiper-button.swiper-button-prev',
            },
            on: {
                beforeInit: setInitialideSlide,
                init: function (swiper) {
                    const isDevice = util.isMobile() || util.isTablet();
                    this.el.dataset.initialized = true;
                    swiper.el.addEventListener('slideSrcFail', removeSlide);

                    // lazy load video in the first slide on mobile
                    if (isDevice) {
                        productTileLazyLoad.lazyLoadVideos(this.slides[this.activeIndex]);

                        if (this.el.dataset.animate) {
                            swiper.lazy.loadInSlide(swiper.activeIndex + 1);
                            if (document.readyState === 'complete') {
                                this.el.classList.add('animated');
                            } else {
                                $(window).on('load', () => {
                                    this.el.classList.add('animated');
                                });
                            }
                            swiper.once('touchStart', function () {
                                this.el.classList.remove('animated');
                            });
                        }
                    } else {
                        swiper.lazy.loadInSlide(swiper.activeIndex + 1);
                    }
                    // load all the other inmages
                    // loading will trigger the removals of slides with missing images
                    swiper.once((isDevice ? 'sliderFirstMove' : 'slideChange'), function () {
                        productTileLazyLoad.lazyLoadVideos(this.el);
                        const count = this.slides.length - 2;
                        for (var i = 0; i < count; i++) {
                            this.lazy.loadInSlide(i);
                        }
                    });
                }

            }
        });
        if (!Array.isArray(initialized)) {
            initialized = [initialized];
        }
        return initialized;
    }
    return [];
}

function destroyProductTileSwiper(productTile) {
    var swiperInstance = productTile.find('.js-product-tile-swiper');

    if (swiperInstance.length && swiperInstance[0].swiper) {
        swiperInstance[0].swiper.destroy(true);
    }
}

exports.initProductTileSwiper = initProductTileSwiper;
exports.destroyProductTileSwiper = destroyProductTileSwiper;
exports.init = function () {
    console.log('PLP Redesign 24 - JS for Product Tiles');
};
