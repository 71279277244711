'use strict';

var formPrepare = require('./../../../app_apac_foundation/cartridge/js/pages/checkout/formPrepare');
var $savedPayments = $('.saved-payment');
var $creditCardListSelect = $('#PaymentMethod_CREDIT_CARD #creditCardList');
var $addNewCardCta = $('#PaymentMethod_CREDIT_CARD .add-new-card');
var $addNewCardContainer = $('#PaymentMethod_CREDIT_CARD').find('.add-card');
var $creditCardNumber = $('#PaymentMethod_CREDIT_CARD').find('.user-cred-details').find('[class*="_creditCard_number"]');
var $creditCardExpirationDate = $('#PaymentMethod_CREDIT_CARD').find('.user-cred-details').find('[class*="_expirationdate_date"]');
var $creditCardCVV = $('#PaymentMethod_CREDIT_CARD').find('.user-cred-details').find('[class*="_creditCard_cvn"]');
var $creditCardOwner = $('#PaymentMethod_CREDIT_CARD').find('.user-cred-details').find('[class*="_creditCard_owner"]');
var $saveCreditCardCheckbox = $('#PaymentMethod_CREDIT_CARD').find('.user-cred-details').find('[class*="_creditCard_saveCard"]');
var $userCredDetails = $('#PaymentMethod_CREDIT_CARD .user-cred-details');

var timeout;

function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document) || (navigator.userAgent.includes("Mac") && (navigator.userAgent.includes("Safari")))
}

function wrapElement ($element, tag) {
    if (!$element.parent().is(tag)) {
        $element.wrap('<' + tag + '>');
    }
}

function unwrapElement ($element, tag) {
    if ($element.parent().is(tag)) {
        $element.unwrap();
    }
}

/**
 * clearCreditCardForm
 */
function clearCreditCardForm () {
    $creditCardCVV.find('.input-text').removeClass('error');
    $creditCardCVV.find('.cvverror').remove();
    $creditCardCVV.find('.error').remove();

    $creditCardOwner.find('.input-text').removeClass('error');
    $creditCardOwner.find('.error').remove();

    $creditCardNumber.find('.input-text').removeClass('error');
    $creditCardNumber.find('.error').remove();

    $creditCardExpirationDate.find('.input-text').removeClass('error');
    $creditCardExpirationDate.find('.error').remove();
}

/**
 * scrollAnimate
 */
function scrollAnimate(callback) {
    clearCreditCardForm();

    // Animated
    $userCredDetails.removeClass('anim-scroll-down').removeClass('anim-scroll-up');
    $userCredDetails.addClass('anim-scroll-up');
    if (!timeout) {
        timeout = setTimeout(function () {
            $userCredDetails.removeClass('anim-scroll-down').addClass('anim-scroll-down');
            callback();
        }, 1000);
    } else {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            $userCredDetails.removeClass('anim-scroll-down').addClass('anim-scroll-down');
            callback();
        }, 1000);
    }
}

/**
 * hideAddCreditCard
 */
function hideAddCreditCard () {
    // Hide card info input
    $creditCardNumber.removeClass('d-none').addClass('d-none');
    $creditCardExpirationDate.removeClass('d-none').addClass('d-none');
    $creditCardOwner.removeClass('d-none').addClass('d-none');
    $saveCreditCardCheckbox.removeClass('d-none').addClass('d-none');

    // Show add new card cta
    $addNewCardContainer.removeClass('d-none');

    // Center CVV input
    $userCredDetails.addClass('justify-content-center');

    // Remove CVV input error
    $creditCardCVV.find('.input-text').removeClass('error');
    $creditCardCVV.find('.cvverror').remove();
    formPrepare.validateTermAndConditional();
}

/**
 * showAddCreditCard
 */
function showAddCreditCard () {
    // Clear input value
    $creditCardNumber.find('input').val('').trigger('change');
    $creditCardExpirationDate.find('input').val('').trigger('change');
    $creditCardOwner.find('input').val('').trigger('change');
    $saveCreditCardCheckbox.find('input').prop('checked', false);

    // Show input
    $creditCardNumber.removeClass('d-none');
    $creditCardExpirationDate.removeClass('d-none');
    $creditCardOwner.removeClass('d-none');
    $saveCreditCardCheckbox.removeClass('d-none');

    // Remove valid input
    $creditCardExpirationDate.find('.valid-icon').remove();
    $creditCardExpirationDate.find('.input-text').removeClass('valid');
    $creditCardOwner.find('.valid-icon').remove();
    $creditCardOwner.find('.input-text').removeClass('valid');
    $creditCardCVV.find('.input-text').removeClass('error');

    // Hide add new card cta
    $addNewCardContainer.removeClass('d-none').addClass('d-none');

    // Uninput all saved payments
    $savedPayments.find('input').prop("checked", false);

    // Remove CVV error
    $('#PaymentMethod_CREDIT_CARD .cvverror').text('');

    // Remove Center input
    $userCredDetails.removeClass('justify-content-center');
    formPrepare.validateTermAndConditional();
}

/**
 * handleSelectPayment
 */
function handleSelectPayment () {
    // Check default select payment in first load, if credit card was selected, then checked first credit card option
    if ($('.is-CREDIT_CARD #is-CREDIT_CARD').is(':checked')) {
        // Remove all checked
        $('.input-creditcard-option').removeClass('checked');

        setTimeout(function () {
            // Check first credit card option
            $($('.input-creditcard-option')[0]).trigger('click');
        }, 1000);
    }

    // Line Pay Selected
    $('.is-LINEPAY').on('click', function () {
        var linepayInput = $(this).find('#is-LINEPAY');
        if (linepayInput.is(':checked')) {
            // Remove all checked
            $('.input-creditcard-option').removeClass('checked');
        }
    })

    // JKO Pay Selected
    $('.is-JKOPAY').on('click', function () {
        var jkopayInput = $(this).find('#is-JKOPAY');
        if (jkopayInput.is(':checked')) {
            // Remove all checked
            $('.input-creditcard-option').removeClass('checked');
        }
    })

    // Credit Card Selected
    $('.credit-input-container').on('click', function () {
        // Remove all checked
        $('.input-creditcard-option').removeClass('checked');

        // Checked select payment
        $(this).find('.input-creditcard-option').addClass('checked');

        // Checked hidden credit card input
        $('.is-CREDIT_CARD').find('#is-CREDIT_CARD').trigger('click');

        // Check type of credit card, then hide another card in card dropdown list
        if ($(this).closest('.is-jcb').length) {
            $creditCardListSelect.find('option:not(:contains(JCB)):not([value=""])').removeClass('hide').addClass('hide');
            $creditCardListSelect.find('option:contains(JCB)').removeClass('hide');

            // Handle select option can't hide on IOS
            if (iOS()) {
                wrapElement($creditCardListSelect.find('option:not(:contains(JCB)):not([value=""])'), 'span');
                unwrapElement($creditCardListSelect.find('option:contains(JCB)'), 'span');
            }
        } else {
            $creditCardListSelect.find('option:contains(JCB)').removeClass('hide').addClass('hide');
            $creditCardListSelect.find('option:not(:contains(JCB)):not([value=""])').removeClass('hide');

            // Handle select option can't hide on IOS
            if (iOS()) {
                wrapElement($creditCardListSelect.find('option:contains(JCB)'), 'span');
                unwrapElement($creditCardListSelect.find('option:not(:contains(JCB)):not([value=""])'), 'span');
            }
        }

        // Hide $creditCardListSelect if user hasn't saved any of this card
        if ($creditCardListSelect.find('option:not([value=""]):not([class="hide"])').length === 0) {
            $creditCardListSelect.addClass('hide');
        } else {
            $creditCardListSelect.removeClass('hide');
        }

        // Change selected card to unvalue
        $creditCardListSelect.val('').trigger('change');
    });
}

/**
 * handleDisplayCVV_SVG - ticket: https://ralphlauren.atlassian.net/browse/DIGITAL-29912
 */
function handleDisplayCVV_SVG () {
    $('#PaymentMethod_CREDIT_CARD .cvn_number').on('change', function () {
        var $this = $(this);
        if ($this.val()) {
            $('.cvn_number').closest('.input-wrapper').find('.cvn_number_svg').addClass('hide');
        } else {
            $('.cvn_number').closest('.input-wrapper').find('.cvn_number_svg').removeClass('hide');
        }
    });
}

/**
 * updatePlaceHolder - ticket: https://ralphlauren.atlassian.net/browse/DIGITAL-29912
 */
function updatePlaceHolder () {
    $('#PaymentMethod_CREDIT_CARD').find('.credit-card-number').attr('placeholder', window.Resources.CREDIT_CARD_NUMBER_PLACEHOLDER);
    $('#PaymentMethod_CREDIT_CARD').find('.exp_date').attr('placeholder', window.Resources.CREDIT_CARD_EXPIRE_DATE_PLACEHOLDER);
    $('#PaymentMethod_CREDIT_CARD').find('.cvn_number').attr('placeholder', window.Resources.CREDIT_CARD_CVV_PLACEHOLDER);
    $('#PaymentMethod_CREDIT_CARD').find('.owner').attr('placeholder', window.Resources.CREDIT_CARD_OWNER_PLACEHOLDER);
}

module.exports = function () {
    $creditCardListSelect.on('change', function () {
        var id = $(this).val();
        if (id) {
            $savedPayments.find('input[value="' + id + '"]').prop("checked", true).trigger("change");
            scrollAnimate(hideAddCreditCard);
        } else {
            $('[data-method="CREDIT_CARD"]').find('[name$="creditCard_selectedCardID"]').val('');
            scrollAnimate(showAddCreditCard);
        }
    });

    $addNewCardCta.on('click', function () {
        $creditCardListSelect.val('').trigger('change');
    });

    $creditCardListSelect.trigger('change') // Trigger change, first load

    handleSelectPayment();
    handleDisplayCVV_SVG();
    updatePlaceHolder();
}