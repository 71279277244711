		function initializeJRNI () {
		if(document.getElementById('bb-journey') != null) {
			iFrameResize({log:false, checkOrigin:false, enablePublicMethods:true}, '#bb-journey');
			// pass url params to the iframe                
			var params = window.location.search.replace("?", "&");                
			var iframe = document.getElementById("bb-journey");                
				iframe.src += params; 
		}
	}
		
module.exports = {
	    initializeJRNI: initializeJRNI
	};